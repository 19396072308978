.page-intro {
    &-heading {
        margin: 0 0 30px;
        @include gradient-text(golden-hour);
        &.text-center {
            display: block;
        }
        h1, h2, h3, h4, h5, h6 {
          margin: 0;
        }
        p {
          margin: 0;
        }
    }

    &-subheading {
        margin: 0 0 30px;
    }

    .wrapper > *:last-child {
        margin-bottom: 0;
    }
}
