.state-listing {
    .state-listing-header {
        margin: 0 auto 50px;
        max-width: 815px;
        text-align: center;
    }

    .title {
        color: #fff;
        text-align: center;
        margin-bottom: 25px;
        font-size: 48px;
        @include breakpoint(small) {
            font-size: 20px;
        }
        @include gradient-text(golden-hour);
    }

    .sub-title {
        font-size: 16px;

        @include breakpoint(small) {
            font-size: 18px;
        }
    }

    .state-list {
        list-style-type: none;
        margin: 0;
        padding: 0;

        @include breakpoint(medium-large) {
            display: flex;
            flex-wrap: wrap;
        }
    }

    .state-item {
        flex: 0 0 33.3333%;
        margin-bottom: 20px;
        text-align: center;
    }

    .state {
        padding: 15px;
    }

    .state-media {
        border-radius: 10px;
        object-fit: cover;
        line-height: 0;
        overflow: hidden;
    }

    .state-image {
        width: 100%;
    }

    .state-title {
        font-size: 24px;
        color: #2D72F7;
    }

    .state-description {
        padding: 0 30px;
    }

    .state-map {
        //margin-bottom: 90px;

        @include breakpoint(small) {
            margin-bottom: 20px;
        }
    }

    // IE11 only
    @media all and (-ms-high-contrast:none)
    {
        #map {
            max-width: 100%;
            width: 1158px;
            height: 643px;
        }
    }

    .state {
        fill: #D3D3D3;
        transition: .2s ease-in-out all;
        transform-origin: center;
        transform-box: fill-box;

        &.is-active {
            fill: #FF8200;
            cursor: pointer;

            &.is-hover {
                fill: #292929;
                transform: scale(1.15);
                stroke: #fff;
                stroke-width: .75;		
            }
        }
    }

    .state-tooltip {
        position: absolute;
        display: block;
        font-size: 14px;
        color: #fff;

        padding: 5px 20px;
        line-height: 1.25;
        @include breakpoint(small) {
            line-height: 1.5;
        }

        background: linear-gradient(45deg, map-get(getGradientColor(golden-hour), left) 20%, map-get(getGradientColor(golden-hour), right) 80%);
    }

    @include breakpoint(small) {
        a {
            pointer-events: none;
        }
    }
}


.state-item {
    .info-card-title {
        font-size: 24px;
    }
}

