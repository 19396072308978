article.about-sunnova {
    @include breakpoint(small) {
      margin-bottom: 40px;
    }
    .content {
        @include breakpoint(medium-large) {
            @include grid-columns(6);
        }

        @include breakpoint(small) {
            margin-bottom: 40px;
        }

        h1,h2,h3,h4,h5,h6 {
            @include gradient-text(aurora);
            margin-top: 0;
            font-size: 48px;
            @include breakpoint(small) {
                font-size: 36px;
            }
        }

        .button {
            color: #fff;
        }
    }

    .logos {
        @include breakpoint(large) {
            @include grid-columns(6);
        }

        ul {
            @include layout-list();
            display: flex;
            flex-wrap: wrap;
            font-size: 12px;
            margin: -25px 0;

            li {
                display: flex;
                flex-direction: column;
                width: 50%;
                padding: 0 25px;
                text-align: center;
                margin: 0 0 25px 0;
                @include breakpoint(small) {
                  margin-top: 25px;
                }

                img {
                    margin: 0 auto;

                    @include breakpoint(large) {
                        max-width: 200px;
                    }
                }
            }
        }
    }
}
