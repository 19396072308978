.site-footer {
    padding: 0 0 50px 0;
    font-size: 16px;

    @include breakpoint(large) {
        .inner {
            @include grid-width(10, 12);
            @include push(1);
        }
    }

    h4 {
        padding-bottom: 15px;
        border-bottom: 1px solid #292929;
    }
    div.col-1 {
        @include breakpoint(medium-large) {
            @include grid-columns(3, 10);
        }
    }
    div.col-2 {
        @include breakpoint(medium-large) {
            @include grid-columns(3, 10);
        }
    }
    div.col-3 {
        @include grid-container();
        @include breakpoint(medium-large) {
            @include grid-columns(4, 10);
        }
        ul {
            @include layout-list();
            @include grid-columns(2, 4);
        }
    }
    .footnotes {
        font-size: 13px;
        margin-bottom: 40px;
        color: #8F8F8F;
        p {
            font-size: 13px;
        }
    }
    .copyright {
        font-size: 16px;
        p {
            display: inline;
            margin-right: 15px;
            @include breakpoint(small) {
                display: block;
            }
        }
        ul {
            @include layout-list();
            display: inline;
            li {
                display: inline-block;
                margin-right: 15px;
                @include breakpoint(small) {
                    display: block;
                }
            }
        }
    }
}
