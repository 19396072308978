.product-highlights {
    $this: &;

    @include breakpoint(large) {
        margin: 100px 0;

        .col {
            @include grid-columns(5);
        }

        .col + .col {
            @include grid-columns(7);
        }    
    }

    &-heading {
        margin-top: 0;

        @include breakpoint(small) {
            //margin-top: 50px;
            margin-bottom: 20px;
        }

        &.title-top {
            text-align: center;
        }
    }

    &-intro {
        padding-top: 40px;
        margin-bottom: 40px;
        display: flex;
        align-items: center;

        @include breakpoint(small) {
            padding-top: 20px;
        }

        &::after {
            content: '';
            line-height: 0;
            border-top: 2px solid black;
            flex: 1;
            margin-left: 10px;
        }
    }

    &-list {
        @include layout-list();
        @include flex-grid($gutter: 50px,
                           $isSelf: true);

        @include breakpoint(small) {
            .product-highlight {
                margin-bottom: 30px;
            }
        }

        p {
            font-size: 16px;
        }
    }

    &-disclaimer {
        margin-top: 50px;

        @include breakpoint(large) {
            margin-top: 100px;
        }
    }

    &-alt {
        #{$this}-heading {
            text-align: center;
            margin-bottom: 80px;

            @include breakpoint(small) {
                margin-bottom: 20px;
                text-align: left;
            }
        }
    }
}
