article.video {
    @include breakpoint(large) {
        margin: 100px 0;
    }    
}

.video {
    text-align: center;

    &-content {
        margin-bottom: 50px;
    }

    &-container {
        position: relative;
        width: 100%;
        height: 0;

        &.is-16x9 {
            padding-bottom: 56.25%;
        }

        & > * {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
