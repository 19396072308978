article.testimonial {
    @include breakpoint(medium-large) {
        margin: 100px 0;
    }

    .title {
        @include breakpoint(medium-large) {
            @include grid-columns(6);
        }

        h1,h2,h3,h4,h5,h6 {
            font-size: 56px;
            line-height: 72px;
            @include gradient-text(aurora);
            @include breakpoint(small) {
                font-size: 32px;
                line-height: 1.25em;
            }
        }
    }

    .content {
        position: relative;
        z-index: 0;

        @include breakpoint(medium-large) {
            @include grid-columns(6);
        }

        img {
            border-radius: 50%;
            max-width: 200px;
            max-height: 200px;
        }

        figure.quote {
            padding: 40px 0 60px 0;
            @include breakpoint(small) {
                padding: 20px 0 40px;
            }
            p {
                font-size: 16px;
                margin-top: 0;
            }
        }
    }

    .tns-nav button, .tns-controls button {
        background: none;
        padding: 0;
        color: #333;
        box-shadow: none;
    }

    .tns-slider.is-transitioning {
        .tns-item {
            transition: 1s ease-in-out opacity;
        }
    }

    .tns-nav {
        counter-reset: testimonial-nav-counter;

        button {
            counter-increment: testimonial-nav-counter;
            min-width: 32px;
            min-height: 32px;
            border-radius: 50%;
            color: rgba(0,0,0,.4);
            font-weight: bold;
            text-align: center;

            &:before {
                content: counter(testimonial-nav-counter);
            }
        }

        .tns-nav-active {
            background: getGradient(aurora);
            color: #fff;
        }
    }

    .tns-nav-controls-container {
        display: inline-flex;
        position: relative;
        padding-left: 32px;
        padding-right: 32px;
        &.first-selected {
            padding-left: 0;
        }
    }

    .tns-controls {
        display: flex;
        align-items: center;

        [data-controls="prev"],
        [data-controls="next"] {
            position: absolute;
            min-width: 32px;
            min-height: 32px;
        }

        [data-controls="prev"] {
            left: 0;
        }

        [data-controls="next"] {
            right: 0;
        }

        button[disabled] {
            display: none;
        }
    }

    .tns-item {
        opacity: 0;
    }

    .tns-slide-active {
        opacity: 1;
    }

    .tns-outer {
        position: relative;
    }
}
