article.homepage-hero {
    position: relative;
    margin-top: 110px;
    
    .mask-wrapper {
        &.top-curve {
            @include curved-box(".bg");
        }
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .bg {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    
    .wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        vertical-align: middle;
        flex-direction: column;
        padding: 150px 0px;
        &.has-video-bg {
            visibility: hidden;
        }
    }
    .mask-wrapper ~ .wrapper {
        padding: 125px 0px;
    }

    div.header {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    div.cta {
        .button {
            margin: 5px 30px;
        }
        @include breakpoint(small) {
            margin-top: 72px;
        }
    }

    h1 {
        color: #fff;
        text-align: center;
        > span {
            padding: 5px 0;
            background: #FF8200; // Fallback color
            > span {
                display: inline;
                padding: 5px 20px;
                line-height: 1.35;
                color: #fff;
                -webkit-box-decoration-break: clone;
                box-decoration-break: clone;
                
                @include breakpoint(medium-large) {
                    background: linear-gradient(to right top, #F15523 20%, #FFBE01 80%);
                    background-attachment: fixed; 
                }
                
                @include breakpoint(small) {
                    line-height: 1.55;
                }
            }
        }
    }

    .video-bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .mobile, .tablet, .desktop {
        display: none;
    }
    @include breakpoint(small) {
        .mobile {
            display: initial;
        }
    }
    @include breakpoint(medium) {
        .tablet {
            display: initial;
        }
    }
    @include breakpoint(large) {
        .desktop {
            display: initial;
        }
    }

    @include breakpoint(medium-large) {
        &.top {
            .header {
                align-items: flex-start;
            }
        }

        &.middle {
            .header {
                align-items: center;
            }
        }

        &.bottom {
            .header {
                align-items: flex-end;
            }
        }

        &.left {
            .header {
                justify-content: flex-start;
            }
        }

        &.center {
            .header {
                justify-content: center;
            }
        }

        &.right {
            .header {
                justify-content: flex-end;
            }
        }
    }
    &.homepage {
        margin-top: 110px;
        @include breakpoint(small) {
            margin-top: 0px;
        }
    }
}
