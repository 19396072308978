article.callouts {
    background: transparent;
    position: relative;
    z-index: 2;

    @include breakpoint(medium-large) {
        margin: -300px 0 200px 0;
    }

    @include breakpoint(small) {
        margin-top: 50px;
    }

    @include breakpoint(medium-large) {
        div.row {
            display: flex;
        }
    }

    div.callout-box {
        @include breakpoint(medium-large) {
            @include grid-columns(5);
            display: flex;
            flex-direction: column;
            margin-bottom: -150px;

            &:first-child {
                @include push(1);
            }
        }

        @include breakpoint(small) {
            margin-bottom: 20px;
        }

        h3 {
            font-size: 20px;
        }

        ul {
            @include layout-list();
            margin-top: auto;
            font-size: 12px;

            li {
                border-bottom: 1px solid #C4C4C4;
                &:last-child {
                    border-bottom: 0;
                }

                a {
                    @include gradient-text(golden-hour);
                    display: block;
                    padding: 10px 0;
                    font-size: 24px;
                    &:after {
                      content: "";
                      float: right;
                      margin-top: 16px;
                      border: 1px solid #e8825b;
                      border-width: 0 2px 2px 0;
                      display: inline-block;
                      padding: 3px;
                      transform: rotate(-45deg);
                      -webkit-transform: rotate(-45deg);
                    }
                }
            }
        }
    }
}
