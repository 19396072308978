.faq-autocomplete {
    position: relative;
    display: flex;
    align-items: center;

    &-form {
        position: relative;
    }
    
    &-submit {
        @include button-reset();
        position: absolute;
        right: 0;
        top: 22px;
    
        &:after {
            content: '';
            position: absolute;
            right: 25px;
            width: 38px;
            height: 36px;
            background-image: inline-svg('<svg width="38" height="36" viewBox="0 0 38 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24.3503 22.9495L36 34M27.75 15.2121C27.75 21.9567 22.2095 27.4242 15.375 27.4242C8.54048 27.4242 3 21.9567 3 15.2121C3 8.46755 8.54048 3 15.375 3C22.2095 3 27.75 8.46755 27.75 15.2121Z" stroke="#8F8F8F" stroke-width="5"/></svg>');
        }
    
        &.is-loading {
            &:after {
                background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" stroke="#2D72F7"><g fill="none" fill-rule="evenodd"><g transform="translate(1 1)" stroke-width="4"><circle stroke-opacity=".5" cx="18" cy="18" r="16"/><path d="M34 18c0-9.94-8.06-16-18-16"><animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite"/></path></g></g></svg>');
            }
        }
    }
    
    &-input {
        border: none;
        margin: 0px;
        height: 80px;
        width: 100%;
        border-radius: 15px;
        font-size: 24px;
        padding: 25px 65px 25px 25px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border: 1px solid rgba(0, 0, 0, 0.15);
    }
    
}

.autocomplete {
    position: absolute;
    transition: all 0.5s ease 0s;
    max-height: 0;
    transition-duration: 0.3s;
    transition-property: all;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    border-radius: 10px;
    opacity: 0;
    margin-top: 2px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-align: left;

    &:active,
    &:focus,
    &:hover {
        background-color: #fafafa;
        transition: all 0.5s ease 0s;
    }
    
    &:empty {
        display: none;
    }
    
    & > ul {
        list-style-type: none;
        margin: 0;
        padding: 10px;
        color: map-get($colors, black);

        a, 
        a:hover, 
        a:focus {
            color: map-get($colors, black);
        }

        a {
            display: block;
            line-height: 1.35;
        }

        & > li {
            cursor: pointer;
            padding: 12px 15px;
            font-size: 20px;
            border-radius: 10px;

            &.active,
            &:active,
            &:focus,
            &:hover {
                background-color: #eeeeee;
                transition: all 0.5s ease 0s;
            }

            &.active a:active,
            &:active a:active,
            &:focus a:active,
            &:hover a:active,
            &.active a:focus,
            &:active a:focus,
            &:focus a:focus,
            &:hover a:focus,
            &.active a:hover,
            &:active a:hover,
            &:focus a:hover,
            &:hover a:hover {
                text-decoration: none;
            }

            &.locked {
                cursor: inherit;
            }

            span {
                display: block;
                text-transform: uppercase;
                font-size: 14px;
                color: #8F8F8F;
                margin-top: 4px;
            }
        }
    }

    &.open {
        display: block;
        transition: all 0.5s ease 0s;
        background-color: #fafafa;
        max-height: 1000px;
        overflow: hidden;
        transition-duration: 0.5s;
        transition-property: all;
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
        z-index: 100;
        opacity: 1;

        &:empty {
            display: none;
        }
    }
    
    &-show-all {
        position: relative;
        margin-top: 6px;
        padding: 0 !important;

        button {
            @include button-reset();
            font-family: acumin-pro, 'Helvetica Neue', Helvetica, Arial, sans-serif;
            width: 100%;
            padding: 12px 15px;
        }
    
        &:before {
            content: '';
            border-top: 1px solid #ccc;
            position: absolute;
            top: -3px;
            left: 15px;
            right: 15px;
        }
    
        &:after {
            content: '';
            position: absolute;
            width: 41px;
            height: 33px;
            right: 15px;
            top: 11px;
            pointer-events: none;
            background-image: inline-svg('<svg width="41" height="33" viewBox="0 0 41 33" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.5 16H38.3536M38.3536 16L23.6464 2M38.3536 16L23.3536 31" stroke="#B2B2B2" stroke-width="3"/></svg>');
        }
    }
}