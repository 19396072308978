.filter-bar {
    &-list-container {
        background: linear-gradient(230.21deg, #33CCFF 7.99%, #333399 100%);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
        border-radius: 8px;
        color: #fff;
        padding: 30px;
        text-align: left;
        position: relative;
        min-height: 350px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-decoration: none;
        @include breakpoint(small) {
            display: block;
            padding: 30px;
        }
    }

    &-heading {
        margin: 0;

        @include breakpoint(small) {
            margin-bottom: 25px;
        }
    }

    @include bp(sm) {
        .col:not(.tns-item) {
            width: 25%;
            float: left;
            padding-right: 20px;

            &:nth-child(n+5) {
                display: none;
            }
        }
    }

    &-list {
       @include layout-list();
       transform: translate3d(0%, 0px, 0px);
       list-style: none;
       margin: 25px 0 0;
       padding: 0;
       font-size: 20px;

       a, a:focus, a:hover, a:active {
           color: #fff;
       }
       @include breakpoint(small) {
         margin: 0;
       }
       
        li {
            position: relative;
            counter-increment: reading-list-counter;
            padding-left: 25px;
            margin-bottom: 20px;
            @include breakpoint(medium-large) {
                display: inline-block;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &.is-selected {
                font-weight: 600;
            }
        }

        li::before {
            position: absolute;
            left: 0;
        }
       
        @include breakpoint(small) {
            padding: 5px 0;
            margin: 0;
        }
    }

    &.is-sidebar {
        margin-top: 0;

        .filter-bar-list-container {
            padding: 45px 30px;
            display: block;
            box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.16);

            li {
                margin-left: 0;
                margin-top: 25px;
                display: block;
            }
        }

        .filter-bar-heading {
            font-size: 28px;
        }
    }

    .custom-dropdown {
        margin-bottom: 0;
    }
}

.blog-listing {
    $this: &;

    &-heading {
        text-transform: capitalize;
        margin-bottom: 30px;
        
        @include breakpoint(small) {
            text-align: center;
        }
    }

    &-list {
        transition: .3s ease-in-out height;
        position: relative;
        
        @include bp(md) {
            margin-top: -40px;
        }
    }

    li {
        position: relative;
        transition: .3s ease-in-out all;

        @include bp(md) {
            display: flex;
        }
        
        &.is-hiding {
            opacity: 0;

            .info-card {
                transform: scale(0);
            }
        }

        &.is-hidden {
            flex-basis: 0;
            display: none;
        }
    }

    .info-card {
        transition: .3s ease-in-out all;

        @include bp(md) {
            margin-top: 40px;
            display: flex;
            flex-direction: column;
            flex: 1;
        }
    }

    .info-card-content {
        @include bp(md) {
            display: flex;
            flex-direction: column;
            flex: 1;
        }
    }

    .info-card-meta {
        @include bp(md) {
            margin-top: auto;
        }
    }

    .load-more {
        text-align: center;

        button {
            margin-top: 40px;
        }
    }

    &--v2 {
        #{$this}-heading {
            letter-spacing: -2px;
            color:  map-get($colors, black);
            margin-bottom: 50px;
        }
    }
}
