article.text {
    @include breakpoint(small) {
        // margin-bottom: 0;
    }

    @include bp(sm) {
        margin-top: 80px;
    }

    .sidebar {
        margin-top: 60px;

        @include bp(md) {
            margin-top: 0;
        }
    }

    .text-header {
        h1 {
            margin-bottom: 15px;
            margin-top: 0;
            @include gradient-text(golden-hour);
            font-size: 48px;
            line-height: 1.15;
            padding-bottom: 3px;

            @include breakpoint(small) {
                font-size: 36px;
            }
        }
    }

    .text-header-description {
        margin-top: 10px;
    }

    .text-content {
        h2 {
            font-size: 36px;
            margin-top: 60px;

            @include breakpoint(small) {
                font-size: 30px;
            }
        }

        h3 {
            font-size: 28px;

            @include breakpoint(small) {
                font-size: 24px;
            }
        }

        h4 {
            font-size: 24px;

            @include breakpoint(small) {
                font-size: 20px;
            }
        }

        img + p {
            margin-top: 0;
        }

        a {
            //margin-bottom: 30px;
            overflow-wrap: break-word;
            word-wrap: break-word;
            -ms-word-break: break-all;
            word-break: break-all;
            word-break: break-word;
            -ms-hyphens: auto;
            -moz-hyphens: auto;
            -webkit-hyphens: auto;
            hyphens: none;
        }

        ul {
            font-size: 16px;
            line-height: 1.5em;
        }

        li {
            margin-bottom: 8px;
        }

        .box,
        .blockquote {
            display: block;
            padding: 30px;
            margin: 50px 0;
            border-radius: 10px;
            box-shadow: 5px 10px 25px rgba(0, 0, 0, 0.15);

            @include bp(md) {
                padding: 50px;
                box-shadow: 10px 15px 25px rgba(0, 0, 0, 0.15);
            }

            @each $name, $bg in $gradients {
                &-#{$name} {
                    background: $bg;
                    color: color-contrast(getGradientFallback($name));
                }
            }
        }

        .blockquote-blue {
            @extend .blockquote;
            @extend .blockquote-blue-hour;
        }

        .image-left {
            float: left;
            margin: 10px 30px 20px 0;
        }

        .image-right {
            float: right;
            margin: 10px 0 20px 30px;
        }

        .image-full {
            width: 100%;
            margin: 0 0 30px;
        }

        & > *:last-child {
            margin-bottom: 0;
        }
    }

    .text-meta {
        @include layout-list();

        text-transform: uppercase;
        display: flex;
        color: map-get($colors, smoke);
        font-size: map-get($text-sizes, xs);
        margin-bottom: 25px;
        letter-spacing: 0.1em;

        @include breakpoint(small) {
            display: block;
            margin-bottom: 0;
        }

        li {
            margin-right: 34px;

            @include breakpoint(small) {
                margin-bottom: 5px;
            }
        }
    }

    .addthis_inline_share_toolbox {
        @include bp(sm) {
            margin-bottom: 30px;
        }
    }

    .text-image {
        margin-bottom: 30px;
        border-radius: 10px;

        p:last-child {
            margin-bottom: 0;
        }
    }

    .text-disclaimer {
        font-size: 13px;
        &.black {
            color: map-get($colors, black);
        }
        &.grey {
            color: #8f8f8f;
        }
    }

    .wrapper {
        & > *:first-child {
            margin-top: 0;
        }
    }

    &.has-sidebar {
        .text-header {
            @include breakpoint(medium-large) {
                @include grid-columns(9, $column_gap: 55px);
            }
        }
    }

    &.is-blog-article {
        @include bp(sm) {
            font-size: 20px;
        }
    }

    &.is-faq-article {
        header {
            margin-top: 0;
            margin-bottom: 40px;

            @include bp(md) {
                margin-bottom: 60px;
            }
        }

        h2 {
            margin-bottom: 30px;
        }

        .popular-faqs-list {
            margin-bottom: 80px;
        }

        .mainContent, .sidebar {
            *:last-child {
                margin-bottom: 0;
            }
        }

        @include bp(md) {
            .mainContent {
                padding-right: 50px;
            }
        }
    }

    &.is-faq-search {
        header {
            margin-bottom: 20px;

            @include bp(md) {
                margin-bottom: 30px;
            }
        }

        .faq-listing {
            margin-top: 60px;
        }
    }
}
