.product-cards {
    @include breakpoint(large) {
        margin: 100px 0;
    }

    &-header {
        margin-bottom: 65px;

        @include breakpoint(small) {
            margin-bottom: 20px;
        }

        @include breakpoint(large) {
            .col {
                @include grid-columns(5);
            }
    
            .col + .col {
                @include grid-columns(7);
            }
    
            .row {
                display: flex;
                align-items: center;
            }
        }
    }

    &-filters {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
    }

    &-filters-form {
        display: flex;
        flex-wrap: wrap;

        & > * {
            padding: 10px 20px 10px 0;
        }

        @include breakpoint(large) {
            max-width: 80%;
    
            & > * {
                padding: 10px;
                flex: 0 0 50%;
            }
        }
    }

    &-list {
        display: flex;
        flex-wrap: wrap;
        border-radius: 16px;
        overflow: hidden;
        box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.16); 
        @include flex-grid($gutter: 0px,
                           $isSelf: true);
        @include breakpoint(large) {
            &.single-card {
                width: 50%;
            }
        }
    }
}

.product-card {
    $this: &;

    @extend .callout-box;
    position: relative;
    padding: 40px;
    @include breakpoint(small) {
      padding: 30px;
    }
    border-radius: 0;
    width: 100%;
    display: flex;
    flex-direction: column;

    @include breakpoint(large) {
        &.is-1\/2 {
            #{$this}-header {
                display: flex;
                align-items: center;

                img {
                    margin-right: 15px;
                }
            }
        }
    }

    &-header {
      h1,h2,h3,h4,h5,h6 {
          margin-top: 0;
        }
    }

    &-tag {
        position: absolute;
        top: 0;
        right: 0;
        background: #2D72F7;
        color: #fff;
        font-size: map-get($text-sizes, 2xs);
        padding: 5px;
        text-transform: uppercase;
        letter-spacing: 0.04em;
    }

    &-content {
        ul, ol {
            list-style-type: initial;
        }
    }

    &-button {
        margin-top: auto;
        .button {
            white-space: normal;
            margin-top: 10px;
        }
    }
}
