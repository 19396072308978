/*
 * Grid System
 *
 * This is a grid system based off of floats and CSS calc() to calculate percentage-based widths and margins. This allows the grid columns to be flexible to a responsive viewport, with fixed gaps between each column. Grids can also be nested and still maintain the consistent gap for consistent layout.
 *
 * Setup:
 *
 * Set up the grid variables to specify the total number of columns, as well as the gap size between each column.
 *
 * Usage:
 * 
 * @include grid-columns($num, $max_columns, $column_gap)
 *     This is used to set a column and specify how many columns it should span
 * 
 *   Options
 *     $num [required] -> Total number of columns to span
 *     $max_columns [optional] -> Defaults to the global variable, but can be set if you want to establish a new grid context
 *     $column_gap [optional] -> Defaults to the global variable, but can be set if you want to establish a new column gap
 *
 * @include grid-width($num, $max_columns, $column_gap)
 *     This is used to return a width based on the number of columns specified
 *
 *   Options
 *     $num [required] -> Total number of columns to span
 *     $max_columns [optional] -> Defaults to the global variable, but can be set if you want to establish a new grid context
 *     $column_gap [optional] -> Defaults to the global variable, but can be set if you want to establish a new column gap
 *
 * /

/* --- Grid variables ---*/
$max_columns: 12; // Total number of columns in the grid
$column_gap: 20px; // Gap size between each column.

@mixin grid-width($num, $max_columns: $max_columns, $column_gap: $column_gap) {
    // Edge doesn't calculate correctly, so add a .1px offset
    width: calc((((100% - #{($column_gap * ($max_columns - 1))}) / #{$max_columns}) * #{$num}) + #{($column_gap * ($num - 1))} - .1px);
}

@mixin push($num, $extra_gap: false, $max_columns: $max_columns, $direction: 'left', $column_gap: $column_gap) {
    $extra_gap_size: 0px;
    @if($extra_gap) {
        $extra_gap_size: $column_gap;
    }
    margin-#{$direction}: calc( (((100% - #{($column_gap * ($max_columns - 1))}) / #{$max_columns}) * #{$num}) + #{($column_gap * ($num - 1))} + #{$column_gap + $extra_gap_size} ) !important;
    // Same value, but used to override the &:first-child selector in grid-columns()
    &:first-child {
        margin-#{$direction}: calc( (((100% - #{($column_gap * ($max_columns - 1))}) / #{$max_columns}) * #{$num}) + #{($column_gap * ($num - 1))} + #{$column_gap + $extra_gap_size} ) !important;
    }
}

@mixin pull($num, $extra_gap: false, $max_columns: $max_columns, $direction: 'left', $column_gap: $column_gap) {
    $extra_gap_size: 0px;
    @if($extra_gap) {
        $extra_gap_size: $column_gap;
    }
    margin-#{$direction}: calc( ((((100% - #{($column_gap * ($max_columns - 1))}) / #{$max_columns}) * #{$num}) + #{($column_gap * ($num - 1))} + #{$column_gap + $extra_gap_size}) * -1 ) !important;
    // Same value, but used to override the &:first-child selector in grid-columns()
    &:first-child {
        margin-#{$direction}: calc( ((((100% - #{($column_gap * ($max_columns - 1))}) / #{$max_columns}) * #{$num}) + #{($column_gap * ($num - 1))} + #{$column_gap + $extra_gap_size}) * -1 ) !important;
    }
}

@mixin grid-container {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin grid-columns($num, $max_columns: $max_columns, $column_gap: $column_gap) {
    @include grid-width($num, $max_columns, $column_gap);
    box-sizing: border-box;
    float: left;
    margin-left: $column_gap !important;
    &:first-child {
        margin-left: 0 !important;
    }
}

.row {
    @include grid-container();
}

/*
.col {
    @include col;
    margin-left: $column_gap !important;
    &:first-child {
        margin-left: 0 !important;
    }
}*/

/* Debug */

.grid-debug {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 50%;
    width: $contentwidth;
    margin-left: $contentwidth / 2 * -1;
    pointer-events: none;
    .col {
        @include grid-columns(1);
        //@include grid-width(1);
        height: 100vh;
        background: rgba(255,0,0,.1);
    }
}