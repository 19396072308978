* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-size: 16px;
    //line-height: 1.875;
    color: #333333;
    background-color: #f9f9f9;
    &:not(.redesign-2022-header) {
        @include breakpoint(medium-large) {
            padding-top: 135px;
        }
        @include breakpoint(small) {
            padding-top: 60px;
        }
    }
}

a {
    color: #2D72F7;
    text-decoration: none;
    &:visited {
        color: #ff6631;
    }

    &:hover {
        color: #0000ee;
        text-decoration: underline;
    }
}

dl {
    dt {
        float: left;
        clear: both;
        width: 50%;
    }
    dd {
        float: right;
        width: 50%;
        margin: 0;
        text-align: right;
    }
}

hr {
    height: 4px;
    margin: 32px 0;
    border: 0;
    //border-bottom: 1px solid #D8D8D8;
    background: getGradient(golden-hour);
}

img {
    max-width: 100%;
    width: auto;
    height: auto;
}

figure {
    margin: 0;
    padding: 0;
}

.clearfix {
    *zoom: 1;
    &:before,
    &:after {
        display: table;
        content: '';
        line-height: 0;
    }
    &:after {
        clear: both;
    }
}

.hidden {
    display: none;
    // visibility: hidden;
}

.action {
    float: right;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

[hidden] {
    display: none;
}

// Google maps autocomplete
.pac-icon {
    display: none !important;
}

.pac-item {
    padding-left: 10px;
}

// Recaptcha
.grecaptcha-badge {
    width: auto !important;
    height: auto !important;
    margin-top: 30px;
    box-shadow: none !important;
}

.grecaptcha-badge iframe {
    width: auto !important;
}

.grecaptcha-logo {
    width: 255px;
    margin: 0 auto;
}

@include visibility($type: "block");
@include visibility($type: "flex");
@include visibility($type: "hidden");