.fullwidth-callout {
    $this: &;

    @include breakpoint(large) {
        margin: 100px 0;
    }

    &-container {
        position: relative;
        border-radius: 40px;
        overflow: hidden;
        display: flex;
        align-items: center;
    }

    &-heading {
        margin-top: 0;
        margin-bottom: 2px;
    }

    &-content {
        padding: 60px 53% 60px 150px;
        text-align: left;
        color: #fff;
        position: relative;
        display: flex;
        align-items: center;
        min-height: 440px;
        width: 100%;

        @include breakpoint(medium) {
            padding: 60px 80px;
        }

        @include breakpoint(small) {
            padding: 60px 40px;
        }

        @include breakpoint(small-medium) {
            min-height: 0;
        }

        .button {
            margin-top: 10px;
        }
    }

    &-content-wrapper {
        & > *:last-child {
            margin-bottom: 0;
        }
    }

    &-mask {
        background: no-repeat bottom right url(../images/services-mask.svg);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &-image {
        position: absolute;
        right: 0;
        bottom: 0;
        line-height: 0;

        @include breakpoint(small-medium) {
            display: none;
        }
    }

    &.is-image-centered {
        #{$this}-image {
            bottom: auto;
        }
    }

    &.is-image-left {
        @include breakpoint(large) {
            #{$this}-content {
                padding: 60px 85px 60px 59%;
            }
        }

        #{$this}-image {
            left: 0;
            right: auto;
        }
    }
}