article.text-columns {
    h4 {
        font-size: 20px;
    }

    .col {
        @include breakpoint(medium-large) {
            @include grid-columns(4, 8, 15%);
        }
    }

    &[class*="bg-"] {
        padding: 40px 0;

        @include bp(md) {
            padding: 60px 0;
        }
    }

    &.align-middle .wrapper{
        @include bp(md) {
            display: flex;
            align-items: center;
        }
    }

    &.get-in-touch {
        text-align: center;
        
        @include bp(md) {
            text-align: left;
        }
    }
}