@include aspect-ratios();
@include background-gradients();
@include background-colors();

.callout-box {
    background: #fff;
    box-shadow: 0px 6px 34px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    padding: 50px;
}

.carat {
    border: 1px solid #e8825b;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;

    // Default to Right
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);

    &.left {
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
    }
    
    &.up {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
    }
    
    &.down {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }
}

.breadcrumbs {
    @include layout-list();
    display: inline-block;
    font-size: 14px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    li {
        display: inline-flex;
        align-items: center;
        margin-right: 15px;

        &:after {
            @extend .carat;
            content: "";
        }

        &:last-child:after {
            display: none;
        }

        a {
            margin-right: 15px;
            line-height: 1;

            @include breakpoint(small) {
                margin-right: 8px;
            }
        }

        &:last-child {
            margin-right: 0;

            a {
                margin-right: 0;
            }
        }
    }

    .no-link {
        color: map-get($colors, black);
        pointer-events: none;
    }
}

figure.quote {
    margin: 0;
    blockquote {
        margin: 0;
        font-size: 24px;
        font-weight: 300;
    }

    cite {
        font-weight: bold;
    }
}

ul.pagination {
    @include layout-list();
    margin-top: 20px;

    li {
        display: inline-block;

        a {
            font-weight: bold;
            text-align: center;           
            padding: 20px 20px;
            line-height: 0;
            display: flex;
            align-items: center;
            color: map-get($colors, black);
        }

        &.selected a {
            background: map-get($colors, orange);
            color: #fff;
            border-radius: 8px;
        }

        .carat {
            border-color: #7988e9;
        }
    }
}

ul.carousel-nav {
    @include layout-list();
    margin: 20px 0;
    text-align: center;

    li {
        display: inline-block;

        a {
            display: inline-block;
            text-indent: -50000px;
            width: 15px;
            height: 15px;
            border: 1px solid #ccc;
            border-radius: 50%;
        }

        &.active a {
            border: 0;
            background: #FF6B0B;
        }
    }
}

.mask-wrapper {
    @include curved-box("article");
}

.image-placeholder {
    position: relative;
    width: 100%;

    & > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

@each $bpName, $resolution in $breakpoints {
    @include bp($bpName) {
        .#{$bpName}\:image-placeholder {
            position: relative;
            width: 100%;
        
            & > * {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
