article.get-quote {
    padding: 100px 0;
    background: getGradient(golden-hour);
    color: #fff;
    position: relative;

    .get-quote-success, .error-message {
        display: none;
    }

    .get-quote-container, .get-quote-success {
        @extend .clearfix;
        background: rgba(0, 0, 0, .15);
        border-radius: 20px;
        padding: 60px;
        position: relative;

        p {
          font-size: 24px;
        }

        h3 {
            text-align: left;
        }

        @include breakpoint(small) {
            padding: 20px;
        }

        @include breakpoint(medium) {
            padding: 40px;
        }
        &.two-column {
            .checkbox-container {
                position: relative;
                .error {
                    left: calc(50% - 227px);
                    position: absolute;
                    z-index: 1;
                    &:before {
                        left: calc(50% - 46px);
                        top: calc(50% - 48px);
                    }
                    @include breakpoint(small) {
                        left: 0px;
                    }
                }
            }
        }
        .checkbox-container {
            .error {
                position: relative;
                &:before {
                    left: 40px;
                    top: -27px;
                }
            }
        }
        &.one-column {
            form {
                @include breakpoint(medium-large) {
                    @include grid-columns(8);
                    @include push(2);
                }
            }
        }
           
        &.two-column {
             h2 {
                margin-bottom: 30px;
                padding-bottom: 60px;
                border-bottom: 2px solid rgba(255, 255 , 255, 0.5)
            }
            .first-column {
                text-align: left;
                padding: 0 30px 0 10px;
                @include breakpoint(medium-large) {
                    border-right: 1px solid rgba(255, 255 , 255, 0.5)
                }
            }
            .second-column {
                padding: 0 10px 0 30px;
            }
        }
    }

    h2 {
        text-align: center;
    }

    .get-quote-about {
        margin: 30px 0;
    }

    p.get-quote-required {
        font-size: map-get($text-sizes, sm);
    }

    form {
        p {
            &.intro {
                margin-bottom: 50px;
            }
        }

        .item.city {
            flex-grow: 1.5;
        }

        select {
            border-radius: 16px;
        }

        .buttons {
            margin-top: 30px;
        }

        .disclaimer-checkbox {
            font-size: 14px;
            text-align: left;
            a {
                color: #fff;
                text-decoration: underline;
            }
            input {
                margin-right: 10px;
            }    
        }

        p.legal {
            font-size: 14px;
            text-align: center;

            a {
                color: #fff;
                text-decoration: underline;
            }
        }
    }

    @include breakpoint(small) {
        .checkbox-list {
            text-align: left;
        }
    }
}
.center-quote .get-quote .get-quote-container .checkbox-container .error{
    left: calc(50% - 227px);
    z-index: 1;
    position: absolute;
    @include breakpoint(small) {
        left: 20px;
    }
    &:before {
        left: calc(50% - 46px);
        top: calc(50% - 48px);
    }
}
div.overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0, 0.7);
    overflow-x: hidden;
    transition: 0.5s;
    .overlay-content {
        background-color:  rgb(255,255,255);
        position: absolute;
        height: 180px;
        width: 360px;
        left: calc(50% - 180px);
        top: calc(50% - 90px);
        margin: 0px auto;
        border-radius: 5px;
        padding: 35px;
        .overlay-spinner {
            width: 40px;
            height: 40px;
            margin: 0 auto;
            border: 5px solid;
            border-radius: 50%;
            border-color: transparent rgb(0,0,0) rgb(0,0,0);
            animation: loadingSpinner .7s infinite linear;
                -o-animation: loadingSpinner .7s infinite linear;
                -ms-animation: loadingSpinner .7s infinite linear;
                -webkit-animation: loadingSpinner .7s infinite linear;
                -moz-animation: loadingSpinner .7s infinite linear;
        }
        .overlay-text {
            text-align: center;
            h4 {
                font-size: 24px;
                margin: 20px 0 0 0;
            }
            p {
                font-size: 20px;
                margin: 0;
            }
        }
    }
}

@keyframes loadingSpinner {
    100%{ transform: rotate(360deg); transform: rotate(360deg); }
}

@-o-keyframes loadingSpinner {
    100%{ -o-transform: rotate(360deg); transform: rotate(360deg); }
}

@-ms-keyframes loadingSpinner {
    100%{ -ms-transform: rotate(360deg); transform: rotate(360deg); }
}

@-webkit-keyframes loadingSpinner {
    100%{ -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@-moz-keyframes loadingSpinner {
    100%{ -moz-transform: rotate(360deg); transform: rotate(360deg); }
}
