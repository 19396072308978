/* This file is where the styling for modules will go. This employes the concepts of OOCSS, creating common base classes (using <article> as the base), and extending as necessary */

/*

<article class="module-name">
    <div class="wrapper">
        <header>
            <h2>Module Name</h2>
        </header>
        <!-- Content here -->
    </div>
</article>

*/

article {
    // Careful what's added here. This is the base class, and is used everywhere
    margin: 100px 0;

    @include breakpoint(small) {
        margin: 60px 0;
    }

}

.layout {
    & > article:first-of-type {
        margin-top: 0; 
    }
}

.mainContent, .extraContent, .sidebar {
    @include breakpoint(small) {
        & > * {
            margin: 20px 0 50px 0;
            padding: 0;
        }
    }
}

@import "modules/about-sunnova";
@import "modules/breadcrumbs-wrapper";
@import "modules/callouts";
@import "modules/carousel";
@import "modules/charity-listing";
@import "modules/comparison-table";
@import "modules/blog-listing";
@import "modules/fullwidth-callout";
@import "modules/get-quote";
@import "modules/hero";
@import "modules/homepage-hero";
@import "modules/hr";
@import "modules/image";
@import "modules/image-collage";
@import "modules/info-card";
@import "modules/infographic";
@import "modules/news-press";
@import "modules/page-intro";
@import "modules/partner-form";
@import "modules/product-cards";
@import "modules/product-highlights";
@import "modules/services-availability";
@import "modules/setup-steps";
@import "modules/sidebar-nav";
@import "modules/state-listing";
@import "modules/testimonial";
@import "modules/text-columns";
@import "modules/text";
@import "modules/video";
@import "modules/modal";
@import "modules/blog-featured-cards";
@import "modules/reading-list";
@import "modules/faq";
@import "modules/autocomplete";
@import "modules/faq-listing";