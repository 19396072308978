/*------------------------------------------------------------------

IMPORTANT: This file is generated by Sass. Do not modify this file directly.

-------------------------------------------------------------------- */
/* Sizes */
/* Fonts */
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-size: 16px;
  color: #333333;
  background-color: #f9f9f9;
}

@media only screen and (min-width: 768px) {
  body:not(.redesign-2022-header) {
    padding-top: 135px;
  }
}

@media only screen and (max-width: 767px) {
  body:not(.redesign-2022-header) {
    padding-top: 60px;
  }
}

a {
  color: #2D72F7;
  text-decoration: none;
}

a:visited {
  color: #ff6631;
}

a:hover {
  color: #0000ee;
  text-decoration: underline;
}

dl dt {
  float: left;
  clear: both;
  width: 50%;
}

dl dd {
  float: right;
  width: 50%;
  margin: 0;
  text-align: right;
}

hr {
  height: 4px;
  margin: 32px 0;
  border: 0;
  background: linear-gradient(10deg, #ff6633 0%, #ffcc00 100%);
}

img {
  max-width: 100%;
  width: auto;
  height: auto;
}

figure {
  margin: 0;
  padding: 0;
}

.clearfix, .layout, .wrapper, form .item,
.form .item, article.get-quote .get-quote-container, article.get-quote .get-quote-success, article.partner-form .partner-form-container, article.partner-form .partner-form-success {
  *zoom: 1;
}

.clearfix:before, .layout:before, .wrapper:before, form .item:before,
.form .item:before, article.get-quote .get-quote-container:before, article.get-quote .get-quote-success:before, article.partner-form .partner-form-container:before, article.partner-form .partner-form-success:before, .clearfix:after, .layout:after, .wrapper:after, form .item:after,
.form .item:after, article.get-quote .get-quote-container:after, article.get-quote .get-quote-success:after, article.partner-form .partner-form-container:after, article.partner-form .partner-form-success:after {
  display: table;
  content: '';
  line-height: 0;
}

.clearfix:after, .layout:after, .wrapper:after, form .item:after,
.form .item:after, article.get-quote .get-quote-container:after, article.get-quote .get-quote-success:after, article.partner-form .partner-form-container:after, article.partner-form .partner-form-success:after {
  clear: both;
}

.hidden {
  display: none;
}

.action {
  float: right;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

[hidden] {
  display: none;
}

.pac-icon {
  display: none !important;
}

.pac-item {
  padding-left: 10px;
}

.grecaptcha-badge {
  width: auto !important;
  height: auto !important;
  margin-top: 30px;
  box-shadow: none !important;
}

.grecaptcha-badge iframe {
  width: auto !important;
}

.grecaptcha-logo {
  width: 255px;
  margin: 0 auto;
}

@media screen and (min-width: 568px) {
  .xs\:block {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .sm\:block {
    display: block;
  }
}

@media screen and (min-width: 1024px) {
  .md\:block {
    display: block;
  }
}

@media screen and (min-width: 1280px) {
  .lg\:block {
    display: block;
  }
}

@media screen and (min-width: 1360px) {
  .xl\:block {
    display: block;
  }
}

@media screen and (min-width: 568px) {
  .xs\:flex {
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  .sm\:flex {
    display: flex;
  }
}

@media screen and (min-width: 1024px) {
  .md\:flex {
    display: flex;
  }
}

@media screen and (min-width: 1280px) {
  .lg\:flex {
    display: flex;
  }
}

@media screen and (min-width: 1360px) {
  .xl\:flex {
    display: flex;
  }
}

@media screen and (min-width: 568px) {
  .xs\:hidden {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .sm\:hidden {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .md\:hidden {
    display: none;
  }
}

@media screen and (min-width: 1280px) {
  .lg\:hidden {
    display: none;
  }
}

@media screen and (min-width: 1360px) {
  .xl\:hidden {
    display: none;
  }
}

/*
 * Grid System
 *
 * This is a grid system based off of floats and CSS calc() to calculate percentage-based widths and margins. This allows the grid columns to be flexible to a responsive viewport, with fixed gaps between each column. Grids can also be nested and still maintain the consistent gap for consistent layout.
 *
 * Setup:
 *
 * Set up the grid variables to specify the total number of columns, as well as the gap size between each column.
 *
 * Usage:
 * 
 * @include grid-columns($num, $max_columns, $column_gap)
 *     This is used to set a column and specify how many columns it should span
 * 
 *   Options
 *     $num [required] -> Total number of columns to span
 *     $max_columns [optional] -> Defaults to the global variable, but can be set if you want to establish a new grid context
 *     $column_gap [optional] -> Defaults to the global variable, but can be set if you want to establish a new column gap
 *
 * @include grid-width($num, $max_columns, $column_gap)
 *     This is used to return a width based on the number of columns specified
 *
 *   Options
 *     $num [required] -> Total number of columns to span
 *     $max_columns [optional] -> Defaults to the global variable, but can be set if you want to establish a new grid context
 *     $column_gap [optional] -> Defaults to the global variable, but can be set if you want to establish a new column gap
 *
 * /

/* --- Grid variables ---*/
.row:after {
  content: "";
  display: table;
  clear: both;
}

/*
.col {
    @include col;
    margin-left: $column_gap !important;
    &:first-child {
        margin-left: 0 !important;
    }
}*/
/* Debug */
.grid-debug {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 1360px;
  margin-left: -680px;
  pointer-events: none;
}

.grid-debug .col {
  width: calc((((100% - 220px) / 12) * 1) + 0px - .1px);
  box-sizing: border-box;
  float: left;
  margin-left: 20px !important;
  height: 100vh;
  background: rgba(255, 0, 0, 0.1);
}

.grid-debug .col:first-child {
  margin-left: 0 !important;
}

.flex-grid {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  margin: 0 -10px;
  flex-wrap: wrap;
}

.flex-grid > [class*="is-"] {
  padding: 0 10px;
  min-width: 0;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
  max-width: 100%;
}

.flex-grid > [class*="is-"]:last-child {
  margin-bottom: 0;
}

.flex-grid .is-full {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
  max-width: 100%;
}

.flex-grid .is-1\/2 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 50%;
  max-width: 50%;
}

.flex-grid .is-1\/3 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 33.3333%;
  max-width: 33.3333%;
}

.flex-grid .is-2\/3 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 66.6666%;
  max-width: 66.6666%;
}

.flex-grid .is-1\/4 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 25%;
  max-width: 25%;
}

.flex-grid .is-3\/4 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 75%;
  max-width: 75%;
}

.flex-grid .is-1\/6 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 16.66666%;
  max-width: 16.66666%;
}

.flex-grid .is-5\/6 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 83.3333%;
  max-width: 83.3333%;
}

.flex-grid .is-1\/5 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 20%;
  max-width: 20%;
}

.flex-grid .is-2\/5 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 40%;
  max-width: 40%;
}

.flex-grid .is-3\/5 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 60%;
  max-width: 60%;
}

.flex-grid .is-4\/5 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 80%;
  max-width: 80%;
}

@media screen and (min-width: 568px) {
  .flex-grid .xs\:is-full {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%;
    max-width: 100%;
  }
  .flex-grid .xs\:is-1\/2 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    max-width: 50%;
  }
  .flex-grid .xs\:is-1\/3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }
  .flex-grid .xs\:is-2\/3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 66.6666%;
    max-width: 66.6666%;
  }
  .flex-grid .xs\:is-1\/4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 25%;
    max-width: 25%;
  }
  .flex-grid .xs\:is-3\/4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 75%;
    max-width: 75%;
  }
  .flex-grid .xs\:is-1\/6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 16.66666%;
    max-width: 16.66666%;
  }
  .flex-grid .xs\:is-5\/6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 83.3333%;
    max-width: 83.3333%;
  }
  .flex-grid .xs\:is-1\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 20%;
    max-width: 20%;
  }
  .flex-grid .xs\:is-2\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 40%;
    max-width: 40%;
  }
  .flex-grid .xs\:is-3\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 60%;
    max-width: 60%;
  }
  .flex-grid .xs\:is-4\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 80%;
    max-width: 80%;
  }
}

@media screen and (min-width: 768px) {
  .flex-grid .sm\:is-full {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%;
    max-width: 100%;
  }
  .flex-grid .sm\:is-1\/2 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    max-width: 50%;
  }
  .flex-grid .sm\:is-1\/3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }
  .flex-grid .sm\:is-2\/3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 66.6666%;
    max-width: 66.6666%;
  }
  .flex-grid .sm\:is-1\/4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 25%;
    max-width: 25%;
  }
  .flex-grid .sm\:is-3\/4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 75%;
    max-width: 75%;
  }
  .flex-grid .sm\:is-1\/6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 16.66666%;
    max-width: 16.66666%;
  }
  .flex-grid .sm\:is-5\/6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 83.3333%;
    max-width: 83.3333%;
  }
  .flex-grid .sm\:is-1\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 20%;
    max-width: 20%;
  }
  .flex-grid .sm\:is-2\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 40%;
    max-width: 40%;
  }
  .flex-grid .sm\:is-3\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 60%;
    max-width: 60%;
  }
  .flex-grid .sm\:is-4\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 80%;
    max-width: 80%;
  }
}

@media screen and (min-width: 1024px) {
  .flex-grid .md\:is-full {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%;
    max-width: 100%;
  }
  .flex-grid .md\:is-1\/2 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    max-width: 50%;
  }
  .flex-grid .md\:is-1\/3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }
  .flex-grid .md\:is-2\/3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 66.6666%;
    max-width: 66.6666%;
  }
  .flex-grid .md\:is-1\/4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 25%;
    max-width: 25%;
  }
  .flex-grid .md\:is-3\/4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 75%;
    max-width: 75%;
  }
  .flex-grid .md\:is-1\/6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 16.66666%;
    max-width: 16.66666%;
  }
  .flex-grid .md\:is-5\/6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 83.3333%;
    max-width: 83.3333%;
  }
  .flex-grid .md\:is-1\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 20%;
    max-width: 20%;
  }
  .flex-grid .md\:is-2\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 40%;
    max-width: 40%;
  }
  .flex-grid .md\:is-3\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 60%;
    max-width: 60%;
  }
  .flex-grid .md\:is-4\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 80%;
    max-width: 80%;
  }
}

@media screen and (min-width: 1280px) {
  .flex-grid .lg\:is-full {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%;
    max-width: 100%;
  }
  .flex-grid .lg\:is-1\/2 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    max-width: 50%;
  }
  .flex-grid .lg\:is-1\/3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }
  .flex-grid .lg\:is-2\/3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 66.6666%;
    max-width: 66.6666%;
  }
  .flex-grid .lg\:is-1\/4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 25%;
    max-width: 25%;
  }
  .flex-grid .lg\:is-3\/4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 75%;
    max-width: 75%;
  }
  .flex-grid .lg\:is-1\/6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 16.66666%;
    max-width: 16.66666%;
  }
  .flex-grid .lg\:is-5\/6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 83.3333%;
    max-width: 83.3333%;
  }
  .flex-grid .lg\:is-1\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 20%;
    max-width: 20%;
  }
  .flex-grid .lg\:is-2\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 40%;
    max-width: 40%;
  }
  .flex-grid .lg\:is-3\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 60%;
    max-width: 60%;
  }
  .flex-grid .lg\:is-4\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 80%;
    max-width: 80%;
  }
}

@media screen and (min-width: 1360px) {
  .flex-grid .xl\:is-full {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%;
    max-width: 100%;
  }
  .flex-grid .xl\:is-1\/2 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    max-width: 50%;
  }
  .flex-grid .xl\:is-1\/3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }
  .flex-grid .xl\:is-2\/3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 66.6666%;
    max-width: 66.6666%;
  }
  .flex-grid .xl\:is-1\/4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 25%;
    max-width: 25%;
  }
  .flex-grid .xl\:is-3\/4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 75%;
    max-width: 75%;
  }
  .flex-grid .xl\:is-1\/6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 16.66666%;
    max-width: 16.66666%;
  }
  .flex-grid .xl\:is-5\/6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 83.3333%;
    max-width: 83.3333%;
  }
  .flex-grid .xl\:is-1\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 20%;
    max-width: 20%;
  }
  .flex-grid .xl\:is-2\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 40%;
    max-width: 40%;
  }
  .flex-grid .xl\:is-3\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 60%;
    max-width: 60%;
  }
  .flex-grid .xl\:is-4\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 80%;
    max-width: 80%;
  }
}

@media only screen and (max-width: 1023px) {
  .flex-grid > [class*="is-"] {
    margin-bottom: 30px;
  }
}

.layout-columns {
  margin-top: 50px;
}

@media only screen and (min-width: 768px) {
  .layout-columns > section {
    width: calc((((100% - 605px) / 12) * 9) + 440px - .1px);
    box-sizing: border-box;
    float: left;
    margin-left: 55px !important;
  }
  .layout-columns > section:first-child {
    margin-left: 0 !important;
  }
  .layout-columns > aside {
    width: calc((((100% - 605px) / 12) * 3) + 110px - .1px);
    box-sizing: border-box;
    float: left;
    margin-left: 55px !important;
  }
  .layout-columns > aside:first-child {
    margin-left: 0 !important;
  }
}

.site-wrapper {
  max-width: 1930px;
  margin: 0 auto;
  background-color: #fff;
}

.site-wrapper.experience-editor .site-hader {
  position: relative;
  margin-top: -130px;
}

.wrapper {
  max-width: 1360px;
  margin: 0 auto;
  padding: 0 20px;
}

.wrapper .wrapper,
.wrapper .wrapper-lg,
.wrapper .wrapper-md,
.wrapper .wrapper-sm {
  padding: 0;
}

@media only screen and (min-width: 768px) {
  .grid {
    display: table;
    width: 100%;
    border-spacing: 10px;
    margin: -10px;
  }
  .grid .col {
    display: table-cell;
    width: 50%;
    vertical-align: top;
  }
  .grid.three .col {
    width: 33.33%;
  }
  .grid.four .col {
    width: 25%;
  }
  .grid.border .col {
    border-left: 1px solid #cccccc;
    padding: 0 30px;
  }
  .grid.border .col:first-child {
    border-left: 0;
    padding-left: 0;
  }
  .grid.border .col:last-child {
    padding-right: 0;
  }
  .grid.bg .col {
    background: #f3f3f3;
    padding: 20px;
  }
}

.wrapper-lg, .wrapper-md, .wrapper-sm {
  padding: 0 20px;
}

@media only screen and (min-width: 1024px) {
  .wrapper-lg, .wrapper-md, .wrapper-sm {
    margin: 0 auto;
  }
}

.wrapper-lg .wrapper, .wrapper-md .wrapper, .wrapper-sm .wrapper {
  padding: 0;
}

@media only screen and (min-width: 1024px) {
  .wrapper-lg {
    max-width: 1280px;
  }
}

@media only screen and (min-width: 1024px) {
  .wrapper-md {
    max-width: 1024px;
  }
}

@media only screen and (min-width: 1024px) {
  .wrapper-sm {
    max-width: 768px;
  }
}

/* http://www.fontspring.com/blog/further-hardening-of-the-bulletproof-syntax */
html {
  font-family: acumin-pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button,
input,
select,
textarea {
  font-family: acumin-pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: acumin-pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  margin: 10px 0;
  line-height: 1.25;
}

h1 {
  font-size: 48px;
  line-height: 1.15;
  letter-spacing: -0.04em;
}

h2 {
  font-size: 36px;
  line-height: 1.25;
}

h3 {
  font-size: 28px;
  line-height: 1.25;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 16px;
}

p {
  font-size: inherit;
  line-height: 1.5;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  h1 {
    font-size: 40px;
    line-height: 1.125em;
  }
  h2 {
    font-size: 32px;
    line-height: 1.25em;
  }
  h3 {
    font-size: 24px;
    line-height: 1.25em;
  }
  h4 {
    font-size: 21px;
    letter-spacing: 0;
  }
  h5 {
    font-size: 16px;
    letter-spacing: 0;
  }
  h6 {
    font-size: 16px;
    letter-spacing: 8px;
  }
  p {
    font-size: 16px;
    line-height: 1.375em;
  }
}

@media only screen and (max-width: 767px) {
  h1 {
    font-size: 32px;
    line-height: 1.25em;
  }
  h2 {
    font-size: 26px;
    line-height: 1.15384615em;
  }
  h3 {
    font-size: 22px;
    line-height: 1.13636364em;
  }
  h4 {
    font-size: 21px;
    letter-spacing: 0;
  }
  h5 {
    font-size: 16px;
    letter-spacing: 0;
  }
  h6 {
    font-size: 16px;
    letter-spacing: 8px;
  }
  p {
    line-height: 1.4;
  }
}

ul ul,
ul ol,
ol ul,
ol ol {
  margin: 0;
}

.text-2xs {
  font-size: 10px;
}

.text-xs {
  font-size: 12px;
}

.text-sm {
  font-size: 14px;
}

.text-base {
  font-size: 16px;
}

.text-md {
  font-size: 20px;
}

.text-lg {
  font-size: 24px;
}

.text-xl {
  font-size: 32px;
}

.text-2xl {
  font-size: 40px;
}

.text-3xl {
  font-size: 48px;
}

.text-4xl {
  font-size: 56px;
}

.text-5xl {
  font-size: 64px;
}

.text-6xl {
  font-size: 72px;
}

.text-7xl {
  font-size: 88px;
}

@media screen and (min-width: 568px) {
  .xs\:text-2xs {
    font-size: 10px;
  }
  .xs\:text-xs {
    font-size: 12px;
  }
  .xs\:text-sm {
    font-size: 14px;
  }
  .xs\:text-base {
    font-size: 16px;
  }
  .xs\:text-md {
    font-size: 20px;
  }
  .xs\:text-lg {
    font-size: 24px;
  }
  .xs\:text-xl {
    font-size: 32px;
  }
  .xs\:text-2xl {
    font-size: 40px;
  }
  .xs\:text-3xl {
    font-size: 48px;
  }
  .xs\:text-4xl {
    font-size: 56px;
  }
  .xs\:text-5xl {
    font-size: 64px;
  }
  .xs\:text-6xl {
    font-size: 72px;
  }
  .xs\:text-7xl {
    font-size: 88px;
  }
}

@media screen and (min-width: 768px) {
  .sm\:text-2xs {
    font-size: 10px;
  }
  .sm\:text-xs {
    font-size: 12px;
  }
  .sm\:text-sm {
    font-size: 14px;
  }
  .sm\:text-base {
    font-size: 16px;
  }
  .sm\:text-md {
    font-size: 20px;
  }
  .sm\:text-lg {
    font-size: 24px;
  }
  .sm\:text-xl {
    font-size: 32px;
  }
  .sm\:text-2xl {
    font-size: 40px;
  }
  .sm\:text-3xl {
    font-size: 48px;
  }
  .sm\:text-4xl {
    font-size: 56px;
  }
  .sm\:text-5xl {
    font-size: 64px;
  }
  .sm\:text-6xl {
    font-size: 72px;
  }
  .sm\:text-7xl {
    font-size: 88px;
  }
}

@media screen and (min-width: 1024px) {
  .md\:text-2xs {
    font-size: 10px;
  }
  .md\:text-xs {
    font-size: 12px;
  }
  .md\:text-sm {
    font-size: 14px;
  }
  .md\:text-base {
    font-size: 16px;
  }
  .md\:text-md {
    font-size: 20px;
  }
  .md\:text-lg {
    font-size: 24px;
  }
  .md\:text-xl {
    font-size: 32px;
  }
  .md\:text-2xl {
    font-size: 40px;
  }
  .md\:text-3xl {
    font-size: 48px;
  }
  .md\:text-4xl {
    font-size: 56px;
  }
  .md\:text-5xl {
    font-size: 64px;
  }
  .md\:text-6xl {
    font-size: 72px;
  }
  .md\:text-7xl {
    font-size: 88px;
  }
}

@media screen and (min-width: 1280px) {
  .lg\:text-2xs {
    font-size: 10px;
  }
  .lg\:text-xs {
    font-size: 12px;
  }
  .lg\:text-sm {
    font-size: 14px;
  }
  .lg\:text-base {
    font-size: 16px;
  }
  .lg\:text-md {
    font-size: 20px;
  }
  .lg\:text-lg {
    font-size: 24px;
  }
  .lg\:text-xl {
    font-size: 32px;
  }
  .lg\:text-2xl {
    font-size: 40px;
  }
  .lg\:text-3xl {
    font-size: 48px;
  }
  .lg\:text-4xl {
    font-size: 56px;
  }
  .lg\:text-5xl {
    font-size: 64px;
  }
  .lg\:text-6xl {
    font-size: 72px;
  }
  .lg\:text-7xl {
    font-size: 88px;
  }
}

@media screen and (min-width: 1360px) {
  .xl\:text-2xs {
    font-size: 10px;
  }
  .xl\:text-xs {
    font-size: 12px;
  }
  .xl\:text-sm {
    font-size: 14px;
  }
  .xl\:text-base {
    font-size: 16px;
  }
  .xl\:text-md {
    font-size: 20px;
  }
  .xl\:text-lg {
    font-size: 24px;
  }
  .xl\:text-xl {
    font-size: 32px;
  }
  .xl\:text-2xl {
    font-size: 40px;
  }
  .xl\:text-3xl {
    font-size: 48px;
  }
  .xl\:text-4xl {
    font-size: 56px;
  }
  .xl\:text-5xl {
    font-size: 64px;
  }
  .xl\:text-6xl {
    font-size: 72px;
  }
  .xl\:text-7xl {
    font-size: 88px;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table th, table td {
  padding: 10px 0;
  border-bottom: 1px solid #DADADA;
}

table th {
  color: #e8825b;
  text-align: left;
}

table thead th {
  border-bottom: 2px solid #e8825b;
}

input[type="text"], input[type="password"], input[type="search"], input[type="number"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="email"], input[type="url"], input[type="tel"], input[type="color"],
textarea,
select {
  transition: border linear 0.2s, box-shadow linear 0.2s;
  display: inline-block;
  width: 250px;
  padding: 10px 15px;
  border: 1px solid #eaeaea;
  border-radius: 16px;
  background-color: #fff;
  vertical-align: middle;
  -webkit-appearance: none;
}

@media only screen and (max-width: 767px) {
  input[type="text"], input[type="password"], input[type="search"], input[type="number"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="email"], input[type="url"], input[type="tel"], input[type="color"],
  textarea,
  select {
    width: 100%;
  }
}

input[type="text"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="number"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="time"]:focus, input[type="week"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="tel"]:focus, input[type="color"]:focus,
textarea:focus,
select:focus {
  border-color: #acacac;
  outline: none;
}

fieldset {
  border: none;
}

input[type='search'] {
  padding: 0 25px 0 5px;
}

textarea {
  width: 100%;
  height: 200px;
}

select {
  position: relative;
  padding-right: 50px;
  border-radius: 0;
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
  -webkit-appearance: none;
}

select::-ms-expand {
  display: none;
}

.lt-ie10 select {
  padding: 0 0 0 5px;
  background: white;
}

button,
a.button {
  display: inline-block;
  height: auto;
  width: auto;
  padding: 15px 50px;
  border: 0;
  border-radius: 4px;
  background: linear-gradient(10deg, #ff6633 0%, #ffcc00 100%);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
  color: #fff;
  font-family: acumin-pro-condensed, acumin-pro, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
}

button.secondary,
a.button.secondary {
  background: linear-gradient(10deg, #33ccff 0%, #a542ef 100%);
}

button.golden-hour,
a.button.golden-hour {
  background: linear-gradient(10deg, #ff6633 0%, #ffcc00 100%);
}

button.blue-hour,
a.button.blue-hour {
  background: linear-gradient(10deg, #333399 0%, #33ccff 100%);
}

button.aurora,
a.button.aurora {
  background: linear-gradient(10deg, #33ccff 0%, #a542ef 100%);
}

button.plen-air,
a.button.plen-air {
  background: linear-gradient(10deg, #04a5ff 0%, #6cffa9 100%);
}

button.green-shades,
a.button.green-shades {
  background: linear-gradient(10deg, #16481a 0%, #a5e226 100%);
}

button.stone-white,
a.button.stone-white {
  background: linear-gradient(201.18deg, #fff 49%, #b3b3b3 141%);
}

button:disabled,
a.button:disabled {
  opacity: 0.5;
}

[role='button'] {
  cursor: pointer;
}

.loadingSpinner {
  border: 4px solid #fff;
  border-top-color: rgba(0, 0, 0, 0);
  border-left-color: rgba(0, 0, 0, 0);
  position: absolute;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  opacity: 1;
  border-radius: 50%;
  animation: loadingSpinner .7s infinite linear;
  -webkit-animation: loadingSpinner .7s infinite linear;
}

@keyframes loadingSpinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loadingSpinner {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

form div.validationSummary,
.form div.validationSummary {
  margin-bottom: 10px;
  color: #be3438;
}

form div.validationSummary ul,
.form div.validationSummary ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

form .item,
.form .item {
  margin-bottom: 5px;
}

form .item > label,
.form .item > label {
  display: inline-block;
}

form .item > label span,
.form .item > label span {
  font-weight: normal;
}

form .item input,
.form .item input {
  vertical-align: baseline;
}

form .item > ul,
.form .item > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

form .item.horizontal > label,
.form .item.horizontal > label {
  display: inline-block;
  margin-right: 5px;
}

form .item.fl-enabled,
.form .item.fl-enabled {
  position: relative;
}

form .item.fl-enabled > label,
form .item.fl-enabled .label,
.form .item.fl-enabled > label,
.form .item.fl-enabled .label {
  position: absolute;
  top: 20px;
  left: 1px;
  padding: 0 15px;
  color: #acacac;
  opacity: 0;
  font-size: 12px;
  line-height: 24px;
  z-index: 2;
}

form .item.fl-enabled input[type="text"], form .item.fl-enabled input[type="password"], form .item.fl-enabled input[type="search"], form .item.fl-enabled input[type="number"], form .item.fl-enabled input[type="datetime"], form .item.fl-enabled input[type="datetime-local"], form .item.fl-enabled input[type="date"], form .item.fl-enabled input[type="month"], form .item.fl-enabled input[type="time"], form .item.fl-enabled input[type="week"], form .item.fl-enabled input[type="email"], form .item.fl-enabled input[type="url"], form .item.fl-enabled input[type="tel"], form .item.fl-enabled input[type="color"],
form .item.fl-enabled textarea,
form .item.fl-enabled select,
.form .item.fl-enabled input[type="text"],
.form .item.fl-enabled input[type="password"],
.form .item.fl-enabled input[type="search"],
.form .item.fl-enabled input[type="number"],
.form .item.fl-enabled input[type="datetime"],
.form .item.fl-enabled input[type="datetime-local"],
.form .item.fl-enabled input[type="date"],
.form .item.fl-enabled input[type="month"],
.form .item.fl-enabled input[type="time"],
.form .item.fl-enabled input[type="week"],
.form .item.fl-enabled input[type="email"],
.form .item.fl-enabled input[type="url"],
.form .item.fl-enabled input[type="tel"],
.form .item.fl-enabled input[type="color"],
.form .item.fl-enabled textarea,
.form .item.fl-enabled select {
  padding-top: 17px;
  padding-bottom: 17px;
  margin-top: 5px;
}

form .item.fl-enabled.fl-active input[type="text"], form .item.fl-enabled.fl-active input[type="password"], form .item.fl-enabled.fl-active input[type="search"], form .item.fl-enabled.fl-active input[type="number"], form .item.fl-enabled.fl-active input[type="datetime"], form .item.fl-enabled.fl-active input[type="datetime-local"], form .item.fl-enabled.fl-active input[type="date"], form .item.fl-enabled.fl-active input[type="month"], form .item.fl-enabled.fl-active input[type="time"], form .item.fl-enabled.fl-active input[type="week"], form .item.fl-enabled.fl-active input[type="email"], form .item.fl-enabled.fl-active input[type="url"], form .item.fl-enabled.fl-active input[type="tel"], form .item.fl-enabled.fl-active input[type="color"],
form .item.fl-enabled.fl-active textarea,
form .item.fl-enabled.fl-active select,
.form .item.fl-enabled.fl-active input[type="text"],
.form .item.fl-enabled.fl-active input[type="password"],
.form .item.fl-enabled.fl-active input[type="search"],
.form .item.fl-enabled.fl-active input[type="number"],
.form .item.fl-enabled.fl-active input[type="datetime"],
.form .item.fl-enabled.fl-active input[type="datetime-local"],
.form .item.fl-enabled.fl-active input[type="date"],
.form .item.fl-enabled.fl-active input[type="month"],
.form .item.fl-enabled.fl-active input[type="time"],
.form .item.fl-enabled.fl-active input[type="week"],
.form .item.fl-enabled.fl-active input[type="email"],
.form .item.fl-enabled.fl-active input[type="url"],
.form .item.fl-enabled.fl-active input[type="tel"],
.form .item.fl-enabled.fl-active input[type="color"],
.form .item.fl-enabled.fl-active textarea,
.form .item.fl-enabled.fl-active select {
  padding-top: 24px;
  padding-bottom: 10px;
}

form .item.fl-enabled.fl-active > label,
form .item.fl-enabled.fl-active .label,
.form .item.fl-enabled.fl-active > label,
.form .item.fl-enabled.fl-active .label {
  top: 10px;
  opacity: 1;
}

form .item.fl-enabled.fl-animate input[type="text"], form .item.fl-enabled.fl-animate input[type="password"], form .item.fl-enabled.fl-animate input[type="search"], form .item.fl-enabled.fl-animate input[type="number"], form .item.fl-enabled.fl-animate input[type="datetime"], form .item.fl-enabled.fl-animate input[type="datetime-local"], form .item.fl-enabled.fl-animate input[type="date"], form .item.fl-enabled.fl-animate input[type="month"], form .item.fl-enabled.fl-animate input[type="time"], form .item.fl-enabled.fl-animate input[type="week"], form .item.fl-enabled.fl-animate input[type="email"], form .item.fl-enabled.fl-animate input[type="url"], form .item.fl-enabled.fl-animate input[type="tel"], form .item.fl-enabled.fl-animate input[type="color"],
form .item.fl-enabled.fl-animate textarea,
form .item.fl-enabled.fl-animate select,
form .item.fl-enabled.fl-animate > label,
form .item.fl-enabled.fl-animate .label,
.form .item.fl-enabled.fl-animate input[type="text"],
.form .item.fl-enabled.fl-animate input[type="password"],
.form .item.fl-enabled.fl-animate input[type="search"],
.form .item.fl-enabled.fl-animate input[type="number"],
.form .item.fl-enabled.fl-animate input[type="datetime"],
.form .item.fl-enabled.fl-animate input[type="datetime-local"],
.form .item.fl-enabled.fl-animate input[type="date"],
.form .item.fl-enabled.fl-animate input[type="month"],
.form .item.fl-enabled.fl-animate input[type="time"],
.form .item.fl-enabled.fl-animate input[type="week"],
.form .item.fl-enabled.fl-animate input[type="email"],
.form .item.fl-enabled.fl-animate input[type="url"],
.form .item.fl-enabled.fl-animate input[type="tel"],
.form .item.fl-enabled.fl-animate input[type="color"],
.form .item.fl-enabled.fl-animate textarea,
.form .item.fl-enabled.fl-animate select,
.form .item.fl-enabled.fl-animate > label,
.form .item.fl-enabled.fl-animate .label {
  transition: all 0.25s;
}

@media only screen and (min-width: 768px) {
  form .group,
  .form .group {
    display: flex;
    margin: 0 -10px;
  }
  form .group .item,
  .form .group .item {
    margin: 10px;
    text-align: left;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
  }
  form .group .item input[type="text"], form .group .item input[type="password"], form .group .item input[type="search"], form .group .item input[type="number"], form .group .item input[type="datetime"], form .group .item input[type="datetime-local"], form .group .item input[type="date"], form .group .item input[type="month"], form .group .item input[type="time"], form .group .item input[type="week"], form .group .item input[type="email"], form .group .item input[type="url"], form .group .item input[type="tel"], form .group .item input[type="color"],
  form .group .item textarea,
  form .group .item select,
  .form .group .item input[type="text"],
  .form .group .item input[type="password"],
  .form .group .item input[type="search"],
  .form .group .item input[type="number"],
  .form .group .item input[type="datetime"],
  .form .group .item input[type="datetime-local"],
  .form .group .item input[type="date"],
  .form .group .item input[type="month"],
  .form .group .item input[type="time"],
  .form .group .item input[type="week"],
  .form .group .item input[type="email"],
  .form .group .item input[type="url"],
  .form .group .item input[type="tel"],
  .form .group .item input[type="color"],
  .form .group .item textarea,
  .form .group .item select {
    width: 100%;
  }
  form .group .item .group .item,
  .form .group .item .group .item {
    margin: 0 10px;
  }
}

form .buttons,
.form .buttons {
  text-align: center;
}

form .buttons button,
form .buttons .button,
.form .buttons button,
.form .buttons .button {
  margin: 10px 0 0 10px;
}

@media only screen and (max-width: 767px) {
  form .buttons button,
  form .buttons .button,
  .form .buttons button,
  .form .buttons .button {
    width: 100%;
    margin: 0 0 10px 0;
  }
}

form input[type='radio'],
form input[type='checkbox'],
.form input[type='radio'],
.form input[type='checkbox'] {
  margin-bottom: 0;
  margin-right: 5px;
}

form .radio-list,
form .checkbox-list,
.form .radio-list,
.form .checkbox-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

form .radio-list input,
form .radio-list label,
form .checkbox-list input,
form .checkbox-list label,
.form .radio-list input,
.form .radio-list label,
.form .checkbox-list input,
.form .checkbox-list label {
  display: inline-block;
}

form .radio-list.inline li,
form .checkbox-list.inline li,
.form .radio-list.inline li,
.form .checkbox-list.inline li {
  display: inline-block;
  margin-right: 10px;
}

form span.error,
.form span.error {
  text-align: left;
  float: left;
  color: #fff;
  font-size: 14px;
  margin-top: 4px;
  line-height: 1.25;
  background: #ef3039;
  padding: 12px;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

form span.error::before,
.form span.error::before {
  position: absolute;
  top: calc(50% - 14px);
  left: 40px;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  pointer-events: none;
  border-color: rgba(45, 114, 247, 0);
  border-bottom-color: #ef3039;
  border-width: 14px;
}

.custom-checkbox {
  min-height: 30px;
  /*Outer box of the fake checkbox*/
  /*Checkmark of the fake checkbox*/
  /*Hide the checkmark by default*/
  /*Unhide on the checked state*/
  /*Adding focus styles on the outer-box of the fake checkbox*/
}

.custom-checkbox input[type='checkbox'],
.custom-checkbox input[type='radio'] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.custom-checkbox label {
  position: relative;
  display: inline-block;
  padding-left: 32px;
}

.custom-checkbox label::before,
.custom-checkbox label::after {
  position: absolute;
  content: '';
  display: inline-block;
}

.custom-checkbox label::before {
  height: 22px;
  width: 22px;
  left: 0px;
  top: 1px;
  border: 2px solid #ececec;
  background: #fff;
  border-radius: 2px;
}

.custom-checkbox input[type='radio'] + label::before {
  border-radius: 50%;
  top: 0;
}

.custom-checkbox input[type='checkbox'] + label::after {
  height: 5px;
  width: 13px;
  border-left: 3px solid #fff;
  border-bottom: 3px solid #fff;
  transform: rotate(-45deg);
  left: 5px;
  top: 8px;
}

.custom-checkbox input[type='radio'] + label::after {
  height: 16px;
  width: 16px;
  background: #2d72f7;
  border-radius: 50%;
  left: 5px;
  top: 5px;
}

.custom-checkbox input[type='checkbox'] + label::after,
.custom-checkbox input[type='radio'] + label::after {
  content: none;
}

.custom-checkbox input[type='checkbox']:checked + label::before {
  background: #2d72f7;
  border-color: #2d72f7;
}

.custom-checkbox input[type='radio']:checked + label::before {
  background: #fff;
  border-color: #fff;
}

.custom-checkbox input[type='checkbox']:checked + label::after,
.custom-checkbox input[type='radio']:checked + label::after {
  content: '';
}

.custom-checkbox input[type='checkbox']:focus + label::before,
.custom-checkbox input[type='radio']:focus + label::before {
  outline: #3b99fc auto 5px;
}

/* Custom dropdown */
.custom-dropdown {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 50px;
  background: linear-gradient(192.87deg, #FFFFFF 48.72%, #B3B3B3 141.8%);
  border: 1px solid #CFCFCF;
  box-shadow: 4px 1px 11px rgba(0, 0, 0, 0.13);
  max-width: 100%;
}

.custom-dropdown > select {
  background: transparent;
  box-sizing: border-box;
  font-weight: 600;
  color: #292929;
  font-size: inherit;
  padding: 30px 35px 12px;
  width: 350px;
  max-width: 100%;
  border: 0;
  margin: 0;
  text-indent: 0.01px;
  text-overflow: '';
  -webkit-appearance: button;
  /* hide default arrow in chrome OSX */
}

.custom-dropdown::before, .custom-dropdown::after {
  content: '';
  position: absolute;
  pointer-events: none;
}

.custom-dropdown::after {
  /*  Custom dropdown arrow */
  content: '';
  width: 18px;
  height: 11px;
  line-height: 1;
  right: 30px;
  top: 50%;
  margin-top: -5.5px;
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2218%22%20height%3D%2211%22%20viewBox%3D%220%200%2018%2011%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M17%201L9%209L1%200.999999%22%20stroke%3D%22%232D72F7%22%20stroke-width%3D%222%22%2F%3E%3C%2Fsvg%3E");
}

.custom-dropdown select[disabled] {
  color: rgba(0, 0, 0, 0.3);
}

.custom-dropdown select[disabled]::after {
  color: rgba(0, 0, 0, 0.1);
}

.custom-dropdown::after {
  color: rgba(0, 0, 0, 0.4);
}

.custom-dropdown-label {
  font-size: 14px;
  position: absolute;
  z-index: 1;
  left: 35px;
  font-weight: normal;
  top: 12px;
  pointer-events: none;
}

.custom-dropdown-redirect .custom-dropdown-label {
  top: 50%;
  font-size: 16px;
  font-weight: 600;
  margin-top: -13px;
}

.custom-toggle {
  /* support Windows High Contrast Mode. Credit: Adrian Roselli https://twitter.com/aardrian/status/1021372139990134785 */
}

.custom-toggle__label {
  cursor: pointer;
}

.custom-toggle__radio {
  display: inline-block;
  width: 50%;
  height: 100%;
  opacity: 0;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.custom-toggle__radio:checked {
  width: 0;
  position: absolute;
}

.custom-toggle__radio + .custom-toggle__radio:checked ~ .custom-toggle__switcher {
  right: 0;
  left: calc(50% + 3px);
}

.custom-toggle__radio:not(:checked) {
  width: 100%;
  z-index: 3;
}

.custom-toggle__wrapper {
  font-size: var(--baseSize);
  display: inline-block;
  vertical-align: middle;
  width: 5em;
  height: 2.5em;
  border-radius: 2.5em;
  position: relative;
  margin: 0 5px;
}

.custom-toggle__switcher {
  display: block;
  position: absolute;
  top: 3px;
  left: 3px;
  right: 100%;
  width: calc(50% - 6px);
  height: calc(100% - 6px);
  border-radius: 50%;
  transition: all 0.15s ease-in-out;
  z-index: 2;
  background-color: #575757;
}

.custom-toggle__background {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  border-radius: 2.5em;
  transition: all 0.2s ease-out;
  background-color: #fff;
}

@media screen and (-ms-high-contrast: active) {
  .custom-toggle__switcher {
    background-color: windowText;
  }
}

.contact-preferences-container {
  display: flex;
  align-items: baseline;
  padding: 25px 0 0;
}

.contact-preferences-container .contact-preferences-label {
  font-weight: 600;
}

.contact-preferences-container .checkbox-list {
  margin-left: 30px;
}

.contact-preferences-container .checkbox-list.contacts li {
  margin-right: 0;
}

.contact-preferences-container .checkbox-list .custom-checkbox label::before {
  height: 18px;
  width: 18px;
}

.contact-preferences-container .checkbox-list .custom-checkbox label {
  padding-right: 30px;
}

.contact-preferences-container .checkbox-list .custom-checkbox input[type='checkbox'] + label::after {
  left: 4px;
  top: 6px;
}

.contact-preferences-container .checkbox-list .form input[type='checkbox'] {
  margin-right: 0;
}

.aspect-ratio-square {
  padding-bottom: 100%;
}

.aspect-ratio-2\/1 {
  padding-bottom: 50%;
}

.aspect-ratio-3\/2 {
  padding-bottom: 66.6667%;
}

.aspect-ratio-4\/3 {
  padding-bottom: 75%;
}

.aspect-ratio-16\/9 {
  padding-bottom: 56.25%;
}

.aspect-ratio-21\/9 {
  padding-bottom: 42.86%;
}

@media screen and (min-width: 568px) {
  .xs\:aspect-ratio-square {
    padding-bottom: 100%;
  }
  .xs\:aspect-ratio-2\/1 {
    padding-bottom: 50%;
  }
  .xs\:aspect-ratio-3\/2 {
    padding-bottom: 66.6667%;
  }
  .xs\:aspect-ratio-4\/3 {
    padding-bottom: 75%;
  }
  .xs\:aspect-ratio-16\/9 {
    padding-bottom: 56.25%;
  }
  .xs\:aspect-ratio-21\/9 {
    padding-bottom: 42.86%;
  }
}

@media screen and (min-width: 768px) {
  .sm\:aspect-ratio-square {
    padding-bottom: 100%;
  }
  .sm\:aspect-ratio-2\/1 {
    padding-bottom: 50%;
  }
  .sm\:aspect-ratio-3\/2 {
    padding-bottom: 66.6667%;
  }
  .sm\:aspect-ratio-4\/3 {
    padding-bottom: 75%;
  }
  .sm\:aspect-ratio-16\/9 {
    padding-bottom: 56.25%;
  }
  .sm\:aspect-ratio-21\/9 {
    padding-bottom: 42.86%;
  }
}

@media screen and (min-width: 1024px) {
  .md\:aspect-ratio-square {
    padding-bottom: 100%;
  }
  .md\:aspect-ratio-2\/1 {
    padding-bottom: 50%;
  }
  .md\:aspect-ratio-3\/2 {
    padding-bottom: 66.6667%;
  }
  .md\:aspect-ratio-4\/3 {
    padding-bottom: 75%;
  }
  .md\:aspect-ratio-16\/9 {
    padding-bottom: 56.25%;
  }
  .md\:aspect-ratio-21\/9 {
    padding-bottom: 42.86%;
  }
}

@media screen and (min-width: 1280px) {
  .lg\:aspect-ratio-square {
    padding-bottom: 100%;
  }
  .lg\:aspect-ratio-2\/1 {
    padding-bottom: 50%;
  }
  .lg\:aspect-ratio-3\/2 {
    padding-bottom: 66.6667%;
  }
  .lg\:aspect-ratio-4\/3 {
    padding-bottom: 75%;
  }
  .lg\:aspect-ratio-16\/9 {
    padding-bottom: 56.25%;
  }
  .lg\:aspect-ratio-21\/9 {
    padding-bottom: 42.86%;
  }
}

@media screen and (min-width: 1360px) {
  .xl\:aspect-ratio-square {
    padding-bottom: 100%;
  }
  .xl\:aspect-ratio-2\/1 {
    padding-bottom: 50%;
  }
  .xl\:aspect-ratio-3\/2 {
    padding-bottom: 66.6667%;
  }
  .xl\:aspect-ratio-4\/3 {
    padding-bottom: 75%;
  }
  .xl\:aspect-ratio-16\/9 {
    padding-bottom: 56.25%;
  }
  .xl\:aspect-ratio-21\/9 {
    padding-bottom: 42.86%;
  }
}

.bg-gradient-golden-hour {
  background-image: linear-gradient(10deg, #ff6633 0%, #ffcc00 100%);
  color: #292929;
}

.bg-gradient-blue-hour {
  background-image: linear-gradient(10deg, #333399 0%, #33ccff 100%);
  color: #fff;
}

.bg-gradient-aurora {
  background-image: linear-gradient(10deg, #33ccff 0%, #a542ef 100%);
  color: #fff;
}

.bg-gradient-plen-air {
  background-image: linear-gradient(10deg, #04a5ff 0%, #6cffa9 100%);
  color: #292929;
}

.bg-gradient-green-shades {
  background-image: linear-gradient(10deg, #16481a 0%, #a5e226 100%);
  color: #fff;
}

.bg-gradient-stone-white {
  background-image: linear-gradient(201.18deg, #fff 49%, #b3b3b3 141%);
  color: #292929;
}

.bg-orange {
  background-color: #FF8200;
  color: #292929;
}

.bg-blue {
  background-color: #2D72F7;
  color: #fff;
}

.bg-purple {
  background-color: #9933ff;
  color: #fff;
}

.bg-light-blue {
  background-color: #33CCff;
  color: #292929;
}

.bg-green {
  background-color: #a5e226;
  color: #292929;
}

.bg-red {
  background-color: #ED3F39;
  color: #fff;
}

.bg-white {
  background-color: #fff;
  color: #292929;
}

.bg-black {
  background-color: #292929;
  color: #fff;
}

.bg-smoke {
  background-color: #767676;
  color: #fff;
}

.bg-gray {
  background-color: #f5f5f5;
  color: #292929;
}

.bg-golden-hour {
  background-color: #ff8f1f;
  color: #292929;
}

.bg-blue-hour {
  background-color: #3370c2;
  color: #fff;
}

.bg-aurora {
  background-color: #6195f9;
  color: #fff;
}

.bg-plen-air {
  background-color: #2ec9dd;
  color: #292929;
}

.bg-green-shades {
  background-color: #4f861f;
  color: #fff;
}

.bg-stone-white {
  background-color: #e1e1e1;
  color: #292929;
}

.callout-box, .product-card {
  background: #fff;
  box-shadow: 0px 6px 34px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 50px;
}

.carat, .breadcrumbs li:after {
  border: 1px solid #e8825b;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.carat.left, .breadcrumbs li.left:after {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.carat.up, .breadcrumbs li.up:after {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.carat.down, .breadcrumbs li.down:after {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.breadcrumbs {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
  font-size: 14px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.breadcrumbs li {
  display: inline-flex;
  align-items: center;
  margin-right: 15px;
}

.breadcrumbs li:after {
  content: "";
}

.breadcrumbs li:last-child:after {
  display: none;
}

.breadcrumbs li a {
  margin-right: 15px;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .breadcrumbs li a {
    margin-right: 8px;
  }
}

.breadcrumbs li:last-child {
  margin-right: 0;
}

.breadcrumbs li:last-child a {
  margin-right: 0;
}

.breadcrumbs .no-link {
  color: #292929;
  pointer-events: none;
}

figure.quote {
  margin: 0;
}

figure.quote blockquote {
  margin: 0;
  font-size: 24px;
  font-weight: 300;
}

figure.quote cite {
  font-weight: bold;
}

ul.pagination {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 20px;
}

ul.pagination li {
  display: inline-block;
}

ul.pagination li a {
  font-weight: bold;
  text-align: center;
  padding: 20px 20px;
  line-height: 0;
  display: flex;
  align-items: center;
  color: #292929;
}

ul.pagination li.selected a {
  background: #FF8200;
  color: #fff;
  border-radius: 8px;
}

ul.pagination li .carat, ul.pagination li .breadcrumbs li:after, .breadcrumbs ul.pagination li li:after {
  border-color: #7988e9;
}

ul.carousel-nav {
  list-style: none;
  margin: 0;
  padding: 0;
  margin: 20px 0;
  text-align: center;
}

ul.carousel-nav li {
  display: inline-block;
}

ul.carousel-nav li a {
  display: inline-block;
  text-indent: -50000px;
  width: 15px;
  height: 15px;
  border: 1px solid #ccc;
  border-radius: 50%;
}

ul.carousel-nav li.active a {
  border: 0;
  background: #FF6B0B;
}

.mask-wrapper {
  mask-image: url(../images/curve-mask.png);
  mask-repeat: no-repeat;
  mask-position: 50% 100%;
  mask-size: cover;
}

.mask-wrapper article {
  mask-image: url(../images/curve-mask.png);
  mask-repeat: no-repeat;
  mask-position: 50% 0;
  mask-size: cover;
}

.image-placeholder {
  position: relative;
  width: 100%;
}

.image-placeholder > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (min-width: 568px) {
  .xs\:image-placeholder {
    position: relative;
    width: 100%;
  }
  .xs\:image-placeholder > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media screen and (min-width: 768px) {
  .sm\:image-placeholder {
    position: relative;
    width: 100%;
  }
  .sm\:image-placeholder > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media screen and (min-width: 1024px) {
  .md\:image-placeholder {
    position: relative;
    width: 100%;
  }
  .md\:image-placeholder > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media screen and (min-width: 1280px) {
  .lg\:image-placeholder {
    position: relative;
    width: 100%;
  }
  .lg\:image-placeholder > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media screen and (min-width: 1360px) {
  .xl\:image-placeholder {
    position: relative;
    width: 100%;
  }
  .xl\:image-placeholder > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

body.is-scrolling .site-header {
  border-bottom: 1px solid #bababa;
}

.site-header.desktop {
  position: fixed;
  top: 0;
  z-index: 100;
  background: #fff;
  height: 135px;
  font-size: 14px;
  padding: 15px 0;
  max-width: 1930px;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .site-header.desktop {
    display: none;
  }
}

.site-header.desktop .utility-nav {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  text-align: right;
  font-size: 10px;
  font-weight: bold;
}

.site-header.desktop .utility-nav > li {
  display: inline-block;
  padding-left: 35px;
}

.site-header.desktop .utility-nav > li > a {
  padding: 5px 0;
  color: #6C6C6C;
}

.site-header.desktop .utility-nav > li > a.selected {
  position: relative;
}

.site-header.desktop .utility-nav > li > a.selected:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background: linear-gradient(10deg, #ff6633 0%, #ffcc00 100%);
}

.site-header.desktop .logo {
  float: left;
  width: 200px;
  height: 56px;
  margin-top: 4px;
  background: url(../images/sunnova-logo.png) no-repeat;
  background-size: 100%;
  text-indent: -5000px;
}

.site-header.desktop .main-nav-wrapper {
  display: flex;
  justify-content: space-between;
}

.site-header.desktop .main-nav {
  text-align: right;
  margin-top: 6px;
}

.site-header.desktop .main-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  float: right;
}

.site-header.desktop .main-nav ul > li {
  display: inline-block;
  margin: 0 10px;
}

.site-header.desktop .main-nav ul > li:last-child {
  margin-right: 0;
}

.site-header.desktop .main-nav ul > li:first-child {
  margin-left: 0;
}

.site-header.desktop .main-nav ul > li > a {
  display: block;
  padding: 15px 10px;
  color: #292929;
  text-decoration: none;
}

.site-header.desktop .main-nav ul > li.get-quote a {
  background: linear-gradient(10deg, #ff6633 0%, #ffcc00 100%);
  color: #fff;
  padding: 10px 30px;
}

.site-header.desktop .main-nav ul > li.is-active:not(.get-quote), .site-header.desktop .main-nav ul > li:hover:not(.get-quote) {
  position: relative;
}

.site-header.desktop .main-nav ul > li.is-active:not(.get-quote):before, .site-header.desktop .main-nav ul > li:hover:not(.get-quote):before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background: linear-gradient(10deg, #ff6633 0%, #ffcc00 100%);
}

.site-header.desktop .main-nav ul > li.is-active:not(.get-quote) > a, .site-header.desktop .main-nav ul > li:hover:not(.get-quote) > a {
  color: #000;
}

.site-header.desktop .main-nav ul > li.is-active:not(.get-quote)::before, .site-header.desktop .main-nav ul > li:hover:not(.get-quote)::before {
  bottom: 10px;
}

.site-header.desktop .main-nav ul > li.has-sub-nav {
  position: relative;
  text-align: left;
  display: inline-flex;
  justify-content: center;
}

.site-header.desktop .main-nav ul > li.has-sub-nav > a {
  padding-right: 20px;
}

.site-header.desktop .main-nav ul > li.has-sub-nav::after {
  position: absolute;
  right: 5px;
  top: calc(50% - 2px);
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  pointer-events: none;
  border-color: rgba(45, 114, 247, 0);
  border-top-color: #292929;
  border-width: 6px;
  transition: .3s ease-in-out all;
  border-left-width: 4px;
  border-right-width: 4px;
}

.site-header.desktop .main-nav ul > li.has-sub-nav:hover > ul {
  display: block;
}

.site-header.desktop .main-nav ul > li.has-sub-nav > ul {
  display: none;
  position: absolute;
  background: #FFFFFF;
  box-shadow: 0px 15px 34px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.02);
  border-radius: 16px;
  padding: 10px 20px;
  min-width: 180px;
  top: 100%;
  left: 0;
}

.site-header.desktop .main-nav ul > li.has-sub-nav > ul li {
  margin: 0;
  display: block;
}

.site-header.desktop .main-nav ul > li.has-sub-nav > ul a {
  white-space: nowrap;
}

.site-header.mobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px 0;
  background: #fff;
  z-index: 100;
  overflow: auto;
}

@media only screen and (min-width: 768px) {
  .site-header.mobile {
    display: none;
  }
}

.site-header.mobile .logo {
  float: left;
  width: 144px;
  height: 40px;
  background: url(../images/sunnova-logo.png) no-repeat;
  background-size: 100%;
  text-indent: -5000px;
}

.site-header.mobile .mobile-menu {
  float: right;
  margin-top: 5px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
}

.site-header.mobile span.hamburger {
  display: inline-block;
  height: 19px;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  width: 24px;
  vertical-align: middle;
  margin-right: 10px;
}

.site-header.mobile span.hamburger span {
  background: linear-gradient(10deg, #ff6633 0%, #ffcc00 100%);
  height: 3px;
  left: 0;
  opacity: 1;
  position: absolute;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
  width: 100%;
}

.site-header.mobile span.hamburger span:nth-child(1) {
  top: 0;
}

.site-header.mobile span.hamburger span:nth-child(2) {
  top: 8px;
}

.site-header.mobile span.hamburger span:nth-child(3) {
  top: 8px;
}

.site-header.mobile span.hamburger span:nth-child(4) {
  top: 16px;
}

.site-header.mobile span.hamburger.open span:nth-child(1) {
  top: 8px;
  left: 50%;
  width: 0;
}

.site-header.mobile span.hamburger.open span:nth-child(2) {
  transform: rotate(45deg);
}

.site-header.mobile span.hamburger.open span:nth-child(3) {
  transform: rotate(-45deg);
}

.site-header.mobile span.hamburger.open span:nth-child(4) {
  top: 8px;
  left: 50%;
  width: 0;
}

.site-header.mobile nav {
  display: none;
  clear: both;
}

.site-header.mobile nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 20px;
}

.site-header.mobile nav ul li {
  position: relative;
}

.site-header.mobile nav ul li a {
  display: block;
  padding: 15px 0;
  color: #292929;
  font-weight: bold;
}

.site-header.mobile nav ul li.get-quote a {
  margin-top: 10px;
  background: linear-gradient(10deg, #ff6633 0%, #ffcc00 100%);
  color: #fff;
  font-size: 20px;
  padding: 10px 0 12px;
}

.site-header.mobile nav ul li > ul {
  background: #f3f3f3;
  padding: 5px 20px;
  margin: 0 -20px;
  transition: .3s ease-in-out height;
}

.site-header.mobile nav ul li.has-sub-nav > a {
  margin-right: 25px;
}

.site-header.mobile nav ul.utility-nav {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 20px;
  font-size: 14px;
}

.site-header.mobile nav ul.utility-nav li {
  padding: 5px 0;
}

.site-header.mobile nav ul.utility-nav li a {
  display: inline-block;
  padding: 5px 0;
  color: #292929;
  font-weight: bold;
}

.site-header.mobile nav ul.utility-nav li a.selected {
  position: relative;
}

.site-header.mobile nav ul.utility-nav li a.selected:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background: linear-gradient(10deg, #ff6633 0%, #ffcc00 100%);
}

.site-header.mobile [data-accordion-trigger] {
  position: absolute;
  width: 15px;
  height: 20px;
  top: 22px;
  right: -5px;
  cursor: pointer;
  outline: 0;
  transition: .3s ease-in-out all;
}

.site-header.mobile [data-accordion-trigger]::after {
  position: absolute;
  right: 0;
  top: 0;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  pointer-events: none;
  border-color: rgba(45, 114, 247, 0);
  border-top-color: #292929;
  border-width: 10px;
  margin-top: 6px;
  transition: .3s ease-in-out transform;
  transform-origin: 5px 3px;
  border-left-width: 6px;
  border-right-width: 6px;
}

.site-header.mobile [data-accordion-trigger][aria-expanded="true"]::after {
  transform: rotateZ(-180deg);
}

.site-header.mobile.open {
  bottom: 0;
}

.site-footer {
  padding: 0 0 50px 0;
  font-size: 16px;
}

@media only screen and (min-width: 1024px) {
  .site-footer .inner {
    width: calc((((100% - 220px) / 12) * 10) + 180px - .1px);
    margin-left: calc( (((100% - 220px) / 12) * 1) + 0px + 20px) !important;
  }
  .site-footer .inner:first-child {
    margin-left: calc( (((100% - 220px) / 12) * 1) + 0px + 20px) !important;
  }
}

.site-footer h4 {
  padding-bottom: 15px;
  border-bottom: 1px solid #292929;
}

@media only screen and (min-width: 768px) {
  .site-footer div.col-1 {
    width: calc((((100% - 180px) / 10) * 3) + 40px - .1px);
    box-sizing: border-box;
    float: left;
    margin-left: 20px !important;
  }
  .site-footer div.col-1:first-child {
    margin-left: 0 !important;
  }
}

@media only screen and (min-width: 768px) {
  .site-footer div.col-2 {
    width: calc((((100% - 180px) / 10) * 3) + 40px - .1px);
    box-sizing: border-box;
    float: left;
    margin-left: 20px !important;
  }
  .site-footer div.col-2:first-child {
    margin-left: 0 !important;
  }
}

.site-footer div.col-3:after {
  content: "";
  display: table;
  clear: both;
}

@media only screen and (min-width: 768px) {
  .site-footer div.col-3 {
    width: calc((((100% - 180px) / 10) * 4) + 60px - .1px);
    box-sizing: border-box;
    float: left;
    margin-left: 20px !important;
  }
  .site-footer div.col-3:first-child {
    margin-left: 0 !important;
  }
}

.site-footer div.col-3 ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: calc((((100% - 60px) / 4) * 2) + 20px - .1px);
  box-sizing: border-box;
  float: left;
  margin-left: 20px !important;
}

.site-footer div.col-3 ul:first-child {
  margin-left: 0 !important;
}

.site-footer .footnotes {
  font-size: 13px;
  margin-bottom: 40px;
  color: #8F8F8F;
}

.site-footer .footnotes p {
  font-size: 13px;
}

.site-footer .copyright {
  font-size: 16px;
}

.site-footer .copyright p {
  display: inline;
  margin-right: 15px;
}

@media only screen and (max-width: 767px) {
  .site-footer .copyright p {
    display: block;
  }
}

.site-footer .copyright ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline;
}

.site-footer .copyright ul li {
  display: inline-block;
  margin-right: 15px;
}

@media only screen and (max-width: 767px) {
  .site-footer .copyright ul li {
    display: block;
  }
}

/* This file is where the styling for modules will go. This employes the concepts of OOCSS, creating common base classes (using <article> as the base), and extending as necessary */
/*

<article class="module-name">
    <div class="wrapper">
        <header>
            <h2>Module Name</h2>
        </header>
        <!-- Content here -->
    </div>
</article>

*/
article {
  margin: 100px 0;
}

@media only screen and (max-width: 767px) {
  article {
    margin: 60px 0;
  }
}

.layout > article:first-of-type {
  margin-top: 0;
}

@media only screen and (max-width: 767px) {
  .mainContent > *, .extraContent > *, .sidebar > * {
    margin: 20px 0 50px 0;
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  article.about-sunnova {
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 768px) {
  article.about-sunnova .content {
    width: calc((((100% - 220px) / 12) * 6) + 100px - .1px);
    box-sizing: border-box;
    float: left;
    margin-left: 20px !important;
  }
  article.about-sunnova .content:first-child {
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 767px) {
  article.about-sunnova .content {
    margin-bottom: 40px;
  }
}

article.about-sunnova .content h1, article.about-sunnova .content h2, article.about-sunnova .content h3, article.about-sunnova .content h4, article.about-sunnova .content h5, article.about-sunnova .content h6 {
  display: inline-block;
  background: linear-gradient(45deg, #33ccff 0%, #a542ef 100%);
  color: #6195f9;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 0;
  font-size: 48px;
}

@media only screen and (max-width: 767px) {
  article.about-sunnova .content h1, article.about-sunnova .content h2, article.about-sunnova .content h3, article.about-sunnova .content h4, article.about-sunnova .content h5, article.about-sunnova .content h6 {
    font-size: 36px;
  }
}

article.about-sunnova .content .button {
  color: #fff;
}

@media only screen and (min-width: 1024px) {
  article.about-sunnova .logos {
    width: calc((((100% - 220px) / 12) * 6) + 100px - .1px);
    box-sizing: border-box;
    float: left;
    margin-left: 20px !important;
  }
  article.about-sunnova .logos:first-child {
    margin-left: 0 !important;
  }
}

article.about-sunnova .logos ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  margin: -25px 0;
}

article.about-sunnova .logos ul li {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 0 25px;
  text-align: center;
  margin: 0 0 25px 0;
}

@media only screen and (max-width: 767px) {
  article.about-sunnova .logos ul li {
    margin-top: 25px;
  }
}

article.about-sunnova .logos ul li img {
  margin: 0 auto;
}

@media only screen and (min-width: 1024px) {
  article.about-sunnova .logos ul li img {
    max-width: 200px;
  }
}

article.breadcrumbs-wrapper {
  margin: -50px 0;
}

@media only screen and (max-width: 767px) {
  article.breadcrumbs-wrapper {
    margin: -25px 0;
  }
}

article.breadcrumbs-wrapper a {
  color: #FF6B0B;
  transition: .2s linear opacity;
}

article.breadcrumbs-wrapper a:hover, article.breadcrumbs-wrapper a:focus {
  opacity: .7;
}

article.callouts {
  background: transparent;
  position: relative;
  z-index: 2;
}

@media only screen and (min-width: 768px) {
  article.callouts {
    margin: -300px 0 200px 0;
  }
}

@media only screen and (max-width: 767px) {
  article.callouts {
    margin-top: 50px;
  }
}

@media only screen and (min-width: 768px) {
  article.callouts div.row {
    display: flex;
  }
}

@media only screen and (min-width: 768px) {
  article.callouts div.callout-box, article.callouts div.product-card {
    width: calc((((100% - 220px) / 12) * 5) + 80px - .1px);
    box-sizing: border-box;
    float: left;
    margin-left: 20px !important;
    display: flex;
    flex-direction: column;
    margin-bottom: -150px;
  }
  article.callouts div.callout-box:first-child, article.callouts div.product-card:first-child {
    margin-left: 0 !important;
  }
  article.callouts div.callout-box:first-child, article.callouts div.product-card:first-child {
    margin-left: calc( (((100% - 220px) / 12) * 1) + 0px + 20px) !important;
  }
  article.callouts div.callout-box:first-child:first-child, article.callouts div.product-card:first-child:first-child {
    margin-left: calc( (((100% - 220px) / 12) * 1) + 0px + 20px) !important;
  }
}

@media only screen and (max-width: 767px) {
  article.callouts div.callout-box, article.callouts div.product-card {
    margin-bottom: 20px;
  }
}

article.callouts div.callout-box h3, article.callouts div.product-card h3 {
  font-size: 20px;
}

article.callouts div.callout-box ul, article.callouts div.product-card ul {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: auto;
  font-size: 12px;
}

article.callouts div.callout-box ul li, article.callouts div.product-card ul li {
  border-bottom: 1px solid #C4C4C4;
}

article.callouts div.callout-box ul li:last-child, article.callouts div.product-card ul li:last-child {
  border-bottom: 0;
}

article.callouts div.callout-box ul li a, article.callouts div.product-card ul li a {
  display: inline-block;
  background: linear-gradient(45deg, #ff6633 0%, #ffcc00 100%);
  color: #ff8f1f;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: block;
  padding: 10px 0;
  font-size: 24px;
}

article.callouts div.callout-box ul li a:after, article.callouts div.product-card ul li a:after {
  content: "";
  float: right;
  margin-top: 16px;
  border: 1px solid #e8825b;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.carousel-content {
  margin-bottom: 20px;
}

.carousel .tns-outer {
  position: relative;
}

.carousel .tns-controls [data-controls] {
  position: absolute;
  width: calc(25% - 30px);
  top: 0;
  bottom: 0;
  z-index: 1;
  background: transparent;
  box-shadow: none;
  outline: 0;
}

.carousel .tns-controls [data-controls="next"] {
  right: 0;
}

.carousel .tns-controls [data-controls][disabled] {
  display: none;
}

.carousel .tns-nav {
  text-align: center;
  margin-top: 20px;
}

.carousel [data-controls], .carousel [data-nav] {
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  text-transform: none;
  color: inherit;
  font-size: inherit;
  font-weight: normal;
  white-space: initial;
  border-radius: 0;
  text-align: left;
}

.carousel [data-nav] {
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 1px solid #ccc;
  border-radius: 50%;
  margin: 2px;
}

.carousel [data-nav].tns-nav-active {
  border-color: #FF6B0B;
  background: #FF6B0B;
}

.carousel .tns-slider {
  padding: 0;
}

.carousel .tns-slider .tns-item {
  transition: .3s ease-in-out opacity;
  opacity: .35;
}

.carousel .tns-slider .tns-item.is-active {
  opacity: 1;
}

.carousel .tns-slider .tns-item img {
  width: 100% !important;
  height: auto !important;
  border-radius: 10px;
}

.charity-listing-header {
  margin-bottom: 50px;
  text-align: center;
}

.charity-listing img {
  margin-bottom: 45px;
}

.charity-listing-grid {
  margin-bottom: -45px;
}

.charity-listing-grid li {
  display: flex;
  align-items: center;
  padding: 0 10px;
  min-width: 0;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
  max-width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 50%;
  max-width: 50%;
}

.charity-listing-grid li:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .charity-listing-grid li {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }
}

@media screen and (min-width: 1024px) {
  .charity-listing-grid li {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 16.66666%;
    max-width: 16.66666%;
  }
}

.comparison {
  max-width: 940px;
  margin: 0 auto;
  font: 16px/1.4 "Helvetica Neue",Helvetica,Arial,sans-serif;
  text-align: center;
}

.comparison table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  border-bottom: 1px solid #CCC;
  border-left: 1px solid #CCC;
  border-top: 1px solid #CCC;
}

.comparison td, .comparison th {
  border-right: 1px solid #CCC;
  empty-cells: show;
  padding: 10px;
}

.compare-heading {
  font-size: 16px;
  border-bottom: 0 !important;
  padding: 10px !important;
}

.comparison .compare-row {
  background: #F5F5F5;
}

.comparison .tickblue {
  color: #0078C1;
}

.comparison .tickgreen {
  color: #009E2C;
  font-size: 20px;
  font-weight: bold;
}

.comparison th {
  font-weight: normal;
  padding: 0;
  border-bottom: 1px solid #CCC;
  text-align: center;
}

.comparison tr td:first-child {
  text-align: left;
}

.comparison .qbse, .comparison .qbo, .comparison .tl {
  color: #FFF;
  padding: 10px;
  font-size: 13px;
  border-right: 1px solid #CCC;
  border-bottom: 0;
}

.comparison .tl2 {
  border-right: 0;
}

.comparison .qbse {
  background: #636363;
  border-top-left-radius: 3px;
  border-left: 0px;
  text-align: center;
}

.comparison .platinum {
  background: #F36F21;
  border-top-left-radius: 3px;
  border-left: 0px;
  color: #fff;
}

.comparison .qbo {
  background: #009E2C;
  border-top-right-radius: 3px;
  border-right: 0px;
}

.comparison .price-info {
  padding: 5px 15px 15px 15px;
}

.comparison .price-was {
  color: #999;
  text-decoration: line-through;
}

.comparison .price-now, .comparison .price-now span {
  color: #ff5406;
}

.comparison .price-now span {
  font-size: 32px;
}

.comparison .price-small {
  font-size: 18px !important;
  position: relative;
  top: -11px;
  left: 2px;
}

.comparison .price-buy {
  background: #ff5406;
  padding: 10px 20px;
  font-size: 12px;
  display: inline-block;
  color: #FFF;
  text-decoration: none;
  border-radius: 3px;
  text-transform: uppercase;
  margin: 5px 0 10px 0;
}

.comparison .price-try {
  font-size: 12px;
}

.comparison .price-try a {
  color: #202020;
}

@media (max-width: 767px) {
  .comparison tbody tr:nth-child(odd) {
    display: table-row;
    background: #F7F7F7;
  }
  .comparison .row {
    background: #FFF;
  }
  .comparison td, .comparison th {
    border: 1px solid #CCC;
  }
  .price-info {
    border-top: 0 !important;
  }
}

@media (max-width: 639px) {
  .comparison .price-buy {
    padding: 5px 10px;
  }
  .comparison td, .comparison th {
    padding: 10px 5px;
    font-size: 11px;
  }
  .comparison .hide-mobile {
    display: none;
  }
  .comparison .price-now span {
    font-size: 16px;
  }
  .comparison .price-small {
    font-size: 16px !important;
    top: 0;
    left: 0;
  }
  .comparison .qbse, .comparison .qbo {
    font-size: 12px;
    padding: 10px 5px;
  }
  .comparison .price-buy {
    margin-top: 10px;
  }
  .compare-heading {
    font-size: 13px;
  }
}

.filter-bar-list-container {
  background: linear-gradient(230.21deg, #33CCFF 7.99%, #333399 100%);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  color: #fff;
  padding: 30px;
  text-align: left;
  position: relative;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
}

@media only screen and (max-width: 767px) {
  .filter-bar-list-container {
    display: block;
    padding: 30px;
  }
}

.filter-bar-heading {
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .filter-bar-heading {
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 768px) {
  .filter-bar .col:not(.tns-item) {
    width: 25%;
    float: left;
    padding-right: 20px;
  }
  .filter-bar .col:not(.tns-item):nth-child(n+5) {
    display: none;
  }
}

.filter-bar-list {
  list-style: none;
  margin: 0;
  padding: 0;
  transform: translate3d(0%, 0px, 0px);
  list-style: none;
  margin: 25px 0 0;
  padding: 0;
  font-size: 20px;
}

.filter-bar-list a, .filter-bar-list a:focus, .filter-bar-list a:hover, .filter-bar-list a:active {
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .filter-bar-list {
    margin: 0;
  }
}

.filter-bar-list li {
  position: relative;
  counter-increment: reading-list-counter;
  padding-left: 25px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 768px) {
  .filter-bar-list li {
    display: inline-block;
  }
}

.filter-bar-list li:last-child {
  margin-bottom: 0;
}

.filter-bar-list li.is-selected {
  font-weight: 600;
}

.filter-bar-list li::before {
  position: absolute;
  left: 0;
}

@media only screen and (max-width: 767px) {
  .filter-bar-list {
    padding: 5px 0;
    margin: 0;
  }
}

.filter-bar.is-sidebar {
  margin-top: 0;
}

.filter-bar.is-sidebar .filter-bar-list-container {
  padding: 45px 30px;
  display: block;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.16);
}

.filter-bar.is-sidebar .filter-bar-list-container li {
  margin-left: 0;
  margin-top: 25px;
  display: block;
}

.filter-bar.is-sidebar .filter-bar-heading {
  font-size: 28px;
}

.filter-bar .custom-dropdown {
  margin-bottom: 0;
}

.blog-listing-heading {
  text-transform: capitalize;
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .blog-listing-heading {
    text-align: center;
  }
}

.blog-listing-list {
  transition: .3s ease-in-out height;
  position: relative;
}

@media screen and (min-width: 1024px) {
  .blog-listing-list {
    margin-top: -40px;
  }
}

.blog-listing li {
  position: relative;
  transition: .3s ease-in-out all;
}

@media screen and (min-width: 1024px) {
  .blog-listing li {
    display: flex;
  }
}

.blog-listing li.is-hiding {
  opacity: 0;
}

.blog-listing li.is-hiding .info-card {
  transform: scale(0);
}

.blog-listing li.is-hidden {
  flex-basis: 0;
  display: none;
}

.blog-listing .info-card {
  transition: .3s ease-in-out all;
}

@media screen and (min-width: 1024px) {
  .blog-listing .info-card {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

@media screen and (min-width: 1024px) {
  .blog-listing .info-card-content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

@media screen and (min-width: 1024px) {
  .blog-listing .info-card-meta {
    margin-top: auto;
  }
}

.blog-listing .load-more {
  text-align: center;
}

.blog-listing .load-more button {
  margin-top: 40px;
}

.blog-listing--v2 .blog-listing-heading {
  letter-spacing: -2px;
  color: #292929;
  margin-bottom: 50px;
}

@media only screen and (min-width: 1024px) {
  .fullwidth-callout {
    margin: 100px 0;
  }
}

.fullwidth-callout-container {
  position: relative;
  border-radius: 40px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.fullwidth-callout-heading {
  margin-top: 0;
  margin-bottom: 2px;
}

.fullwidth-callout-content {
  padding: 60px 53% 60px 150px;
  text-align: left;
  color: #fff;
  position: relative;
  display: flex;
  align-items: center;
  min-height: 440px;
  width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .fullwidth-callout-content {
    padding: 60px 80px;
  }
}

@media only screen and (max-width: 767px) {
  .fullwidth-callout-content {
    padding: 60px 40px;
  }
}

@media only screen and (max-width: 1023px) {
  .fullwidth-callout-content {
    min-height: 0;
  }
}

.fullwidth-callout-content .button {
  margin-top: 10px;
}

.fullwidth-callout-content-wrapper > *:last-child {
  margin-bottom: 0;
}

.fullwidth-callout-mask {
  background: no-repeat bottom right url(../images/services-mask.svg);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.fullwidth-callout-image {
  position: absolute;
  right: 0;
  bottom: 0;
  line-height: 0;
}

@media only screen and (max-width: 1023px) {
  .fullwidth-callout-image {
    display: none;
  }
}

.fullwidth-callout.is-image-centered .fullwidth-callout-image {
  bottom: auto;
}

@media only screen and (min-width: 1024px) {
  .fullwidth-callout.is-image-left .fullwidth-callout-content {
    padding: 60px 85px 60px 59%;
  }
}

.fullwidth-callout.is-image-left .fullwidth-callout-image {
  left: 0;
  right: auto;
}

article.get-quote {
  padding: 100px 0;
  background: linear-gradient(10deg, #ff6633 0%, #ffcc00 100%);
  color: #fff;
  position: relative;
}

article.get-quote .get-quote-success, article.get-quote .error-message {
  display: none;
}

article.get-quote .get-quote-container, article.get-quote .get-quote-success {
  background: rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 60px;
  position: relative;
}

article.get-quote .get-quote-container p, article.get-quote .get-quote-success p {
  font-size: 24px;
}

article.get-quote .get-quote-container h3, article.get-quote .get-quote-success h3 {
  text-align: left;
}

@media only screen and (max-width: 767px) {
  article.get-quote .get-quote-container, article.get-quote .get-quote-success {
    padding: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  article.get-quote .get-quote-container, article.get-quote .get-quote-success {
    padding: 40px;
  }
}

article.get-quote .get-quote-container.two-column .checkbox-container, article.get-quote .get-quote-success.two-column .checkbox-container {
  position: relative;
}

article.get-quote .get-quote-container.two-column .checkbox-container .error, article.get-quote .get-quote-success.two-column .checkbox-container .error {
  left: calc(50% - 227px);
  position: absolute;
  z-index: 1;
}

article.get-quote .get-quote-container.two-column .checkbox-container .error:before, article.get-quote .get-quote-success.two-column .checkbox-container .error:before {
  left: calc(50% - 46px);
  top: calc(50% - 48px);
}

@media only screen and (max-width: 767px) {
  article.get-quote .get-quote-container.two-column .checkbox-container .error, article.get-quote .get-quote-success.two-column .checkbox-container .error {
    left: 0px;
  }
}

article.get-quote .get-quote-container .checkbox-container .error, article.get-quote .get-quote-success .checkbox-container .error {
  position: relative;
}

article.get-quote .get-quote-container .checkbox-container .error:before, article.get-quote .get-quote-success .checkbox-container .error:before {
  left: 40px;
  top: -27px;
}

@media only screen and (min-width: 768px) {
  article.get-quote .get-quote-container.one-column form, article.get-quote .get-quote-success.one-column form {
    width: calc((((100% - 220px) / 12) * 8) + 140px - .1px);
    box-sizing: border-box;
    float: left;
    margin-left: 20px !important;
    margin-left: calc( (((100% - 220px) / 12) * 2) + 20px + 20px) !important;
  }
  article.get-quote .get-quote-container.one-column form:first-child, article.get-quote .get-quote-success.one-column form:first-child {
    margin-left: 0 !important;
  }
  article.get-quote .get-quote-container.one-column form:first-child, article.get-quote .get-quote-success.one-column form:first-child {
    margin-left: calc( (((100% - 220px) / 12) * 2) + 20px + 20px) !important;
  }
}

article.get-quote .get-quote-container.two-column h2, article.get-quote .get-quote-success.two-column h2 {
  margin-bottom: 30px;
  padding-bottom: 60px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
}

article.get-quote .get-quote-container.two-column .first-column, article.get-quote .get-quote-success.two-column .first-column {
  text-align: left;
  padding: 0 30px 0 10px;
}

@media only screen and (min-width: 768px) {
  article.get-quote .get-quote-container.two-column .first-column, article.get-quote .get-quote-success.two-column .first-column {
    border-right: 1px solid rgba(255, 255, 255, 0.5);
  }
}

article.get-quote .get-quote-container.two-column .second-column, article.get-quote .get-quote-success.two-column .second-column {
  padding: 0 10px 0 30px;
}

article.get-quote h2 {
  text-align: center;
}

article.get-quote .get-quote-about {
  margin: 30px 0;
}

article.get-quote p.get-quote-required {
  font-size: 14px;
}

article.get-quote form p.intro {
  margin-bottom: 50px;
}

article.get-quote form .item.city {
  flex-grow: 1.5;
}

article.get-quote form select {
  border-radius: 16px;
}

article.get-quote form .buttons {
  margin-top: 30px;
}

article.get-quote form .disclaimer-checkbox {
  font-size: 14px;
  text-align: left;
}

article.get-quote form .disclaimer-checkbox a {
  color: #fff;
  text-decoration: underline;
}

article.get-quote form .disclaimer-checkbox input {
  margin-right: 10px;
}

article.get-quote form p.legal {
  font-size: 14px;
  text-align: center;
}

article.get-quote form p.legal a {
  color: #fff;
  text-decoration: underline;
}

@media only screen and (max-width: 767px) {
  article.get-quote .checkbox-list {
    text-align: left;
  }
}

.center-quote .get-quote .get-quote-container .checkbox-container .error {
  left: calc(50% - 227px);
  z-index: 1;
  position: absolute;
}

@media only screen and (max-width: 767px) {
  .center-quote .get-quote .get-quote-container .checkbox-container .error {
    left: 20px;
  }
}

.center-quote .get-quote .get-quote-container .checkbox-container .error:before {
  left: calc(50% - 46px);
  top: calc(50% - 48px);
}

div.overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.7);
  overflow-x: hidden;
  transition: 0.5s;
}

div.overlay .overlay-content {
  background-color: white;
  position: absolute;
  height: 180px;
  width: 360px;
  left: calc(50% - 180px);
  top: calc(50% - 90px);
  margin: 0px auto;
  border-radius: 5px;
  padding: 35px;
}

div.overlay .overlay-content .overlay-spinner {
  width: 40px;
  height: 40px;
  margin: 0 auto;
  border: 5px solid;
  border-radius: 50%;
  border-color: transparent black black;
  animation: loadingSpinner .7s infinite linear;
  -o-animation: loadingSpinner .7s infinite linear;
  -ms-animation: loadingSpinner .7s infinite linear;
  -webkit-animation: loadingSpinner .7s infinite linear;
  -moz-animation: loadingSpinner .7s infinite linear;
}

div.overlay .overlay-content .overlay-text {
  text-align: center;
}

div.overlay .overlay-content .overlay-text h4 {
  font-size: 24px;
  margin: 20px 0 0 0;
}

div.overlay .overlay-content .overlay-text p {
  font-size: 20px;
  margin: 0;
}

@keyframes loadingSpinner {
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes loadingSpinner {
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-ms-keyframes loadingSpinner {
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loadingSpinner {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes loadingSpinner {
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.hero {
  position: relative;
}

.hero .mask-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.hero .mask-wrapper.top-curve {
  mask-image: url(../images/curve-mask.png);
  mask-repeat: no-repeat;
  mask-position: 50% 100%;
  mask-size: cover;
}

.hero .mask-wrapper.top-curve .bg {
  mask-image: url(../images/curve-mask.png);
  mask-repeat: no-repeat;
  mask-position: 50% 0;
  mask-size: cover;
}

.hero .bg {
  position: relative;
  background-repeat: no-repeat;
  background-position: 0 100%;
  background-size: cover;
  height: 465px;
}

@media only screen and (max-width: 767px) {
  .hero .bg {
    height: 240px;
  }
}

.hero.center-bg .bg {
  background-position: 0 50%;
}

.hero .video-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero .title {
  position: relative;
  display: flex;
  align-items: center;
  color: #fff;
  min-height: 465px;
}

@media only screen and (max-width: 767px) {
  .hero .title {
    min-height: 240px;
  }
}

.hero:not(.hero--columns) h1:not(.clear-title) {
  color: #fff;
  text-align: center;
}

.hero:not(.hero--columns) h1:not(.clear-title) > span {
  padding: 5px 0;
  background: #FF8200;
}

.hero:not(.hero--columns) h1:not(.clear-title) > span > span {
  display: inline;
  padding: 5px 20px;
  line-height: 1.35;
  color: #fff;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}

@media only screen and (min-width: 768px) {
  .hero:not(.hero--columns) h1:not(.clear-title) > span > span {
    background: linear-gradient(to right top, #F15523 20%, #FFBE01 80%);
    background-attachment: fixed;
  }
}

@media only screen and (max-width: 767px) {
  .hero:not(.hero--columns) h1:not(.clear-title) > span > span {
    line-height: 1.55;
  }
}

.hero.is-small .title {
  min-height: 365px;
}

@media only screen and (max-width: 767px) {
  .hero.is-small .title {
    min-height: 165px;
  }
}

.hero.top .title {
  align-items: flex-start;
  margin-top: 20px;
}

.hero.middle .title {
  align-items: center;
}

.hero.bottom .title {
  align-items: flex-end;
}

@media only screen and (max-width: 767px) {
  .hero.bottom .title {
    top: 10px;
  }
}

.hero.left .title {
  justify-content: flex-start;
}

.hero.center .title {
  justify-content: center;
}

.hero.right .title {
  justify-content: flex-end;
}

.hero--columns .bg {
  height: 100%;
}

.hero--columns .title {
  padding: 40px 0;
  box-sizing: content-box;
}

@media screen and (min-width: 1024px) {
  .hero--columns .title {
    padding: 40px 0 60px 0;
  }
}

@media screen and (min-width: 1024px) {
  .hero--columns .title .flex-grid {
    align-items: center;
    width: 100%;
  }
}

.hero--columns .hero-heading > *:first-child, .hero--columns .hero-content > *:first-child {
  margin-top: 0;
}

.hero--columns .hero-heading > *:last-child, .hero--columns .hero-content > *:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 1024px) {
  .hero--columns.right .flex-grid {
    text-align: right;
    flex-direction: row-reverse;
  }
  .hero--columns.right .faq-autocomplete-form {
    margin-left: auto;
  }
}

@media screen and (min-width: 1024px) {
  .hero--columns.center .flex-grid {
    text-align: center;
    justify-content: center;
  }
  .hero--columns.center .faq-autocomplete-form {
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: 768px) {
  .hero--columns.bottom {
    padding-bottom: 57px;
  }
}

.hero-content {
  margin-top: 15px;
}

.hero .faq-autocomplete-form {
  margin-top: 40px;
  max-width: 515px;
}

article.homepage-hero {
  position: relative;
  margin-top: 110px;
}

article.homepage-hero .mask-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

article.homepage-hero .mask-wrapper.top-curve {
  mask-image: url(../images/curve-mask.png);
  mask-repeat: no-repeat;
  mask-position: 50% 100%;
  mask-size: cover;
}

article.homepage-hero .mask-wrapper.top-curve .bg {
  mask-image: url(../images/curve-mask.png);
  mask-repeat: no-repeat;
  mask-position: 50% 0;
  mask-size: cover;
}

article.homepage-hero .bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

article.homepage-hero .wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  flex-direction: column;
  padding: 150px 0px;
}

article.homepage-hero .wrapper.has-video-bg {
  visibility: hidden;
}

article.homepage-hero .mask-wrapper ~ .wrapper {
  padding: 125px 0px;
}

article.homepage-hero div.header {
  display: flex;
  align-items: center;
  justify-content: center;
}

article.homepage-hero div.cta .button {
  margin: 5px 30px;
}

@media only screen and (max-width: 767px) {
  article.homepage-hero div.cta {
    margin-top: 72px;
  }
}

article.homepage-hero h1 {
  color: #fff;
  text-align: center;
}

article.homepage-hero h1 > span {
  padding: 5px 0;
  background: #FF8200;
}

article.homepage-hero h1 > span > span {
  display: inline;
  padding: 5px 20px;
  line-height: 1.35;
  color: #fff;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}

@media only screen and (min-width: 768px) {
  article.homepage-hero h1 > span > span {
    background: linear-gradient(to right top, #F15523 20%, #FFBE01 80%);
    background-attachment: fixed;
  }
}

@media only screen and (max-width: 767px) {
  article.homepage-hero h1 > span > span {
    line-height: 1.55;
  }
}

article.homepage-hero .video-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

article.homepage-hero .mobile, article.homepage-hero .tablet, article.homepage-hero .desktop {
  display: none;
}

@media only screen and (max-width: 767px) {
  article.homepage-hero .mobile {
    display: initial;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  article.homepage-hero .tablet {
    display: initial;
  }
}

@media only screen and (min-width: 1024px) {
  article.homepage-hero .desktop {
    display: initial;
  }
}

@media only screen and (min-width: 768px) {
  article.homepage-hero.top .header {
    align-items: flex-start;
  }
  article.homepage-hero.middle .header {
    align-items: center;
  }
  article.homepage-hero.bottom .header {
    align-items: flex-end;
  }
  article.homepage-hero.left .header {
    justify-content: flex-start;
  }
  article.homepage-hero.center .header {
    justify-content: center;
  }
  article.homepage-hero.right .header {
    justify-content: flex-end;
  }
}

article.homepage-hero.homepage {
  margin-top: 110px;
}

@media only screen and (max-width: 767px) {
  article.homepage-hero.homepage {
    margin-top: 0px;
  }
}

article.hr {
  margin: 0 0 50px 0;
  padding: 0;
}

@media only screen and (max-width: 767px) {
  article.hr {
    margin-bottom: 25px;
  }
}

@media only screen and (min-width: 1024px) {
  article.image {
    margin: 100px 0;
  }
}

.image {
  text-align: center;
}

.image-content {
  margin-bottom: 50px;
}

.image img {
  border-radius: 10px;
  overflow: hidden;
}

.image-collage-grid {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.image-collage-grid > [class*="is-"] {
  min-width: 0;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
}

.image-collage-grid > [class*="is-"]:last-child {
  margin-bottom: 0;
}

.image-collage-grid .is-full {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
  max-width: 100%;
}

.image-collage-grid .is-1\/2 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 50%;
  max-width: 50%;
}

.image-collage-grid .is-1\/3 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 33.3333%;
  max-width: 33.3333%;
}

.image-collage-grid .is-2\/3 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 66.6666%;
  max-width: 66.6666%;
}

.image-collage-grid .is-1\/4 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 25%;
  max-width: 25%;
}

.image-collage-grid .is-3\/4 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 75%;
  max-width: 75%;
}

.image-collage-grid .is-1\/6 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 16.66666%;
  max-width: 16.66666%;
}

.image-collage-grid .is-5\/6 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 83.3333%;
  max-width: 83.3333%;
}

.image-collage-grid .is-1\/5 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 20%;
  max-width: 20%;
}

.image-collage-grid .is-2\/5 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 40%;
  max-width: 40%;
}

.image-collage-grid .is-3\/5 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 60%;
  max-width: 60%;
}

.image-collage-grid .is-4\/5 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 80%;
  max-width: 80%;
}

.image-collage {
  overflow: hidden;
}

.image-collage-wrapper {
  margin: 0 -10px;
}

.image-collage img {
  border-radius: 10px;
  padding: 5px;
}

@media only screen and (max-width: 767px) {
  .image-collage img {
    border-radius: 5px;
    padding: 3px;
  }
}

.image-collage .aspect-ratio-3\/4 {
  padding-bottom: 133.333%;
}

.info-card {
  text-align: center;
}

.info-card-media {
  line-height: 0;
  overflow: hidden;
  margin-bottom: 10px;
}

.info-card-title {
  color: #2D72F7;
  margin-top: 0;
}

.info-card-meta {
  font-size: 14px;
  margin-bottom: 10px;
  margin: 0 -5px;
}

.info-card-meta > span {
  margin: 0 5px;
}

.info-card-description {
  padding: 0 25px;
  margin-top: 0;
}

.info-card-description > *:last-child {
  margin-bottom: 0;
}

.info-card-description.fade {
  position: relative;
  height: 130px;
  overflow: hidden;
}

.info-card-description.fade:after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70%;
  height: 1.5em;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white 50%);
}

.info-card-content > *:last-child {
  margin-bottom: 0;
}

.info-card-tags {
  list-style: none;
  margin: 0;
  padding: 0;
  margin: 0 -5px;
  text-transform: uppercase;
}

.info-card-tags li {
  display: inline-block;
  margin: 0 5px;
}

.info-card-tags a {
  text-decoration: underline;
  color: #2D72F7;
  font-size: 14px;
}

.info-card.is-opposite {
  flex-direction: row-reverse;
}

@media only screen and (min-width: 1024px) {
  .info-card.is-horizontal {
    text-align: left;
    display: flex;
    padding: 0;
  }
  .info-card.is-horizontal .info-card-media {
    margin-bottom: 0;
  }
  .info-card.is-horizontal .info-card-description {
    padding: 0;
  }
  .info-card.is-horizontal .info-card-header {
    flex: 0 0 50%;
  }
  .info-card.is-horizontal .info-card-content {
    padding: 10px;
  }
  .info-card.is-horizontal .info-card-header + .info-card-content {
    padding: 10px 20px;
  }
  .info-card.is-horizontal.is-padded .info-card-header + .info-card-content {
    padding: 10px 0 10px 40px;
  }
  .info-card.is-padded {
    padding: 10px;
  }
}

.info-card.has-shadow {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.25);
}

.info-card.is-rounded .info-card-media {
  border-radius: 10px;
}

.info-card--v2.is-horizontal .info-card-header + .info-card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.info-card--v2.is-horizontal .info-card-title {
  margin-bottom: 20px;
}

.info-card--v2.is-horizontal .info-card-content {
  background: linear-gradient(201.18deg, #FFFFFF 48.72%, #B3B3B3 141.8%);
}

@media screen and (min-width: 1024px) {
  .info-card--v2.is-horizontal .info-card-media {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .info-card--v2.is-horizontal .info-card-media img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media screen and (min-width: 1024px) {
  .info-card--v2.is-horizontal.is-small .info-card-header + .info-card-content {
    padding: 35px;
  }
}

.info-card--v2:not(.is-horizontal) {
  position: relative;
}

.info-card--v2:not(.is-horizontal):after {
  content: '';
  position: absolute;
  top: 20px;
  left: 40px;
  bottom: 0;
  right: 40px;
  background: #000000;
  opacity: 0.3;
  filter: blur(30px);
  z-index: 0;
}

.info-card--v2 .info-card-header + .info-card-content {
  text-align: left;
}

@media screen and (min-width: 1024px) {
  .info-card--v2 .info-card-header + .info-card-content {
    padding: 50px 55px;
  }
}

.info-card--v2 .info-card-description {
  padding: 0;
}

.info-card--v2 .info-card-content {
  background: linear-gradient(226.39deg, #FFFFFF 48.72%, #B3B3B3 141.8%);
  padding: 35px 30px;
}

.info-card--v2 .info-card-content > a:hover, .info-card--v2 .info-card-content > a:focus, .info-card--v2 .info-card-content > a:active {
  text-decoration: none;
}

.info-card--v2 .info-card-media {
  margin-bottom: 0;
  z-index: 1;
  background: #fff;
}

.info-card--v2 .info-card-media img {
  object-position: top;
  object-fit: cover;
}

.info-card--v2 .info-card-media, .info-card--v2 .info-card-content {
  position: relative;
  z-index: 1;
}

.info-card--v2 .info-card-header {
  position: relative;
}

.info-card--v2 .info-card-title {
  color: #292929;
  letter-spacing: -0.03em;
  line-height: 1.15;
  margin-bottom: 25px;
}

.info-card--v2 .info-card-tags {
  margin-bottom: 10px;
}

.info-card--v2 .info-card-tags a {
  text-decoration: none;
}

.info-card--v2 .info-card-meta {
  font-weight: normal;
}

.infographic {
  /*margin: 75px 0;
    @include breakpoint(small) {
      margin: 35px 0;
    }*/
}

.infographic-tabs {
  text-align: center;
  margin-top: -25px;
}

@media only screen and (max-width: 767px) {
  .infographic-tabs {
    margin-bottom: 40px;
  }
}

.infographic-tabs [role="tablist"] {
  position: relative;
  overflow: visible;
  z-index: 1;
}

.infographic-tabs [role="tab"] {
  position: relative;
  margin: 0;
  padding: 25px;
  background: transparent;
  box-shadow: none;
  border-radius: 0;
  font-weight: bold;
  text-transform: none;
  color: inherit;
  transition: .2s ease-in-out all;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .infographic-tabs [role="tab"] {
    padding: 25px 10px;
  }
}

.infographic-tabs [role="tab"]:last-child::after {
  display: none;
}

.infographic-tabs [role="tab"]::after {
  position: absolute;
  bottom: 25px;
  top: 25px;
  width: 1px;
  right: -2px;
  border-right: 2px solid #C4C4C4;
  content: '';
}

.infographic-tabs [role="tab"][aria-selected="true"] {
  display: inline-block;
  background: linear-gradient(45deg, #ff6633 0%, #ffcc00 100%);
  color: #ff8f1f;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.infographic-tabs [role="tab"]:hover,
.infographic-tabs [role="tab"]:focus,
.infographic-tabs [role="tab"]:active {
  outline: 0;
  border-radius: 0;
}

.infographic-tabs [role="tabpanel"] {
  padding: .5em .5em .7em;
}

.infographic-tabs [role="tabpanel"]:focus {
  outline: 0;
}

.infographic-tabs [role="tabpanel"] p {
  margin: 0;
}

.infographic-tabs [role="tabpanel"] * + p {
  margin-top: 1em;
}

.infographic-wrapper {
  position: relative;
}

.infographic-content {
  position: relative;
  z-index: 1;
  text-align: center;
  margin-top: 75px;
}

@media only screen and (max-width: 767px) {
  .infographic-content {
    margin-top: 35px;
  }
}

.infographic .wrapper-md {
  position: relative;
}

.infographic-background,
.infographic .mask-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.infographic-background {
  background: linear-gradient(180deg, #FFFFFF 43.17%, #90C5FF 100%);
}

.infographic-background.is-night {
  background: linear-gradient(180deg, #1C1C1C 53.17%, #2B276B 84.99%);
}

.infographic-toggle {
  margin-bottom: 60px;
}

.infographic [role="tabpanel"] [data-toggle-transition] .infographic-background {
  transition: .3s ease-in-out all;
}

.infographic [role="tabpanel"].day [data-toggle-transition="night"] .infographic-background {
  opacity: 0;
}

.infographic [role="tabpanel"].day [data-toggle-transition="day"] .infographic-background {
  opacity: 1;
}

.infographic [role="tabpanel"].night {
  color: #fff;
}

.infographic [role="tabpanel"].night [data-toggle-transition="night"] .infographic-background {
  opacity: 1;
}

.infographic [role="tabpanel"].night [data-toggle-transition="day"] .infographic-background {
  opacity: 0;
}

.infographic [role="tablist"].night {
  color: #fff;
}

.infographic .custom-toggle {
  display: inline-flex;
  align-items: center;
}

.infographic .custom-toggle__label {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  min-width: 120px;
}

@media only screen and (max-width: 767px) {
  .infographic .custom-toggle__label {
    min-width: 90px;
  }
}

.infographic .custom-toggle__label img {
  margin-bottom: 8px;
}

.infographic .is-off:checked ~ .custom-toggle__switcher {
  background-color: #fff;
}

.infographic .is-off:checked ~ .custom-toggle__background {
  background-color: #575757;
}

.infographic .is-on:checked ~ .custom-toggle__switcher {
  background-color: #16481a;
}

.infographic .is-on:checked ~ .custom-toggle__background {
  background-color: #E8E8E8;
}

.news-press-heading {
  margin-bottom: 30px;
  font-weight: 400;
}

.news-press-videos-container {
  margin-bottom: 50px;
}

.news-press-videos-container .tns-nav {
  text-align: center;
}

.news-press-videos-container .tns-controls {
  position: absolute;
}

.news-press-videos-container [data-controls], .news-press-videos-container [data-nav] {
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  text-transform: none;
  color: inherit;
  font-size: inherit;
  font-weight: normal;
  white-space: initial;
  border-radius: 0;
  text-align: left;
}

.news-press-videos-container [data-nav] {
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 1px solid #ccc;
  border-radius: 50%;
  margin: 2px;
}

.news-press-videos-container [data-nav].tns-nav-active {
  border: 0;
  background: #FF6B0B;
}

.news-press-videos .video {
  background-color: #f1f1f1;
  position: relative;
  height: 0;
}

@media only screen and (min-width: 768px) {
  .news-press-container {
    position: relative;
  }
  .news-press-container:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    border-left: 1px solid #ccc;
  }
}

@media only screen and (min-width: 768px) {
  .news-press div.news {
    width: calc((((100% - 220px) / 12) * 4) + 60px - .1px);
    box-sizing: border-box;
    float: left;
    margin-left: 20px !important;
    margin-left: calc( (((100% - 220px) / 12) * 1) + 0px + 20px) !important;
  }
  .news-press div.news:first-child {
    margin-left: 0 !important;
  }
  .news-press div.news:first-child {
    margin-left: calc( (((100% - 220px) / 12) * 1) + 0px + 20px) !important;
  }
}

.news-press div.news h1, .news-press div.news h2, .news-press div.news h3, .news-press div.news h4, .news-press div.news h5, .news-press div.news h6 {
  font-size: 24px;
  background: linear-gradient(10deg, #33ccff 10%, #333399 100%);
  color: #fff;
  padding: 10px 20px;
  margin-top: 0;
}

@media only screen and (max-width: 767px) {
  .news-press div.news h1, .news-press div.news h2, .news-press div.news h3, .news-press div.news h4, .news-press div.news h5, .news-press div.news h6 {
    font-size: 28px;
  }
}

@media only screen and (min-width: 768px) {
  .news-press div.press {
    width: calc((((100% - 220px) / 12) * 4) + 60px - .1px);
    box-sizing: border-box;
    float: left;
    margin-left: 20px !important;
    margin-left: calc( (((100% - 220px) / 12) * 2) + 20px + 40px) !important;
  }
  .news-press div.press:first-child {
    margin-left: 0 !important;
  }
  .news-press div.press:first-child {
    margin-left: calc( (((100% - 220px) / 12) * 2) + 20px + 40px) !important;
  }
}

.news-press div.press h1, .news-press div.press h2, .news-press div.press h3, .news-press div.press h4, .news-press div.press h5, .news-press div.press h6 {
  font-size: 24px;
  background: linear-gradient(10deg, #333399 10%, #33ccff 100%);
  color: #fff;
  padding: 10px 20px;
  margin-top: 0;
}

@media only screen and (max-width: 767px) {
  .news-press div.press h1, .news-press div.press h2, .news-press div.press h3, .news-press div.press h4, .news-press div.press h5, .news-press div.press h6 {
    font-size: 28px;
  }
}

.news-press ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.news-press ul li {
  margin-bottom: 20px;
}

.news-press ul li div.source {
  font-size: 14px;
  text-transform: uppercase;
}

.news-press ul li a {
  font-size: 16px;
  line-height: 1.5;
}

.news-press ul li div.date {
  font-size: 14px;
}

.news-press .load-more {
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .news-press .load-more {
    margin-top: 30px;
    margin-bottom: 40px;
  }
}

.news-press .load-more .button {
  margin-top: 40px;
  color: #fff;
}

.news-press [data-load-more-list] {
  transition: .3s ease-in-out height;
}

.page-intro-heading {
  margin: 0 0 30px;
  display: inline-block;
  background: linear-gradient(45deg, #ff6633 0%, #ffcc00 100%);
  color: #ff8f1f;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.page-intro-heading.text-center {
  display: block;
}

.page-intro-heading h1, .page-intro-heading h2, .page-intro-heading h3, .page-intro-heading h4, .page-intro-heading h5, .page-intro-heading h6 {
  margin: 0;
}

.page-intro-heading p {
  margin: 0;
}

.page-intro-subheading {
  margin: 0 0 30px;
}

.page-intro .wrapper > *:last-child {
  margin-bottom: 0;
}

article.partner-form {
  padding: 100px 0;
  background: linear-gradient(10deg, #ff6633 0%, #ffcc00 100%);
  color: #fff;
  text-align: center;
  position: relative;
}

article.partner-form .partner-form-success, article.partner-form .error-message {
  display: none;
}

article.partner-form .partner-form-container, article.partner-form .partner-form-success {
  background: rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 60px;
  position: relative;
}

article.partner-form .partner-form-container p, article.partner-form .partner-form-success p {
  font-size: 24px;
}

@media only screen and (max-width: 767px) {
  article.partner-form .partner-form-container, article.partner-form .partner-form-success {
    padding: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  article.partner-form .partner-form-container, article.partner-form .partner-form-success {
    padding: 40px;
  }
}

article.partner-form h2 {
  text-align: center;
}

article.partner-form .partner-form-about {
  margin: 30px 0;
}

article.partner-form p.partner-form-required {
  font-size: 14px;
}

@media only screen and (min-width: 768px) {
  article.partner-form form {
    width: calc((((100% - 220px) / 12) * 8) + 140px - .1px);
    box-sizing: border-box;
    float: left;
    margin-left: 20px !important;
    margin-left: calc( (((100% - 220px) / 12) * 2) + 20px + 20px) !important;
  }
  article.partner-form form:first-child {
    margin-left: 0 !important;
  }
  article.partner-form form:first-child {
    margin-left: calc( (((100% - 220px) / 12) * 2) + 20px + 20px) !important;
  }
}

article.partner-form form p.intro {
  margin-bottom: 50px;
}

article.partner-form form .item.city {
  flex-grow: 1.5;
}

article.partner-form form button {
  background: #fff;
  color: #ff8f1f;
}

article.partner-form form .buttons {
  margin-top: 30px;
}

article.partner-form form p.legal {
  margin-top: -20px;
  font-size: 14px;
  text-align: center;
}

article.partner-form form p.legal a {
  color: #fff;
  text-decoration: underline;
}

article.partner-form form select {
  border-radius: 16px;
  padding: 17px 15px;
  height: 58.5px;
}

article.partner-form form input {
  border: 1px solid #eaeaea;
  border-radius: 16px;
  background-color: #fff;
  transition: all 0.25s;
}

article.partner-form form input:focus, article.partner-form form input:active {
  box-shadow: 0 0 0px 1px #555;
  border-color: #eaeaea;
  outline: 0;
}

article.partner-form .combobox {
  display: inline-block;
  position: relative;
  margin-bottom: 2em;
  margin-top: 1em;
  width: 100%;
}

article.partner-form .combobox.active-markets {
  margin-bottom: 5px;
}

article.partner-form .combobox.active-markets ul {
  z-index: 1;
}

article.partner-form .markets-label {
  top: 10px !important;
  opacity: 1 !important;
}

article.partner-form .combo-input-display {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 58.5px;
  padding: 35px 15px 10px;
  border: 1px solid #eaeaea;
  border-radius: 16px;
  background-color: #fff;
  transition: all 0.25s;
}

article.partner-form .combo-input-display:focus, article.partner-form .combo-input-display:active {
  box-shadow: 0 0 0px 1px #555;
  border-color: #eaeaea;
  outline: 0;
}

article.partner-form .combobox-input-tag {
  display: inline-block;
  border-radius: 8px;
  padding: 6px 15px;
  margin: 2px;
  font-size: 14px;
  background: #2d72f7;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.3px;
}

article.partner-form .combobox button {
  background: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  position: absolute;
  right: 0;
  top: 0;
}

article.partner-form .combobox input {
  color: #757575;
  font-size: 16px;
  width: 100%;
  outline: none;
  padding: 17px 15px;
  margin: 0;
  border: 1px solid #fff;
  border-radius: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  overflow-y: scroll;
  max-height: 18em;
}

article.partner-form .combobox .toggle {
  position: absolute;
  right: 15px;
  top: 50%;
  height: 18px;
  transform: translateY(-50%);
  pointer-events: none;
}

article.partner-form .combobox .toggle svg {
  height: 18px;
  color: #555;
}

article.partner-form .toggle-list {
  position: absolute;
  right: 15px;
  top: 45%;
  height: 18px;
  pointer-events: none;
  z-index: 1;
}

article.partner-form .toggle-list svg {
  height: 18px;
  color: #555;
}

article.partner-form .toggle-arrow-fix {
  top: 25px !important;
}

article.partner-form .combobox ul {
  display: none;
  list-style: none;
  text-align: left;
  background-color: #fff;
  color: #202020;
  font-size: 16px;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 1px solid #999;
  border-radius: 5px;
  max-height: 300px;
  overflow-y: auto;
  position: absolute;
  top: calc(100% + 5px);
}

article.partner-form .combobox ul:focus {
  box-shadow: 0 0 0px 2px #555;
  border-color: #555;
  outline: 0;
}

article.partner-form .combobox li {
  color: #757575;
  font-size: 16px;
  padding: 15px 0 15px 15px;
  position: relative;
}

article.partner-form .combobox [aria-selected="true"] {
  background-color: #eaeaea;
  color: #333;
  display: flex;
  align-items: center;
}

article.partner-form .combobox [aria-selected="true"]:after {
  display: inline-block;
  position: absolute;
  right: 10px;
  width: 20px;
  height: 20px;
  content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='%23a5e226' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'></path></svg>");
}

article.partner-form .combobox .activedescendant {
  background: #2d72f7;
  color: #fff;
}

article.partner-form .text-box {
  padding: 14px 15px 15px 15px;
  border: 1px solid #eaeaea;
  border-radius: 16px;
  background-color: #fff;
  margin-top: 1em;
}

article.partner-form .text-box.fl-active {
  padding: 24px 15px 5px 15px;
}

article.partner-form .text-box.fl-active textarea {
  padding-top: 0px;
}

article.partner-form .text-box textarea {
  border: none;
  padding: 0px;
  border-radius: 0px;
  max-height: 80px;
}

article.partner-form .combobox-options {
  margin-bottom: 0;
  display: inline-block;
  position: relative;
  margin-top: 1em;
  width: 100%;
}

article.partner-form .combobox-options ul {
  z-index: 9999;
}

article.partner-form .combobox-options button {
  background: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  position: absolute;
  right: 0;
  top: 0;
}

article.partner-form .combobox-options input {
  color: #757575;
  font-size: 16px;
  width: 100%;
  outline: none;
  padding: 17px 15px;
  margin: 0;
  border: 1px solid #fff;
  border-radius: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  overflow-y: scroll;
  max-height: 18em;
}

article.partner-form .combobox-options .toggle {
  position: absolute;
  right: 15px;
  top: 50%;
  height: 18px;
  transform: translateY(-50%);
  pointer-events: none;
}

article.partner-form .combobox-options .toggle svg {
  height: 18px;
  color: #555;
}

article.partner-form .combobox-options ul {
  display: none;
  list-style: none;
  text-align: left;
  background-color: #fff;
  color: #202020;
  font-size: 16px;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 1px solid #999;
  border-radius: 5px;
  max-height: 300px;
  overflow-y: auto;
  position: absolute;
  top: calc(100% + 5px);
}

article.partner-form .combobox-options ul:focus {
  box-shadow: 0 0 0px 2px #555;
  border-color: #555;
  outline: 0;
}

article.partner-form .combobox-options li {
  color: #757575;
  font-size: 16px;
  padding: 15px 0 15px 15px;
  position: relative;
}

article.partner-form .combobox-options [aria-selected="true"] {
  background-color: #eaeaea;
  color: #333;
  display: flex;
  align-items: center;
}

article.partner-form .combobox-options [aria-selected="true"]:after {
  display: inline-block;
  position: absolute;
  right: 10px;
  width: 20px;
  height: 20px;
  content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='%23a5e226' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'></path></svg>");
}

article.partner-form .combobox-options .activedescendant {
  background: #2d72f7;
  color: #fff;
}

article.partner-form .selections + span.error {
  display: none;
}

article.partner-form .not-required + span.error {
  display: none !important;
}

article.partner-form span#businessfocus-error + .toggle {
  top: 29.25px !important;
}

@media only screen and (min-width: 1024px) {
  .product-cards {
    margin: 100px 0;
  }
}

.product-cards-header {
  margin-bottom: 65px;
}

@media only screen and (max-width: 767px) {
  .product-cards-header {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 1024px) {
  .product-cards-header .col {
    width: calc((((100% - 220px) / 12) * 5) + 80px - .1px);
    box-sizing: border-box;
    float: left;
    margin-left: 20px !important;
  }
  .product-cards-header .col:first-child {
    margin-left: 0 !important;
  }
  .product-cards-header .col + .col {
    width: calc((((100% - 220px) / 12) * 7) + 120px - .1px);
    box-sizing: border-box;
    float: left;
    margin-left: 20px !important;
  }
  .product-cards-header .col + .col:first-child {
    margin-left: 0 !important;
  }
  .product-cards-header .row {
    display: flex;
    align-items: center;
  }
}

.product-cards-filters {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}

.product-cards-filters-form {
  display: flex;
  flex-wrap: wrap;
}

.product-cards-filters-form > * {
  padding: 10px 20px 10px 0;
}

@media only screen and (min-width: 1024px) {
  .product-cards-filters-form {
    max-width: 80%;
  }
  .product-cards-filters-form > * {
    padding: 10px;
    flex: 0 0 50%;
  }
}

.product-cards-list {
  display: flex;
  flex-wrap: wrap;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.16);
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.product-cards-list > [class*="is-"] {
  min-width: 0;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
}

.product-cards-list > [class*="is-"]:last-child {
  margin-bottom: 0;
}

.product-cards-list .is-full {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
  max-width: 100%;
}

.product-cards-list .is-1\/2 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 50%;
  max-width: 50%;
}

.product-cards-list .is-1\/3 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 33.3333%;
  max-width: 33.3333%;
}

.product-cards-list .is-2\/3 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 66.6666%;
  max-width: 66.6666%;
}

.product-cards-list .is-1\/4 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 25%;
  max-width: 25%;
}

.product-cards-list .is-3\/4 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 75%;
  max-width: 75%;
}

.product-cards-list .is-1\/6 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 16.66666%;
  max-width: 16.66666%;
}

.product-cards-list .is-5\/6 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 83.3333%;
  max-width: 83.3333%;
}

.product-cards-list .is-1\/5 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 20%;
  max-width: 20%;
}

.product-cards-list .is-2\/5 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 40%;
  max-width: 40%;
}

.product-cards-list .is-3\/5 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 60%;
  max-width: 60%;
}

.product-cards-list .is-4\/5 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 80%;
  max-width: 80%;
}

@media screen and (min-width: 568px) {
  .product-cards-list .xs\:is-full {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%;
    max-width: 100%;
  }
  .product-cards-list .xs\:is-1\/2 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    max-width: 50%;
  }
  .product-cards-list .xs\:is-1\/3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }
  .product-cards-list .xs\:is-2\/3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 66.6666%;
    max-width: 66.6666%;
  }
  .product-cards-list .xs\:is-1\/4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 25%;
    max-width: 25%;
  }
  .product-cards-list .xs\:is-3\/4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 75%;
    max-width: 75%;
  }
  .product-cards-list .xs\:is-1\/6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 16.66666%;
    max-width: 16.66666%;
  }
  .product-cards-list .xs\:is-5\/6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 83.3333%;
    max-width: 83.3333%;
  }
  .product-cards-list .xs\:is-1\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 20%;
    max-width: 20%;
  }
  .product-cards-list .xs\:is-2\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 40%;
    max-width: 40%;
  }
  .product-cards-list .xs\:is-3\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 60%;
    max-width: 60%;
  }
  .product-cards-list .xs\:is-4\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 80%;
    max-width: 80%;
  }
}

@media screen and (min-width: 768px) {
  .product-cards-list .sm\:is-full {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%;
    max-width: 100%;
  }
  .product-cards-list .sm\:is-1\/2 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    max-width: 50%;
  }
  .product-cards-list .sm\:is-1\/3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }
  .product-cards-list .sm\:is-2\/3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 66.6666%;
    max-width: 66.6666%;
  }
  .product-cards-list .sm\:is-1\/4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 25%;
    max-width: 25%;
  }
  .product-cards-list .sm\:is-3\/4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 75%;
    max-width: 75%;
  }
  .product-cards-list .sm\:is-1\/6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 16.66666%;
    max-width: 16.66666%;
  }
  .product-cards-list .sm\:is-5\/6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 83.3333%;
    max-width: 83.3333%;
  }
  .product-cards-list .sm\:is-1\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 20%;
    max-width: 20%;
  }
  .product-cards-list .sm\:is-2\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 40%;
    max-width: 40%;
  }
  .product-cards-list .sm\:is-3\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 60%;
    max-width: 60%;
  }
  .product-cards-list .sm\:is-4\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 80%;
    max-width: 80%;
  }
}

@media screen and (min-width: 1024px) {
  .product-cards-list .md\:is-full {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%;
    max-width: 100%;
  }
  .product-cards-list .md\:is-1\/2 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    max-width: 50%;
  }
  .product-cards-list .md\:is-1\/3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }
  .product-cards-list .md\:is-2\/3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 66.6666%;
    max-width: 66.6666%;
  }
  .product-cards-list .md\:is-1\/4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 25%;
    max-width: 25%;
  }
  .product-cards-list .md\:is-3\/4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 75%;
    max-width: 75%;
  }
  .product-cards-list .md\:is-1\/6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 16.66666%;
    max-width: 16.66666%;
  }
  .product-cards-list .md\:is-5\/6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 83.3333%;
    max-width: 83.3333%;
  }
  .product-cards-list .md\:is-1\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 20%;
    max-width: 20%;
  }
  .product-cards-list .md\:is-2\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 40%;
    max-width: 40%;
  }
  .product-cards-list .md\:is-3\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 60%;
    max-width: 60%;
  }
  .product-cards-list .md\:is-4\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 80%;
    max-width: 80%;
  }
}

@media screen and (min-width: 1280px) {
  .product-cards-list .lg\:is-full {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%;
    max-width: 100%;
  }
  .product-cards-list .lg\:is-1\/2 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    max-width: 50%;
  }
  .product-cards-list .lg\:is-1\/3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }
  .product-cards-list .lg\:is-2\/3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 66.6666%;
    max-width: 66.6666%;
  }
  .product-cards-list .lg\:is-1\/4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 25%;
    max-width: 25%;
  }
  .product-cards-list .lg\:is-3\/4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 75%;
    max-width: 75%;
  }
  .product-cards-list .lg\:is-1\/6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 16.66666%;
    max-width: 16.66666%;
  }
  .product-cards-list .lg\:is-5\/6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 83.3333%;
    max-width: 83.3333%;
  }
  .product-cards-list .lg\:is-1\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 20%;
    max-width: 20%;
  }
  .product-cards-list .lg\:is-2\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 40%;
    max-width: 40%;
  }
  .product-cards-list .lg\:is-3\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 60%;
    max-width: 60%;
  }
  .product-cards-list .lg\:is-4\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 80%;
    max-width: 80%;
  }
}

@media screen and (min-width: 1360px) {
  .product-cards-list .xl\:is-full {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%;
    max-width: 100%;
  }
  .product-cards-list .xl\:is-1\/2 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    max-width: 50%;
  }
  .product-cards-list .xl\:is-1\/3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }
  .product-cards-list .xl\:is-2\/3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 66.6666%;
    max-width: 66.6666%;
  }
  .product-cards-list .xl\:is-1\/4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 25%;
    max-width: 25%;
  }
  .product-cards-list .xl\:is-3\/4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 75%;
    max-width: 75%;
  }
  .product-cards-list .xl\:is-1\/6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 16.66666%;
    max-width: 16.66666%;
  }
  .product-cards-list .xl\:is-5\/6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 83.3333%;
    max-width: 83.3333%;
  }
  .product-cards-list .xl\:is-1\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 20%;
    max-width: 20%;
  }
  .product-cards-list .xl\:is-2\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 40%;
    max-width: 40%;
  }
  .product-cards-list .xl\:is-3\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 60%;
    max-width: 60%;
  }
  .product-cards-list .xl\:is-4\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 80%;
    max-width: 80%;
  }
}

@media only screen and (min-width: 1024px) {
  .product-cards-list.single-card {
    width: 50%;
  }
}

.product-card {
  position: relative;
  padding: 40px;
  border-radius: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 767px) {
  .product-card {
    padding: 30px;
  }
}

@media only screen and (min-width: 1024px) {
  .product-card.is-1\/2 .product-card-header {
    display: flex;
    align-items: center;
  }
  .product-card.is-1\/2 .product-card-header img {
    margin-right: 15px;
  }
}

.product-card-header h1, .product-card-header h2, .product-card-header h3, .product-card-header h4, .product-card-header h5, .product-card-header h6 {
  margin-top: 0;
}

.product-card-tag {
  position: absolute;
  top: 0;
  right: 0;
  background: #2D72F7;
  color: #fff;
  font-size: 10px;
  padding: 5px;
  text-transform: uppercase;
  letter-spacing: 0.04em;
}

.product-card-content ul, .product-card-content ol {
  list-style-type: initial;
}

.product-card-button {
  margin-top: auto;
}

.product-card-button .button {
  white-space: normal;
  margin-top: 10px;
}

@media only screen and (min-width: 1024px) {
  .product-highlights {
    margin: 100px 0;
  }
  .product-highlights .col {
    width: calc((((100% - 220px) / 12) * 5) + 80px - .1px);
    box-sizing: border-box;
    float: left;
    margin-left: 20px !important;
  }
  .product-highlights .col:first-child {
    margin-left: 0 !important;
  }
  .product-highlights .col + .col {
    width: calc((((100% - 220px) / 12) * 7) + 120px - .1px);
    box-sizing: border-box;
    float: left;
    margin-left: 20px !important;
  }
  .product-highlights .col + .col:first-child {
    margin-left: 0 !important;
  }
}

.product-highlights-heading {
  margin-top: 0;
}

@media only screen and (max-width: 767px) {
  .product-highlights-heading {
    margin-bottom: 20px;
  }
}

.product-highlights-heading.title-top {
  text-align: center;
}

.product-highlights-intro {
  padding-top: 40px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .product-highlights-intro {
    padding-top: 20px;
  }
}

.product-highlights-intro::after {
  content: '';
  line-height: 0;
  border-top: 2px solid black;
  flex: 1;
  margin-left: 10px;
}

.product-highlights-list {
  list-style: none;
  margin: 0;
  padding: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  margin: 0 -25px;
  flex-wrap: wrap;
}

.product-highlights-list > [class*="is-"] {
  padding: 0 25px;
  min-width: 0;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
  max-width: 100%;
}

.product-highlights-list > [class*="is-"]:last-child {
  margin-bottom: 0;
}

.product-highlights-list .is-full {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
  max-width: 100%;
}

.product-highlights-list .is-1\/2 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 50%;
  max-width: 50%;
}

.product-highlights-list .is-1\/3 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 33.3333%;
  max-width: 33.3333%;
}

.product-highlights-list .is-2\/3 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 66.6666%;
  max-width: 66.6666%;
}

.product-highlights-list .is-1\/4 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 25%;
  max-width: 25%;
}

.product-highlights-list .is-3\/4 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 75%;
  max-width: 75%;
}

.product-highlights-list .is-1\/6 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 16.66666%;
  max-width: 16.66666%;
}

.product-highlights-list .is-5\/6 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 83.3333%;
  max-width: 83.3333%;
}

.product-highlights-list .is-1\/5 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 20%;
  max-width: 20%;
}

.product-highlights-list .is-2\/5 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 40%;
  max-width: 40%;
}

.product-highlights-list .is-3\/5 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 60%;
  max-width: 60%;
}

.product-highlights-list .is-4\/5 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 80%;
  max-width: 80%;
}

@media screen and (min-width: 568px) {
  .product-highlights-list .xs\:is-full {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%;
    max-width: 100%;
  }
  .product-highlights-list .xs\:is-1\/2 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    max-width: 50%;
  }
  .product-highlights-list .xs\:is-1\/3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }
  .product-highlights-list .xs\:is-2\/3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 66.6666%;
    max-width: 66.6666%;
  }
  .product-highlights-list .xs\:is-1\/4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 25%;
    max-width: 25%;
  }
  .product-highlights-list .xs\:is-3\/4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 75%;
    max-width: 75%;
  }
  .product-highlights-list .xs\:is-1\/6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 16.66666%;
    max-width: 16.66666%;
  }
  .product-highlights-list .xs\:is-5\/6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 83.3333%;
    max-width: 83.3333%;
  }
  .product-highlights-list .xs\:is-1\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 20%;
    max-width: 20%;
  }
  .product-highlights-list .xs\:is-2\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 40%;
    max-width: 40%;
  }
  .product-highlights-list .xs\:is-3\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 60%;
    max-width: 60%;
  }
  .product-highlights-list .xs\:is-4\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 80%;
    max-width: 80%;
  }
}

@media screen and (min-width: 768px) {
  .product-highlights-list .sm\:is-full {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%;
    max-width: 100%;
  }
  .product-highlights-list .sm\:is-1\/2 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    max-width: 50%;
  }
  .product-highlights-list .sm\:is-1\/3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }
  .product-highlights-list .sm\:is-2\/3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 66.6666%;
    max-width: 66.6666%;
  }
  .product-highlights-list .sm\:is-1\/4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 25%;
    max-width: 25%;
  }
  .product-highlights-list .sm\:is-3\/4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 75%;
    max-width: 75%;
  }
  .product-highlights-list .sm\:is-1\/6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 16.66666%;
    max-width: 16.66666%;
  }
  .product-highlights-list .sm\:is-5\/6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 83.3333%;
    max-width: 83.3333%;
  }
  .product-highlights-list .sm\:is-1\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 20%;
    max-width: 20%;
  }
  .product-highlights-list .sm\:is-2\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 40%;
    max-width: 40%;
  }
  .product-highlights-list .sm\:is-3\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 60%;
    max-width: 60%;
  }
  .product-highlights-list .sm\:is-4\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 80%;
    max-width: 80%;
  }
}

@media screen and (min-width: 1024px) {
  .product-highlights-list .md\:is-full {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%;
    max-width: 100%;
  }
  .product-highlights-list .md\:is-1\/2 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    max-width: 50%;
  }
  .product-highlights-list .md\:is-1\/3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }
  .product-highlights-list .md\:is-2\/3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 66.6666%;
    max-width: 66.6666%;
  }
  .product-highlights-list .md\:is-1\/4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 25%;
    max-width: 25%;
  }
  .product-highlights-list .md\:is-3\/4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 75%;
    max-width: 75%;
  }
  .product-highlights-list .md\:is-1\/6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 16.66666%;
    max-width: 16.66666%;
  }
  .product-highlights-list .md\:is-5\/6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 83.3333%;
    max-width: 83.3333%;
  }
  .product-highlights-list .md\:is-1\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 20%;
    max-width: 20%;
  }
  .product-highlights-list .md\:is-2\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 40%;
    max-width: 40%;
  }
  .product-highlights-list .md\:is-3\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 60%;
    max-width: 60%;
  }
  .product-highlights-list .md\:is-4\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 80%;
    max-width: 80%;
  }
}

@media screen and (min-width: 1280px) {
  .product-highlights-list .lg\:is-full {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%;
    max-width: 100%;
  }
  .product-highlights-list .lg\:is-1\/2 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    max-width: 50%;
  }
  .product-highlights-list .lg\:is-1\/3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }
  .product-highlights-list .lg\:is-2\/3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 66.6666%;
    max-width: 66.6666%;
  }
  .product-highlights-list .lg\:is-1\/4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 25%;
    max-width: 25%;
  }
  .product-highlights-list .lg\:is-3\/4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 75%;
    max-width: 75%;
  }
  .product-highlights-list .lg\:is-1\/6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 16.66666%;
    max-width: 16.66666%;
  }
  .product-highlights-list .lg\:is-5\/6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 83.3333%;
    max-width: 83.3333%;
  }
  .product-highlights-list .lg\:is-1\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 20%;
    max-width: 20%;
  }
  .product-highlights-list .lg\:is-2\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 40%;
    max-width: 40%;
  }
  .product-highlights-list .lg\:is-3\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 60%;
    max-width: 60%;
  }
  .product-highlights-list .lg\:is-4\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 80%;
    max-width: 80%;
  }
}

@media screen and (min-width: 1360px) {
  .product-highlights-list .xl\:is-full {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%;
    max-width: 100%;
  }
  .product-highlights-list .xl\:is-1\/2 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    max-width: 50%;
  }
  .product-highlights-list .xl\:is-1\/3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }
  .product-highlights-list .xl\:is-2\/3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 66.6666%;
    max-width: 66.6666%;
  }
  .product-highlights-list .xl\:is-1\/4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 25%;
    max-width: 25%;
  }
  .product-highlights-list .xl\:is-3\/4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 75%;
    max-width: 75%;
  }
  .product-highlights-list .xl\:is-1\/6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 16.66666%;
    max-width: 16.66666%;
  }
  .product-highlights-list .xl\:is-5\/6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 83.3333%;
    max-width: 83.3333%;
  }
  .product-highlights-list .xl\:is-1\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 20%;
    max-width: 20%;
  }
  .product-highlights-list .xl\:is-2\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 40%;
    max-width: 40%;
  }
  .product-highlights-list .xl\:is-3\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 60%;
    max-width: 60%;
  }
  .product-highlights-list .xl\:is-4\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 80%;
    max-width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .product-highlights-list .product-highlight {
    margin-bottom: 30px;
  }
}

.product-highlights-list p {
  font-size: 16px;
}

.product-highlights-disclaimer {
  margin-top: 50px;
}

@media only screen and (min-width: 1024px) {
  .product-highlights-disclaimer {
    margin-top: 100px;
  }
}

.product-highlights-alt .product-highlights-heading {
  text-align: center;
  margin-bottom: 80px;
}

@media only screen and (max-width: 767px) {
  .product-highlights-alt .product-highlights-heading {
    margin-bottom: 20px;
    text-align: left;
  }
}

@media only screen and (min-width: 1024px) {
  .services-availability {
    margin: 100px 0;
  }
}

.services-availability .services {
  position: relative;
  border-radius: 40px;
}

.services-availability .services-content {
  padding: 60px 120px;
  text-align: center;
  color: #fff;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .services-availability .services-content {
    padding: 60px 80px;
  }
}

@media only screen and (max-width: 767px) {
  .services-availability .services-content {
    padding: 40px 30px;
  }
}

.services-availability .services-mask {
  background: no-repeat bottom right url(../images/services-mask.svg);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.services-availability .services-heading {
  margin-bottom: 45px;
}

.services-availability .services form {
  margin: 0 auto;
}

.services-availability .services form .item {
  position: relative;
}

.services-availability .services form .item + .item {
  flex: 0;
}

@media only screen and (max-width: 767px) {
  .services-availability .services form .item + .item {
    margin-top: 20px;
  }
}

.services-availability .services form label {
  position: absolute;
  top: -22px;
  font-size: 14px;
}

.services-availability .services form input[type="text"] {
  border-radius: 5px;
}

.services-availability .services form button {
  background: linear-gradient(10deg, #33ccff 0%, #a542ef 100%);
  color: #fff;
}

@media only screen and (min-width: 1024px) {
  .services-availability .services form {
    width: 750px;
  }
}

@media only screen and (min-width: 1024px) {
  .setup-steps {
    margin: 100px 0;
  }
}

.setup-steps-header {
  text-align: center;
  margin-bottom: 60px;
}

@media only screen and (max-width: 767px) {
  .setup-steps-header {
    margin-bottom: 30px;
  }
}

.setup-steps ol {
  list-style: none;
  margin: 0;
  padding: 0;
  list-style: none;
  counter-reset: setup-steps-counter;
}

.setup-steps ol > li {
  position: relative;
  counter-increment: setup-steps-counter;
  margin-bottom: 40px;
  margin-left: 20%;
}

@media only screen and (max-width: 767px) {
  .setup-steps ol > li {
    margin-bottom: 20px;
  }
}

.setup-steps ol > li:last-child {
  margin-bottom: 0;
}

.setup-steps ol > li::before {
  content: counter(setup-steps-counter);
  display: inline-block;
  background: linear-gradient(45deg, #ff6633 0%, #ffcc00 100%);
  color: #ff8f1f;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-size: 46px;
  position: absolute;
  left: -10%;
  top: -12px;
}

@media only screen and (max-width: 767px) {
  .setup-steps ol > li::before {
    left: -56px;
  }
}

article.sidebar-nav {
  margin-top: 0;
}

article.sidebar-nav nav {
  background: #FFFFFF;
  box-shadow: 0px 15px 34px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.02);
  border-radius: 16px;
  padding: 20px 40px;
}

article.sidebar-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

article.sidebar-nav ul > li {
  position: relative;
}

article.sidebar-nav ul > li a {
  display: block;
  position: relative;
  padding: 20px 0;
}

article.sidebar-nav ul > li > a:hover {
  font-weight: bold;
  color: #000;
}

article.sidebar-nav ul > li:first-child {
  padding-top: 0;
}

article.sidebar-nav ul > li:last-child {
  padding-bottom: 0;
}

article.sidebar-nav ul > li:not(.has-sub-nav) > a {
  position: relative;
}

article.sidebar-nav ul > li:not(.has-sub-nav) > a:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background: linear-gradient(10deg, #ff6633 0%, #ffcc00 100%);
}

article.sidebar-nav ul > li:not(.has-sub-nav) > a:before {
  opacity: 0;
}

article.sidebar-nav ul > li:not(.has-sub-nav) > a:hover > a, article.sidebar-nav ul > li:not(.has-sub-nav) > a.selected > a {
  font-weight: bold;
  color: #000;
}

article.sidebar-nav ul > li:not(.has-sub-nav) > a:hover::before, article.sidebar-nav ul > li:not(.has-sub-nav) > a.selected::before {
  bottom: 14px;
  opacity: 1;
}

article.sidebar-nav ul > li.has-sub-nav > a, article.sidebar-nav ul > li.has-sub-nav.selected > a {
  position: relative;
}

article.sidebar-nav ul > li.has-sub-nav > a:before, article.sidebar-nav ul > li.has-sub-nav.selected > a:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background: linear-gradient(10deg, #ff6633 0%, #ffcc00 100%);
}

article.sidebar-nav ul > li.has-sub-nav > a::before, article.sidebar-nav ul > li.has-sub-nav.selected > a::before {
  opacity: 0;
  bottom: 11px;
}

article.sidebar-nav ul > li.has-sub-nav > a:hover::before, article.sidebar-nav ul > li.has-sub-nav.selected > a:hover::before {
  bottom: 11px;
  opacity: 1;
}

article.sidebar-nav ul ul {
  margin-left: 22px;
}

article.sidebar-nav ul.is-divided > li.has-sub-nav > a:hover::before {
  bottom: -2px;
}

article.sidebar-nav ul.is-divided > li.has-sub-nav.selected > a::before {
  opacity: 1;
}

article.sidebar-nav ul.is-divided > li > a:hover::before {
  bottom: -2px;
}

article.sidebar-nav ul.has-description:not(.is-divided) > li > a:hover::before {
  bottom: 14px;
}

article.sidebar-nav ul.has-description.is-divided > li:hover > a {
  position: static;
  font-weight: bold;
  color: #000;
}

article.sidebar-nav ul.has-description.is-divided > li:hover > a::before {
  bottom: -2px;
  opacity: 1;
}

article.sidebar-nav .sidebar-nav-list {
  transition: .3s ease-in-out all;
}

article.sidebar-nav .sidebar-nav-list.is-divided > li {
  border-bottom: 1px solid #ccc;
}

article.sidebar-nav .sidebar-nav-list.is-divided > li:last-child {
  border-bottom-color: transparent;
}

article.sidebar-nav .sidebar-nav-list.is-divided > li > a {
  font-weight: 600;
}

article.sidebar-nav .sidebar-nav-list.is-divided > li.selected::before, article.sidebar-nav .sidebar-nav-list.is-divided > li:not(.has-sub-nav):hover::before {
  bottom: -2px;
}

article.sidebar-nav .sidebar-nav-list .has-sub-nav > a {
  font-weight: 600;
}

article.sidebar-nav .sidebar-nav-list .has-sub-nav > span:not([aria-expanded="false"]) + a,
article.sidebar-nav .sidebar-nav-list .has-sub-nav > span:not([aria-expanded="false"]) + a:hover {
  padding-bottom: 10px;
}

article.sidebar-nav .sidebar-nav-list .has-sub-nav > span:not([aria-expanded="false"]) + a::before,
article.sidebar-nav .sidebar-nav-list .has-sub-nav > span:not([aria-expanded="false"]) + a:hover::before {
  bottom: 4px;
  opacity: 1;
}

article.sidebar-nav .sidebar-nav-list .has-sub-nav:hover > .sidebar-accordion-toggle::after {
  border-left-color: #000;
}

article.sidebar-nav .sidebar-accordion-toggle {
  position: absolute;
  width: 15px;
  height: 20px;
  top: 22px;
  left: -20px;
  cursor: pointer;
  outline: 0;
}

article.sidebar-nav [data-accordion-trigger] {
  transition: .3s ease-in-out all;
}

article.sidebar-nav [data-accordion-trigger]::after {
  position: absolute;
  left: 0;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  pointer-events: none;
  border-color: rgba(45, 114, 247, 0);
  border-left-color: #2D72F7;
  border-width: 10px;
  margin-top: -6px;
  transition: .3s ease-in-out transform;
  transform-origin: 4px 5px;
  border-top-width: 6px;
  border-bottom-width: 6px;
}

article.sidebar-nav [data-accordion-trigger][aria-expanded="true"]::after {
  transform: rotateZ(90deg);
}

article.sidebar-nav .sidebar-nav-link-description {
  font-size: 14px;
  margin-bottom: 25px;
  margin-top: -10px;
}

.state-listing .state-listing-header {
  margin: 0 auto 50px;
  max-width: 815px;
  text-align: center;
}

.state-listing .title {
  color: #fff;
  text-align: center;
  margin-bottom: 25px;
  font-size: 48px;
  display: inline-block;
  background: linear-gradient(45deg, #ff6633 0%, #ffcc00 100%);
  color: #ff8f1f;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media only screen and (max-width: 767px) {
  .state-listing .title {
    font-size: 20px;
  }
}

.state-listing .sub-title {
  font-size: 16px;
}

@media only screen and (max-width: 767px) {
  .state-listing .sub-title {
    font-size: 18px;
  }
}

.state-listing .state-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

@media only screen and (min-width: 768px) {
  .state-listing .state-list {
    display: flex;
    flex-wrap: wrap;
  }
}

.state-listing .state-item {
  flex: 0 0 33.3333%;
  margin-bottom: 20px;
  text-align: center;
}

.state-listing .state {
  padding: 15px;
}

.state-listing .state-media {
  border-radius: 10px;
  object-fit: cover;
  line-height: 0;
  overflow: hidden;
}

.state-listing .state-image {
  width: 100%;
}

.state-listing .state-title {
  font-size: 24px;
  color: #2D72F7;
}

.state-listing .state-description {
  padding: 0 30px;
}

@media only screen and (max-width: 767px) {
  .state-listing .state-map {
    margin-bottom: 20px;
  }
}

@media all and (-ms-high-contrast: none) {
  .state-listing #map {
    max-width: 100%;
    width: 1158px;
    height: 643px;
  }
}

.state-listing .state {
  fill: #D3D3D3;
  transition: .2s ease-in-out all;
  transform-origin: center;
  transform-box: fill-box;
}

.state-listing .state.is-active {
  fill: #FF8200;
  cursor: pointer;
}

.state-listing .state.is-active.is-hover {
  fill: #292929;
  transform: scale(1.15);
  stroke: #fff;
  stroke-width: .75;
}

.state-listing .state-tooltip {
  position: absolute;
  display: block;
  font-size: 14px;
  color: #fff;
  padding: 5px 20px;
  line-height: 1.25;
  background: linear-gradient(45deg, #ff6633 20%, #ffcc00 80%);
}

@media only screen and (max-width: 767px) {
  .state-listing .state-tooltip {
    line-height: 1.5;
  }
}

@media only screen and (max-width: 767px) {
  .state-listing a {
    pointer-events: none;
  }
}

.state-item .info-card-title {
  font-size: 24px;
}

@media only screen and (min-width: 768px) {
  article.testimonial {
    margin: 100px 0;
  }
}

@media only screen and (min-width: 768px) {
  article.testimonial .title {
    width: calc((((100% - 220px) / 12) * 6) + 100px - .1px);
    box-sizing: border-box;
    float: left;
    margin-left: 20px !important;
  }
  article.testimonial .title:first-child {
    margin-left: 0 !important;
  }
}

article.testimonial .title h1, article.testimonial .title h2, article.testimonial .title h3, article.testimonial .title h4, article.testimonial .title h5, article.testimonial .title h6 {
  font-size: 56px;
  line-height: 72px;
  display: inline-block;
  background: linear-gradient(45deg, #33ccff 0%, #a542ef 100%);
  color: #6195f9;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media only screen and (max-width: 767px) {
  article.testimonial .title h1, article.testimonial .title h2, article.testimonial .title h3, article.testimonial .title h4, article.testimonial .title h5, article.testimonial .title h6 {
    font-size: 32px;
    line-height: 1.25em;
  }
}

article.testimonial .content {
  position: relative;
  z-index: 0;
}

@media only screen and (min-width: 768px) {
  article.testimonial .content {
    width: calc((((100% - 220px) / 12) * 6) + 100px - .1px);
    box-sizing: border-box;
    float: left;
    margin-left: 20px !important;
  }
  article.testimonial .content:first-child {
    margin-left: 0 !important;
  }
}

article.testimonial .content img {
  border-radius: 50%;
  max-width: 200px;
  max-height: 200px;
}

article.testimonial .content figure.quote {
  padding: 40px 0 60px 0;
}

@media only screen and (max-width: 767px) {
  article.testimonial .content figure.quote {
    padding: 20px 0 40px;
  }
}

article.testimonial .content figure.quote p {
  font-size: 16px;
  margin-top: 0;
}

article.testimonial .tns-nav button, article.testimonial .tns-controls button {
  background: none;
  padding: 0;
  color: #333;
  box-shadow: none;
}

article.testimonial .tns-slider.is-transitioning .tns-item {
  transition: 1s ease-in-out opacity;
}

article.testimonial .tns-nav {
  counter-reset: testimonial-nav-counter;
}

article.testimonial .tns-nav button {
  counter-increment: testimonial-nav-counter;
  min-width: 32px;
  min-height: 32px;
  border-radius: 50%;
  color: rgba(0, 0, 0, 0.4);
  font-weight: bold;
  text-align: center;
}

article.testimonial .tns-nav button:before {
  content: counter(testimonial-nav-counter);
}

article.testimonial .tns-nav .tns-nav-active {
  background: linear-gradient(10deg, #33ccff 0%, #a542ef 100%);
  color: #fff;
}

article.testimonial .tns-nav-controls-container {
  display: inline-flex;
  position: relative;
  padding-left: 32px;
  padding-right: 32px;
}

article.testimonial .tns-nav-controls-container.first-selected {
  padding-left: 0;
}

article.testimonial .tns-controls {
  display: flex;
  align-items: center;
}

article.testimonial .tns-controls [data-controls="prev"],
article.testimonial .tns-controls [data-controls="next"] {
  position: absolute;
  min-width: 32px;
  min-height: 32px;
}

article.testimonial .tns-controls [data-controls="prev"] {
  left: 0;
}

article.testimonial .tns-controls [data-controls="next"] {
  right: 0;
}

article.testimonial .tns-controls button[disabled] {
  display: none;
}

article.testimonial .tns-item {
  opacity: 0;
}

article.testimonial .tns-slide-active {
  opacity: 1;
}

article.testimonial .tns-outer {
  position: relative;
}

article.text-columns h4 {
  font-size: 20px;
}

@media only screen and (min-width: 768px) {
  article.text-columns .col {
    width: calc((((100% - 105%) / 8) * 4) + 45% - .1px);
    box-sizing: border-box;
    float: left;
    margin-left: 15% !important;
  }
  article.text-columns .col:first-child {
    margin-left: 0 !important;
  }
}

article.text-columns[class*="bg-"] {
  padding: 40px 0;
}

@media screen and (min-width: 1024px) {
  article.text-columns[class*="bg-"] {
    padding: 60px 0;
  }
}

@media screen and (min-width: 1024px) {
  article.text-columns.align-middle .wrapper {
    display: flex;
    align-items: center;
  }
}

article.text-columns.get-in-touch {
  text-align: center;
}

@media screen and (min-width: 1024px) {
  article.text-columns.get-in-touch {
    text-align: left;
  }
}

@media screen and (min-width: 768px) {
  article.text {
    margin-top: 80px;
  }
}

article.text .sidebar {
  margin-top: 60px;
}

@media screen and (min-width: 1024px) {
  article.text .sidebar {
    margin-top: 0;
  }
}

article.text .text-header h1 {
  margin-bottom: 15px;
  margin-top: 0;
  display: inline-block;
  background: linear-gradient(45deg, #ff6633 0%, #ffcc00 100%);
  color: #ff8f1f;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 48px;
  line-height: 1.15;
  padding-bottom: 3px;
}

@media only screen and (max-width: 767px) {
  article.text .text-header h1 {
    font-size: 36px;
  }
}

article.text .text-header-description {
  margin-top: 10px;
}

article.text .text-content h2 {
  font-size: 36px;
  margin-top: 60px;
}

@media only screen and (max-width: 767px) {
  article.text .text-content h2 {
    font-size: 30px;
  }
}

article.text .text-content h3 {
  font-size: 28px;
}

@media only screen and (max-width: 767px) {
  article.text .text-content h3 {
    font-size: 24px;
  }
}

article.text .text-content h4 {
  font-size: 24px;
}

@media only screen and (max-width: 767px) {
  article.text .text-content h4 {
    font-size: 20px;
  }
}

article.text .text-content img + p {
  margin-top: 0;
}

article.text .text-content a {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: none;
}

article.text .text-content ul {
  font-size: 16px;
  line-height: 1.5em;
}

article.text .text-content li {
  margin-bottom: 8px;
}

article.text .text-content .box,
article.text .text-content .blockquote,
article.text .text-content .blockquote-blue {
  display: block;
  padding: 30px;
  margin: 50px 0;
  border-radius: 10px;
  box-shadow: 5px 10px 25px rgba(0, 0, 0, 0.15);
}

@media screen and (min-width: 1024px) {
  article.text .text-content .box,
  article.text .text-content .blockquote,
  article.text .text-content .blockquote-blue {
    padding: 50px;
    box-shadow: 10px 15px 25px rgba(0, 0, 0, 0.15);
  }
}

article.text .text-content .box-golden-hour,
article.text .text-content .blockquote-golden-hour {
  background: linear-gradient(10deg, #ff6633 0%, #ffcc00 100%);
  color: #292929;
}

article.text .text-content .box-blue-hour,
article.text .text-content .blockquote-blue-hour,
article.text .text-content .blockquote-blue {
  background: linear-gradient(10deg, #333399 0%, #33ccff 100%);
  color: #fff;
}

article.text .text-content .box-aurora,
article.text .text-content .blockquote-aurora {
  background: linear-gradient(10deg, #33ccff 0%, #a542ef 100%);
  color: #fff;
}

article.text .text-content .box-plen-air,
article.text .text-content .blockquote-plen-air {
  background: linear-gradient(10deg, #04a5ff 0%, #6cffa9 100%);
  color: #292929;
}

article.text .text-content .box-green-shades,
article.text .text-content .blockquote-green-shades {
  background: linear-gradient(10deg, #16481a 0%, #a5e226 100%);
  color: #fff;
}

article.text .text-content .box-stone-white,
article.text .text-content .blockquote-stone-white {
  background: linear-gradient(201.18deg, #fff 49%, #b3b3b3 141%);
  color: #292929;
}

article.text .text-content .image-left {
  float: left;
  margin: 10px 30px 20px 0;
}

article.text .text-content .image-right {
  float: right;
  margin: 10px 0 20px 30px;
}

article.text .text-content .image-full {
  width: 100%;
  margin: 0 0 30px;
}

article.text .text-content > *:last-child {
  margin-bottom: 0;
}

article.text .text-meta {
  list-style: none;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  display: flex;
  color: #767676;
  font-size: 12px;
  margin-bottom: 25px;
  letter-spacing: 0.1em;
}

@media only screen and (max-width: 767px) {
  article.text .text-meta {
    display: block;
    margin-bottom: 0;
  }
}

article.text .text-meta li {
  margin-right: 34px;
}

@media only screen and (max-width: 767px) {
  article.text .text-meta li {
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 768px) {
  article.text .addthis_inline_share_toolbox {
    margin-bottom: 30px;
  }
}

article.text .text-image {
  margin-bottom: 30px;
  border-radius: 10px;
}

article.text .text-image p:last-child {
  margin-bottom: 0;
}

article.text .text-disclaimer {
  font-size: 13px;
}

article.text .text-disclaimer.black {
  color: #292929;
}

article.text .text-disclaimer.grey {
  color: #8f8f8f;
}

article.text .wrapper > *:first-child {
  margin-top: 0;
}

@media only screen and (min-width: 768px) {
  article.text.has-sidebar .text-header {
    width: calc((((100% - 605px) / 12) * 9) + 440px - .1px);
    box-sizing: border-box;
    float: left;
    margin-left: 55px !important;
  }
  article.text.has-sidebar .text-header:first-child {
    margin-left: 0 !important;
  }
}

@media screen and (min-width: 768px) {
  article.text.is-blog-article {
    font-size: 20px;
  }
}

article.text.is-faq-article header {
  margin-top: 0;
  margin-bottom: 40px;
}

@media screen and (min-width: 1024px) {
  article.text.is-faq-article header {
    margin-bottom: 60px;
  }
}

article.text.is-faq-article h2 {
  margin-bottom: 30px;
}

article.text.is-faq-article .popular-faqs-list {
  margin-bottom: 80px;
}

article.text.is-faq-article .mainContent *:last-child, article.text.is-faq-article .sidebar *:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 1024px) {
  article.text.is-faq-article .mainContent {
    padding-right: 50px;
  }
}

article.text.is-faq-search header {
  margin-bottom: 20px;
}

@media screen and (min-width: 1024px) {
  article.text.is-faq-search header {
    margin-bottom: 30px;
  }
}

article.text.is-faq-search .faq-listing {
  margin-top: 60px;
}

@media only screen and (min-width: 1024px) {
  article.video {
    margin: 100px 0;
  }
}

.video {
  text-align: center;
}

.video-content {
  margin-bottom: 50px;
}

.video-container {
  position: relative;
  width: 100%;
  height: 0;
}

.video-container.is-16x9 {
  padding-bottom: 56.25%;
}

.video-container > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.at-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

[data-modal-open][hidden] {
  display: none;
}

body.modal-open {
  overflow: hidden;
}

.js [data-modal],
.a11y-modal {
  -webkit-overflow-scrolling: touch;
  background: #fff;
  left: 20px;
  right: 20px;
  top: 20px;
  box-shadow: 0 0 1800px 18000px rgba(0, 0, 0, 0.4);
  margin: auto;
  max-height: 100%;
  max-width: 660px;
  opacity: 1;
  overflow: auto;
  padding: 40px;
  position: fixed;
  transform: translateY(0%);
  visibility: visible;
  z-index: 1000;
  border-radius: 20px;
}

@media only screen and (min-width: 768px) {
  .js [data-modal],
  .a11y-modal {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity .2s ease-in-out, transform .2s ease-in-out;
    bottom: auto;
    left: 0;
    right: 0;
    width: 100%;
  }
}

.js [data-modal][hidden] .a11y-modal[hidden] {
  display: block;
  opacity: 0;
  pointer-events: none;
  transform: translateY(15vh);
  visibility: hidden;
}

.a11y-modal__close-btn {
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  text-transform: none;
  color: inherit;
  font-size: inherit;
  font-weight: normal;
  white-space: initial;
  border-radius: 0;
  text-align: left;
}

.is-icon-btn {
  background: none;
  border: none;
  height: 2em;
  padding: 0;
  position: absolute;
  right: .75em;
  top: .75em;
  width: 2em;
}

[data-modal-x] {
  border-right: 2px solid;
  bottom: 0;
  display: block;
  height: 2em;
  left: 0;
  margin: auto;
  pointer-events: none;
  position: relative;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 2px;
}

[data-modal-x]:after {
  border-right: 2px solid;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(-90deg);
  width: 100%;
}

.blog-featured-heading {
  margin-top: 0;
  margin-bottom: 35px;
}

@media screen and (min-width: 768px) {
  .blog-featured-heading {
    margin-bottom: 55px;
  }
}

.blog-featured-cards {
  margin-bottom: -30px;
}

.blog-featured-cards .flex-grid > * > * {
  margin-bottom: 30px;
}

@media screen and (min-width: 768px) {
  .blog-featured-cards .flex-grid > * {
    display: flex;
  }
  .blog-featured-cards .flex-grid > * .info-card {
    flex: 1;
  }
}

.blog-featured.is-large {
  margin-top: -25px;
}

@media screen and (min-width: 768px) {
  .blog-featured.is-large {
    margin-top: -45px;
  }
}

.blog-featured.is-large .blog-featured-cards .flex-grid > * > * {
  margin-bottom: 30px;
}

@media screen and (min-width: 768px) {
  .blog-featured.is-large .blog-featured-cards .flex-grid > * > * {
    margin-bottom: 55px;
  }
}

@media screen and (min-width: 768px) {
  .blog-featured.is-large .blog-featured-cards .info-card-content {
    min-height: 350px;
    padding: 60px 100px;
  }
}

.reading-list {
  background: #F5F5F5;
  padding: 60px 0;
}

.reading-list-title {
  letter-spacing: -2px;
  color: #292929;
  margin-bottom: 50px;
}

.reading-list-list {
  list-style: none;
  margin: 0;
  padding: 0;
  margin: 0;
  padding: 0;
  transform: translate3d(0%, 0px, 0px);
}

@media screen and (min-width: 768px) {
  .reading-list .col:not(.tns-item) {
    width: 25%;
    float: left;
    padding-right: 20px;
  }
  .reading-list .col:not(.tns-item):nth-child(n+5) {
    display: none;
  }
}

.reading-list-card {
  background: linear-gradient(230.21deg, #33CCFF 7.99%, #333399 100%);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  color: #fff;
  padding: 30px;
  text-align: left;
  position: relative;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
}

.reading-list-card:hover, .reading-list-card:focus, .reading-list-card:active, .reading-list-card:visited {
  text-decoration: none;
  color: #fff;
}

.reading-list-card .info-card-header img {
  display: block;
  margin: 0 auto;
}

.reading-list-card .info-card-title {
  color: #fff;
  text-align: center;
  margin-top: 15px;
}

.reading-list-card .info-card-description {
  padding: 0;
  margin-bottom: 10px;
}

.reading-list-card .info-card-content {
  display: flex;
  justify-content: center;
}

.reading-list-card .info-card-meta {
  background-color: white;
  padding: 10px 30px;
  border: 0;
  border-radius: 15px;
}

.reading-list-card .info-card-meta-count {
  color: black;
}

.reading-list-card:after {
  content: '';
  position: absolute;
  top: 9px;
  left: 9px;
  bottom: -9px;
  right: -9px;
  z-index: -1;
  background: linear-gradient(230.21deg, #33CCFF 7.99%, #333399 100%);
  border-radius: 8px;
}

.reading-list-card-listing {
  list-style: none;
  margin: 25px 0 0;
  padding: 0;
  counter-reset: reading-list-counter;
}

.reading-list-card-listing a, .reading-list-card-listing a:focus, .reading-list-card-listing a:hover, .reading-list-card-listing a:active {
  color: #fff;
}

.reading-list-card-listing li {
  position: relative;
  counter-increment: reading-list-counter;
  padding-left: 25px;
  margin-bottom: 20px;
}

.reading-list-card-listing li:last-child {
  margin-bottom: 0;
}

.reading-list-card-listing li.is-current {
  font-weight: 600;
}

.reading-list-card-listing li::before {
  position: absolute;
  left: 0;
  content: counter(reading-list-counter) ".";
}

.reading-list .tns-ovh {
  padding-bottom: 9px;
  padding-right: 9px;
  overflow-y: visible;
  box-shadow: 0 0 44px rgba(95, 95, 95, 0.57);
  background: rgba(0, 0, 0, 0.166);
  border-radius: 8px 20px;
}

.reading-list .tns-controls {
  text-align: center;
  margin: 35px -5px 0;
  outline: 0;
}

.reading-list [data-controls] {
  background: #FFFFFF;
  border-radius: 8px;
  color: #595858;
  box-shadow: none;
  padding: 11px 20px;
  line-height: 0;
  margin: 0 5px;
}

.reading-list [data-controls]:disabled {
  cursor: default;
}

.faq {
  margin-left: 85px;
}

@media only screen and (max-width: 1023px) {
  .faq {
    margin-left: 40px;
  }
}

.faq-heading {
  margin-bottom: 50px;
}

.faq-list {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 100px;
}

@media only screen and (max-width: 1023px) {
  .faq-list {
    margin-bottom: 50px;
  }
}

.faq-list-item {
  margin-bottom: 50px;
}

.faq-list-item:last-child {
  margin-bottom: 0;
}

button.faq-trigger {
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  text-transform: none;
  color: inherit;
  font-size: inherit;
  font-weight: normal;
  white-space: initial;
  border-radius: 0;
  text-align: left;
  outline: 0;
}

.faq-trigger {
  position: relative;
  display: block;
  margin: 0;
  font-weight: 600;
  font-family: acumin-pro-condensed, acumin-pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.faq-trigger[data-accordion-trigger] {
  margin-left: -85px;
  padding-left: 85px;
  cursor: pointer;
}

@media only screen and (max-width: 1023px) {
  .faq-trigger[data-accordion-trigger] {
    margin-left: -40px;
    padding-left: 40px;
  }
}

.faq-trigger[data-accordion-trigger]::after {
  position: absolute;
  left: 0;
  top: 12px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  pointer-events: none;
  border-color: rgba(45, 114, 247, 0);
  border-left-color: #2D72F7;
  border-width: 10px;
  margin-top: -6px;
  transition: .3s ease-in-out all;
  transform-origin: 4px 5px;
  border-top-width: 6px;
  border-bottom-width: 6px;
}

.faq-trigger[data-accordion-trigger][aria-expanded="true"]::after {
  transform: rotateZ(90deg);
}

.faq-panel {
  color: #767676;
  margin-top: 5px;
  transition: .3s ease-in-out all;
}

.faq-panel > *:first-child {
  margin-top: 0;
}

.faq-panel > *:last-child {
  margin-bottom: 0;
}

.faq > *:last-child {
  margin-bottom: 0;
}

.faq.has-no-padding {
  margin-left: 0;
}

@media only screen and (max-width: 1023px) {
  .faq.has-no-padding {
    margin-left: 0;
  }
}

.faq-categories {
  background: #F5F5F5;
  padding: 45px 0;
}

@media screen and (min-width: 1024px) {
  .faq-categories {
    padding: 60px 0;
  }
}

.faq-categories-container {
  margin-top: -10px;
}

.faq-categories-title {
  letter-spacing: -2px;
  color: #292929;
  margin-top: 0;
  margin-bottom: 45px;
  text-align: center;
}

@media screen and (min-width: 1024px) {
  .faq-categories-title {
    margin-bottom: 60px;
  }
}

.faq-categories-list {
  list-style: none;
  margin: 0;
  padding: 0;
  transform: translate3d(0%, 0px, 0px);
}

@media screen and (min-width: 768px) {
  .faq-categories {
    padding-bottom: 100px;
  }
  .faq-categories .col:not(.tns-item) {
    width: 25%;
    float: left;
    padding-right: 20px;
  }
  .faq-categories .col:not(.tns-item):nth-child(n+5) {
    margin-top: 20px;
  }
}

.faq-categories-card {
  background: #fff;
  color: #292929;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  padding: 45px 30px;
  position: relative;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1) transform;
  -webkit-font-smoothing: subpixel-antialiased;
  backface-visibility: hidden;
  margin: 15px 15px 30px;
}

@media screen and (min-width: 1024px) {
  .faq-categories-card {
    margin: 0;
  }
  .faq-categories-card:hover, .faq-categories-card:focus {
    transform: scale3d(1.048, 1.048, 1) translateZ(0);
  }
  .faq-categories-card:hover .info-card-actions, .faq-categories-card:focus .info-card-actions {
    opacity: 1;
  }
}

.faq-categories-card:hover, .faq-categories-card:focus, .faq-categories-card:active, .faq-categories-card:visited {
  text-decoration: none;
  color: #292929;
}

.faq-categories-card .info-card-title {
  color: #292929;
}

.faq-categories-card .info-card-content {
  padding: 0;
  position: relative;
}

.faq-categories-card .info-card-content:before {
  content: '';
  position: absolute;
  width: 46px;
  margin-left: -23px;
  height: 0px;
  left: 50%;
  top: -15px;
  border: 1px solid #CFCFCF;
}

.faq-categories-card .info-card-actions {
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1) opacity;
  opacity: 0;
  font-size: 15px;
  color: #2D72F7;
  font-weight: 600;
}

.faq-categories-card-listing {
  list-style: none;
  margin: 25px 0 0;
  padding: 0;
  counter-reset: reading-list-counter;
}

.faq-categories-card-listing a, .faq-categories-card-listing a:focus, .faq-categories-card-listing a:hover, .faq-categories-card-listing a:active {
  color: #fff;
}

.faq-categories-card-listing li {
  position: relative;
  counter-increment: reading-list-counter;
  padding-left: 25px;
  margin-bottom: 20px;
}

.faq-categories-card-listing li:last-child {
  margin-bottom: 0;
}

.faq-categories-card-listing li.is-current {
  font-weight: 600;
}

.faq-categories-card-listing li::before {
  position: absolute;
  left: 0;
  content: counter(reading-list-counter) ".";
}

.faq-categories .tns-controls {
  text-align: center;
  margin: 0;
  outline: 0;
}

.faq-categories [data-controls] {
  background: #FFFFFF;
  border-radius: 8px;
  color: #595858;
  box-shadow: none;
  padding: 11px 20px;
  line-height: 0;
  margin: 0 5px;
}

.faq-categories [data-controls]:disabled {
  cursor: default;
}

.popular-faqs {
  margin-top: -25px;
}

@media screen and (min-width: 1024px) {
  .popular-faqs {
    margin-top: -45px;
  }
}

.popular-faqs-heading {
  margin-top: 0;
  margin-bottom: 45px;
}

@media screen and (min-width: 1024px) {
  .popular-faqs-heading {
    margin-bottom: 60px;
  }
}

.popular-faqs .faq-listing {
  margin: 0;
}

.faq-autocomplete {
  position: relative;
  display: flex;
  align-items: center;
}

.faq-autocomplete-form {
  position: relative;
}

.faq-autocomplete-submit {
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  text-transform: none;
  color: inherit;
  font-size: inherit;
  font-weight: normal;
  white-space: initial;
  border-radius: 0;
  text-align: left;
  position: absolute;
  right: 0;
  top: 22px;
}

.faq-autocomplete-submit:after {
  content: '';
  position: absolute;
  right: 25px;
  width: 38px;
  height: 36px;
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2238%22%20height%3D%2236%22%20viewBox%3D%220%200%2038%2036%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M24.3503%2022.9495L36%2034M27.75%2015.2121C27.75%2021.9567%2022.2095%2027.4242%2015.375%2027.4242C8.54048%2027.4242%203%2021.9567%203%2015.2121C3%208.46755%208.54048%203%2015.375%203C22.2095%203%2027.75%208.46755%2027.75%2015.2121Z%22%20stroke%3D%22%238F8F8F%22%20stroke-width%3D%225%22%2F%3E%3C%2Fsvg%3E");
}

.faq-autocomplete-submit.is-loading:after {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2238%22%20height%3D%2238%22%20viewBox%3D%220%200%2038%2038%22%20stroke%3D%22%232D72F7%22%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cg%20transform%3D%22translate%281%201%29%22%20stroke-width%3D%224%22%3E%3Ccircle%20stroke-opacity%3D%22.5%22%20cx%3D%2218%22%20cy%3D%2218%22%20r%3D%2216%22%2F%3E%3Cpath%20d%3D%22M34%2018c0-9.94-8.06-16-18-16%22%3E%3CanimateTransform%20attributeName%3D%22transform%22%20type%3D%22rotate%22%20from%3D%220%2018%2018%22%20to%3D%22360%2018%2018%22%20dur%3D%221s%22%20repeatCount%3D%22indefinite%22%2F%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

.faq-autocomplete-input {
  border: none;
  margin: 0px;
  height: 80px;
  width: 100%;
  border-radius: 15px;
  font-size: 24px;
  padding: 25px 65px 25px 25px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.autocomplete {
  position: absolute;
  transition: all 0.5s ease 0s;
  max-height: 0;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  border-radius: 10px;
  opacity: 0;
  margin-top: 2px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-align: left;
}

.autocomplete:active, .autocomplete:focus, .autocomplete:hover {
  background-color: #fafafa;
  transition: all 0.5s ease 0s;
}

.autocomplete:empty {
  display: none;
}

.autocomplete > ul {
  list-style-type: none;
  margin: 0;
  padding: 10px;
  color: #292929;
}

.autocomplete > ul a,
.autocomplete > ul a:hover,
.autocomplete > ul a:focus {
  color: #292929;
}

.autocomplete > ul a {
  display: block;
  line-height: 1.35;
}

.autocomplete > ul > li {
  cursor: pointer;
  padding: 12px 15px;
  font-size: 20px;
  border-radius: 10px;
}

.autocomplete > ul > li.active, .autocomplete > ul > li:active, .autocomplete > ul > li:focus, .autocomplete > ul > li:hover {
  background-color: #eeeeee;
  transition: all 0.5s ease 0s;
}

.autocomplete > ul > li.active a:active,
.autocomplete > ul > li:active a:active,
.autocomplete > ul > li:focus a:active,
.autocomplete > ul > li:hover a:active,
.autocomplete > ul > li.active a:focus,
.autocomplete > ul > li:active a:focus,
.autocomplete > ul > li:focus a:focus,
.autocomplete > ul > li:hover a:focus,
.autocomplete > ul > li.active a:hover,
.autocomplete > ul > li:active a:hover,
.autocomplete > ul > li:focus a:hover,
.autocomplete > ul > li:hover a:hover {
  text-decoration: none;
}

.autocomplete > ul > li.locked {
  cursor: inherit;
}

.autocomplete > ul > li span {
  display: block;
  text-transform: uppercase;
  font-size: 14px;
  color: #8F8F8F;
  margin-top: 4px;
}

.autocomplete.open {
  display: block;
  transition: all 0.5s ease 0s;
  background-color: #fafafa;
  max-height: 1000px;
  overflow: hidden;
  transition-duration: 0.5s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  z-index: 100;
  opacity: 1;
}

.autocomplete.open:empty {
  display: none;
}

.autocomplete-show-all {
  position: relative;
  margin-top: 6px;
  padding: 0 !important;
}

.autocomplete-show-all button {
  background: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  text-transform: none;
  color: inherit;
  font-size: inherit;
  font-weight: normal;
  white-space: initial;
  border-radius: 0;
  text-align: left;
  font-family: acumin-pro, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  width: 100%;
  padding: 12px 15px;
}

.autocomplete-show-all:before {
  content: '';
  border-top: 1px solid #ccc;
  position: absolute;
  top: -3px;
  left: 15px;
  right: 15px;
}

.autocomplete-show-all:after {
  content: '';
  position: absolute;
  width: 41px;
  height: 33px;
  right: 15px;
  top: 11px;
  pointer-events: none;
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2241%22%20height%3D%2233%22%20viewBox%3D%220%200%2041%2033%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M0.5%2016H38.3536M38.3536%2016L23.6464%202M38.3536%2016L23.3536%2031%22%20stroke%3D%22%23B2B2B2%22%20stroke-width%3D%223%22%2F%3E%3C%2Fsvg%3E");
}

.faq-listing {
  margin-top: 60px;
}

@media screen and (min-width: 1024px) {
  .faq-listing {
    margin-top: 80px;
  }
}

.faq-listing-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 1024px) {
  .faq-listing-list.has-columns {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    margin: 0 -50px;
    flex-wrap: wrap;
  }
  .faq-listing-list.has-columns > [class*="is-"] {
    padding: 0 50px;
    min-width: 0;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%;
    max-width: 100%;
  }
  .faq-listing-list.has-columns > [class*="is-"]:last-child {
    margin-bottom: 0;
  }
  .faq-listing-list.has-columns .is-full {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%;
    max-width: 100%;
  }
  .faq-listing-list.has-columns .is-1\/2 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    max-width: 50%;
  }
  .faq-listing-list.has-columns .is-1\/3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }
  .faq-listing-list.has-columns .is-2\/3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 66.6666%;
    max-width: 66.6666%;
  }
  .faq-listing-list.has-columns .is-1\/4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 25%;
    max-width: 25%;
  }
  .faq-listing-list.has-columns .is-3\/4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 75%;
    max-width: 75%;
  }
  .faq-listing-list.has-columns .is-1\/6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 16.66666%;
    max-width: 16.66666%;
  }
  .faq-listing-list.has-columns .is-5\/6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 83.3333%;
    max-width: 83.3333%;
  }
  .faq-listing-list.has-columns .is-1\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 20%;
    max-width: 20%;
  }
  .faq-listing-list.has-columns .is-2\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 40%;
    max-width: 40%;
  }
  .faq-listing-list.has-columns .is-3\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 60%;
    max-width: 60%;
  }
  .faq-listing-list.has-columns .is-4\/5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 80%;
    max-width: 80%;
  }
}

@media screen and (min-width: 1024px) {
  .faq-listing-list.has-columns .faq-listing-list-item:after {
    right: 50px;
  }
}

.faq-listing-heading {
  margin: 0 0 60px;
}

@media screen and (min-width: 1024px) {
  .faq-listing-heading {
    margin-bottom: 70px;
    margin-top: -30px;
  }
}

.faq-listing-list-item {
  display: flex;
  align-items: center;
  position: relative;
}

.faq-listing-card {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 35px 0;
}

.faq-listing-card:before, .faq-listing-card:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  border-top: 1px solid #CFCFCF;
}

.faq-listing-card:after {
  top: auto;
  bottom: -1px;
}

@media screen and (min-width: 1024px) {
  .faq-listing-card {
    height: 100%;
    width: 100%;
  }
}

.faq-listing-card a {
  text-decoration: none;
  flex: 1;
}

.faq-listing-card a + ul {
  margin-top: 10px;
}

.faq-listing-card-title {
  letter-spacing: -.05px;
  color: #292929;
  margin: 0;
  transition: .2s ease-in-out color;
  font-family: acumin-pro-condensed, acumin-pro, 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.faq-listing-card-title:hover, .faq-listing-card-title:focus {
  color: #2D72F7;
}

.experience-editor {
  padding-top: 0;
}

.experience-editor .site-header {
  position: relative;
}

.experience-editor article.about-sunnova .content h2 {
  -webkit-text-fill-color: inherit;
}

.experience-editor article.text h1 {
  -webkit-text-fill-color: inherit;
}

.experience-editor article.news-press h3 {
  -webkit-text-fill-color: inherit;
}

.experience-editor .page-intro-heading {
  -webkit-text-fill-color: inherit;
}

.experience-editor .state-listing .title {
  -webkit-text-fill-color: inherit;
}

.experience-editor article.testimonial .title h1 {
  -webkit-text-fill-color: inherit;
}

.experience-editor .image-placeholder > * {
  position: relative;
  width: auto;
  height: auto;
}

.experience-editor .fullwidth-callout-image {
  z-index: 1;
}
