.blog-featured {
    &-heading {
        margin-top: 0;

        margin-bottom: 35px;

        @include bp(sm) {
            margin-bottom: 55px;
        }
    }

    &-cards {
        margin-bottom: -30px;

        .flex-grid > * {
            & > * {
                margin-bottom: 30px;
            }

            @include bp(sm) {
                display: flex;

                .info-card {
                    flex: 1;
                }
            }
        }

    }

    &.is-large {
        margin-top: -25px;

        @include bp(sm) {
            margin-top: -45px;
        }

        .blog-featured-cards {
            .flex-grid > * {
                & > * {
                    margin-bottom: 30px;
    
                    @include bp(sm) {
                        margin-bottom: 55px;
                    }
                }
            }

            .info-card-content {
                @include bp(sm) {
                    min-height: 350px;
                    padding: 60px 100px;
                }
            }
        }
    }
}