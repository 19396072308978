$column_gap: 20px !default; 
$classPrefix: 'is-' !default;
$rowName: '.row' !default;

@mixin flex-grid($classPrefix: $classPrefix, $gutter: $column_gap, $rowName: $rowName, $isSelf: false, $includeBreakpoints: true) {
    @include layout-list();

    @if($isSelf) {
        display: flex;

        @if($gutter != 0 and $gutter != 0px) {
            margin: 0 ($gutter / 2) * -1;
        }

        flex-wrap: wrap;
    } @else {
        #{$rowName} {
            display: flex;

            @if($gutter != 0 and $gutter != 0px) {
                margin: 0 ($gutter / 2) * -1;
            }

            flex-wrap: wrap;
        }
    }

    @include flex-grid-breakpoints($includeBreakpoints: $includeBreakpoints, $classPrefix: $classPrefix, $gutter: $gutter);
}

@mixin flex-grid-columns($classPrefix, $gutter) {
    @each $name, $percentage in $flex-columns {
        .#{"#{$classPrefix}#{$name}"} {
            @include flex-grid-column($percentage: $percentage, $gutter: $gutter);
        }
    }
}


@mixin flex-grid-column($percentage, $gutter: $column_gap) {
    flex-grow: 0;
    flex-shrink: 0;

    @if($gutter == 0 or $gutter == 0px) {
        flex-basis: $percentage;
        max-width: $percentage;
    } @else {
        flex-basis: $percentage;
        max-width: $percentage;
    }
}

@mixin flex-grid-item($gutter: $column_gap) {

    @if($gutter != 0 and $gutter != 0px) {
        padding: 0 ($gutter / 2);
    }

    min-width: 0; // fixes a weird bug where overflowing content breaks flex-wrap
    flex-grow: 0;
    flex-shrink: 0;

    @if($gutter == 0 or $gutter == 0px) {
        flex-basis: 100%;
    } @else {
        flex-basis: 100%;
        max-width: 100%;
    }

    &:last-child {
        margin-bottom: 0;
    }
}


@mixin flex-grid-breakpoints($includeBreakpoints, $classPrefix: $classPrefix, $gutter: $column_gap) {
    & > [class*="#{$classPrefix}"] {
        @include flex-grid-item($gutter: $gutter);
    }

    @include flex-grid-columns($classPrefix: $classPrefix, $gutter: $gutter);

    @if($includeBreakpoints) {
        @each $name, $resolution in $breakpoints {
            @include bp($name) {
                @include flex-grid-columns($classPrefix: "#{$name}\\:is-", $gutter: $gutter);
            }
        }
    }
}

// Create a default grid and set a margin bottom for mobile
.flex-grid {
    @include flex-grid($isSelf: true);

    @include breakpoint(small-medium) {
        & > [class*="is-"] {
            margin-bottom: 30px;
        }
    }
}