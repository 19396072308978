// Sitecore Experience Editor Styles
.experience-editor {
    padding-top: 0;
    .site-header {
        position: relative;
    }
    article.about-sunnova {
        .content {
            h2 {
                -webkit-text-fill-color: inherit;
            }
        }
    }
    article.text {
        h1 {
           -webkit-text-fill-color: inherit;
        }
    }
    article.news-press {
        h3 {
           -webkit-text-fill-color: inherit;
        }
    }
    .page-intro {
        &-heading {
            -webkit-text-fill-color: inherit;
        }
    }
    .state-listing {
        .title {
            -webkit-text-fill-color: inherit;
        }
    }
    article.testimonial {
        .title {
            h1 {
                -webkit-text-fill-color: inherit;
            }
        }
    }
    .image-placeholder {
        & > * {
            position: relative;
            width: auto;
            height: auto;
        }
    }
    .fullwidth-callout {
        &-image {
            z-index: 1;
        }
    }
}