.faq {
    $this: &;

    margin-left: 85px;

    @include breakpoint(small-medium) {
        margin-left: 40px;
    }

    &-heading {
        margin-bottom: 50px;
    }

    &-list {
        @include layout-list();
        margin-bottom: 100px;

        @include breakpoint(small-medium) {
            margin-bottom: 50px;
        }
    }

    &-list-item {
        margin-bottom: 50px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @at-root {
        button#{$this}-trigger {
            @include button-reset();
            outline: 0;
        }
    }

    &-trigger {
        position: relative;
        display: block;
        margin: 0;
        font-weight: 600;
        font-family: acumin-pro-condensed, acumin-pro, "Helvetica Neue", Helvetica, Arial, sans-serif;

        &[data-accordion-trigger] {
            margin-left: -85px;
            padding-left: 85px;
            cursor: pointer;

            @include breakpoint(small-medium) {
                margin-left: -40px;
                padding-left: 40px;
            }

            &::after {
                position: absolute;
                left: 0;
                top: 12px;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                pointer-events: none;
                border-color: rgba(45, 114, 247, 0);
                border-left-color: #2D72F7;
                border-width: 10px;
                margin-top: -6px;
                transition: .3s ease-in-out all;
                transform-origin: 4px 5px;
                border-top-width: 6px;
                border-bottom-width: 6px;
            }

            &[aria-expanded="true"] {
                &::after {
                    transform: rotateZ(90deg);
                }
            }
        }
    }

    &-panel {
        color: map-get($colors, smoke);
        margin-top: 5px;
        transition: .3s ease-in-out all;

        & > *:first-child {
            margin-top: 0;
        }

        & > *:last-child {
            margin-bottom: 0;
        }
    }

    & > *:last-child {
        margin-bottom: 0;
    }

    &.has-no-padding {
        margin-left: 0;

        @include breakpoint(small-medium) {
            margin-left: 0;
        }
    
    }
}

.faq-categories {
    background: #F5F5F5;
    padding: 45px 0;

    @include bp(md) {
        padding: 60px 0;
    }

    &-container {
        margin-top: -10px;
    }

    &-title {
        letter-spacing: -2px;
        color:  map-get($colors, black);
        margin-top: 0;
        margin-bottom: 45px;
        text-align: center;

        @include bp(md) {
            margin-bottom: 60px;
        }
    }

    &-list {
        @include layout-list();
        transform: translate3d(0%, 0px, 0px);
    }

    @include bp(sm) {
        padding-bottom: 100px;

        .col:not(.tns-item) {
            width: 25%;
            float: left;
            padding-right: 20px;

            &:nth-child(n+5) {
                margin-top: 20px;
            }
        }
    }

    &-card {
        background: #fff;
        color: map-get($colors, black);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
        border-radius: 8px;
        padding: 45px 30px;
        position: relative;
        min-height: 350px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-decoration: none;
        transition: .3s map-get($map: $easings, $key: swift) transform;
        -webkit-font-smoothing: subpixel-antialiased;
        backface-visibility: hidden;
        margin: 15px 15px 30px;

        @include bp(md) {
            margin: 0;

            &:hover, &:focus {
                transform: scale3d(1.048, 1.048, 1) translateZ(0);

                .info-card-actions {
                    opacity: 1;
                }
            }
        }

        &:hover, &:focus, &:active, &:visited {
            text-decoration: none;
            color: map-get($colors, black)
        }

        .info-card-title {
            color: map-get($colors, black);
        }

        .info-card-content {
            padding: 0;
            position: relative;

            &:before {
                content: '';
                position: absolute;
                width: 46px;
                margin-left: -23px;
                height: 0px;
                left: 50%;
                top: -15px;
                border: 1px solid #CFCFCF;
            }
        }

        .info-card-actions {
            transition: .3s map-get($easings, swift) opacity;
            opacity: 0;
            font-size: 15px;
            color: #2D72F7;
            font-weight: 600;
        }
    }

    &-card-listing {
        list-style: none;
        margin: 25px 0 0;
        padding: 0;
        counter-reset: reading-list-counter;

        a, a:focus, a:hover, a:active {
            color: #fff;
        }

        li {
            position: relative;
            counter-increment: reading-list-counter;
            padding-left: 25px;
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }

            &.is-current {
                font-weight: 600;
            }
        }

        li::before {
            position: absolute;
            left: 0;
            content: counter(reading-list-counter) ".";
        }
    }

    .tns-controls {
        text-align: center;
        margin: 0;
        outline: 0;
    }

    [data-controls] {
        background: #FFFFFF;
        border-radius: 8px;
        color: #595858;
        box-shadow: none;
        padding: 11px 20px;
        line-height: 0;
        margin: 0 5px;
    }

    [data-controls]:disabled {
        cursor: default
    }
}

.popular-faqs {
    margin-top: -25px;

    @include bp(md) {
        margin-top: -45px;
    }

    &-heading {
        margin-top: 0;
        margin-bottom: 45px;

        @include bp(md) {
            margin-bottom: 60px;
        }
    }

    .faq-listing {
        margin: 0;
    }
}