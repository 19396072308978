.infographic {   
    /*margin: 75px 0;
    @include breakpoint(small) {
      margin: 35px 0;
    }*/

    &-tabs {
        text-align: center;
        margin-top: -25px;

        @include breakpoint(small) {
            margin-bottom: 40px;
        }

        [role="tablist"] {
            position: relative;
            overflow: visible;
            z-index: 1;
        }
        
        [role="tab"] {
            position: relative;
            margin: 0;
            padding: 25px;
            background: transparent;
            box-shadow: none;
            border-radius: 0;
            font-weight: bold;
            text-transform: none;
            color: inherit;
            transition: .2s ease-in-out all;
            cursor: pointer;
    
            @include breakpoint(small) {
                padding: 25px 10px;
            }
    
            &:last-child() {
                &::after {
                    display: none;
                }
            }
        }
          
        [role="tab"]::after {
            position: absolute;
            bottom: 25px;
            top: 25px;
            width: 1px;
            right: -2px;
            border-right: 2px solid #C4C4C4;
            content: '';
        }
        
        [role="tab"][aria-selected="true"] {
            @include gradient-text(golden-hour);
        }
        
        [role="tab"]:hover,
        [role="tab"]:focus,
        [role="tab"]:active {
            outline: 0;
            border-radius: 0;
        }
        
        [role="tabpanel"] {
            // position: relative;
            // z-index: 2;
            padding: .5em .5em .7em;      
        }
        
        [role="tabpanel"]:focus {
            outline: 0;
        }
        
        [role="tabpanel"] p {
            margin: 0;
        }
        
        [role="tabpanel"] * + p {
            margin-top: 1em;
        }
    }

    &-wrapper {
        position: relative;
        //padding-top: 60px;
    }

    &-content {
        position: relative;
        z-index: 1;
        text-align: center;
        margin-top: 75px;

        @include breakpoint(small) {
            margin-top: 35px;
        }
    }

    .wrapper-md {
        position: relative;
    }

    &-background, 
    .mask-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &-background {
        background: linear-gradient(180deg, #FFFFFF 43.17%, #90C5FF 100%);

        &.is-night {
            background: linear-gradient(180deg, #1C1C1C 53.17%, #2B276B 84.99%);
        }
    }

    &-toggle {
        margin-bottom: 60px;
    }

    [role="tabpanel"] {
        [data-toggle-transition] .infographic-background {
            transition: .3s ease-in-out all;
        }

        &.day {
            [data-toggle-transition="night"] .infographic-background {
                opacity: 0;
            }

            [data-toggle-transition="day"] .infographic-background {
                opacity: 1;
            }
        }

        &.night {
            color: #fff;

            [data-toggle-transition="night"] .infographic-background {
                opacity: 1;
            }

            [data-toggle-transition="day"] .infographic-background {
                opacity: 0;
            }
        }
    }

    [role="tablist"] {
        &.night {
            color: #fff;
        }
    }

    .custom-toggle {
        display: inline-flex;
        align-items: center;
    }
    
    .custom-toggle__label {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        font-size: 16px;
        min-width: 120px;
    
        @include breakpoint(small) {
            min-width: 90px;
        }
        
        img {
            margin-bottom: 8px;
        }
    }
    
    .is-off:checked ~ .custom-toggle__switcher {
        background-color: #fff;
    }
    
    .is-off:checked ~ .custom-toggle__background {
        background-color: #575757;
    }
    
    .is-on:checked ~ .custom-toggle__switcher {
        background-color: #16481a;
    }
    
    .is-on:checked ~ .custom-toggle__background {
        background-color: #E8E8E8;
    }    
}
