.setup-steps {
    @include breakpoint(large) {
        margin: 100px 0;
    }

    &-header {
        text-align: center;
        margin-bottom: 60px;

        @include breakpoint(small) {
            margin-bottom: 30px;
        }
    }

    ol {
        @include layout-list();
        list-style: none;
        counter-reset: setup-steps-counter;

        & > li {
            position: relative;
            counter-increment: setup-steps-counter;
            margin-bottom: 40px;
            margin-left: 20%;

            @include breakpoint(small) {
                margin-bottom: 20px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        & > li::before {
            content: counter(setup-steps-counter);
            @include gradient-text(golden-hour);
            font-weight: bold;
            font-size: 46px;
            position: absolute;
            left: -10%;
            top: -12px;
            @include breakpoint(small) {
                left: -56px;
            }
        }
    }
}