.reading-list {
    background: #F5F5F5;
    padding: 60px 0;

    &-title {
        letter-spacing: -2px;
        color:  map-get($colors, black);
        margin-bottom: 50px;
    }

    &-list {
        @include layout-list();
        margin: 0;
        padding: 0;
        transform: translate3d(0%, 0px, 0px);
    }

    @include bp(sm) {
        .col:not(.tns-item) {
            width: 25%;
            float: left;
            padding-right: 20px;

            &:nth-child(n+5) {
                display: none;
            }
        }
    }

    &-card {
        background: linear-gradient(230.21deg, #33CCFF 7.99%, #333399 100%);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
        border-radius: 8px;
        color: #fff;
        padding: 30px;
        text-align: left;
        position: relative;
        min-height: 350px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-decoration: none;

        &:hover, &:focus, &:active, &:visited {
            text-decoration: none;
            color: #fff;
        }

        .info-card-header {
            img {
                display: block;
                margin: 0 auto;
            }
        }

        .info-card-title {
            color: #fff;
            text-align: center;
            margin-top: 15px;
        }

        .info-card-description {
            padding: 0;
            margin-bottom: 10px;
        }

        .info-card-content {
            display: flex;
            justify-content: center;
        }
        .info-card-meta {
            background-color: white;
            padding: 10px 30px;
            border: 0;
            border-radius: 15px;
        }

        .info-card-meta-count {
            color: black;
        }
        
        &:after {
            content: '';
            position: absolute;
            top: 9px;
            left: 9px;
            bottom: -9px;
            right: -9px;
            z-index: -1;
            background: linear-gradient(230.21deg, #33CCFF 7.99%, #333399 100%);
            border-radius: 8px;
        }
    }

    &-card-listing {
        list-style: none;
        margin: 25px 0 0;
        padding: 0;
        counter-reset: reading-list-counter;

        a, a:focus, a:hover, a:active {
            color: #fff;
        }

        li {
            position: relative;
            counter-increment: reading-list-counter;
            padding-left: 25px;
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }

            &.is-current {
                font-weight: 600;
            }
        }

        li::before {
            position: absolute;
            left: 0;
            content: counter(reading-list-counter) ".";
        }
    }

    .tns-ovh {
        padding-bottom: 9px;
        padding-right: 9px;
        overflow-y: visible;
        box-shadow: 0 0 44px rgba(95, 95, 95, 0.57);
        background: rgba(0, 0, 0, 0.166);
        border-radius: 8px 20px;
    }

    .tns-controls {
        text-align: center;
        margin: 35px -5px 0;
        outline: 0;
    }

    [data-controls] {
        background: #FFFFFF;
        border-radius: 8px;
        color: #595858;
        box-shadow: none;
        padding: 11px 20px;
        line-height: 0;
        margin: 0 5px;
    }

    [data-controls]:disabled {
        cursor: default
    }
}