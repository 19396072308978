/* http://www.fontspring.com/blog/further-hardening-of-the-bulletproof-syntax */

html {
    font-family: $sans;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button,
input,
select,
textarea {
    font-family: $sans;
    font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $sans;
    font-weight: 700;
    margin: 10px 0;
    line-height: 1.25;
}

h1 {
    font-size: 48px;
    line-height: 1.15;
    letter-spacing: -0.04em;
}

h2 {
    font-size: 36px;
    line-height: 1.25;
}

h3 {
    font-size: 28px;
    line-height: 1.25;
}

h4 {
    font-size: 16px;
}

h5 {
    font-size: 16px;
}

h6 {
    font-size: 16px;
}

p {
    font-size: inherit;
    line-height: 1.5;
}

@include breakpoint(medium) {
    h1 {
        font-size: 40px;
        line-height: 1.125em;
    }

    h2 {
        font-size: 32px;
        line-height: 1.25em;
    }

    h3 {
        font-size: 24px;
        line-height: 1.25em;
    }

    h4 {
        font-size: 21px;
        letter-spacing: 0;
    }

    h5 {
        font-size: 16px;
        letter-spacing: 0;
    }

    h6 {
        font-size: 16px;
        letter-spacing: 8px;
    }

    p {
        font-size: 16px;
        line-height: 1.375em;
    }
}

@include breakpoint(small) {
    h1 {
        font-size: 32px;
        line-height: 1.25em;
    }

    h2 {
        font-size: 26px;
        line-height: 1.15384615em;
    }

    h3 {
        font-size: 22px;
        line-height: 1.13636364em;
    }

    h4 {
        font-size: 21px;
        letter-spacing: 0;
    }

    h5 {
        font-size: 16px;
        letter-spacing: 0;
    }

    h6 {
        font-size: 16px;
        letter-spacing: 8px;
    }

    p {
        line-height: 1.4;
    }
}

ul,
ol {
    ul,
    ol {
        margin: 0;
    }
}

@include text-sizes();

.sr-only {
    @include sr-only();
}
