$inputs-list: 'input[type="text"]', 'input[type="password"]', 'input[type="search"]', 'input[type="number"]',
    'input[type="datetime"]', 'input[type="datetime-local"]', 'input[type="date"]', 'input[type="month"]',
    'input[type="time"]', 'input[type="week"]', 'input[type="email"]', 'input[type="url"]', 'input[type="tel"]',
    'input[type="color"]';
$input-selectors: ();

@each $item in $inputs-list {
    $input-selectors: join($input-selectors, unquote($item), comma) !global;
}

#{$input-selectors},
textarea,
select {
    transition: border linear 0.2s, box-shadow linear 0.2s;
    display: inline-block;
    width: 250px;
    @include breakpoint(small) {
        width: 100%;
    }
    padding: 10px 15px;
    border: 1px solid #eaeaea;
    border-radius: 16px;
    background-color: #fff;
    vertical-align: middle;
    // Webkit
    -webkit-appearance: none;
    &:focus {
        border-color: #acacac;
        outline: none;
    }
}

fieldset {
    border: none;
}

input[type='search'] {
    padding: 0 25px 0 5px;
    //background: #fff image-url("icon/search.png") 98% 50% no-repeat;
}

textarea {
    width: 100%;
    height: 200px;
}

select {
    position: relative;
    padding-right: 50px;
    border-radius: 0;
    //background: #fff url("../images/select-arrow.png") no-repeat 100% 65%;
    // Mozilla
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';

    // Webkit
    -webkit-appearance: none;
}
// IE
select::-ms-expand {
    display: none;
}
.lt-ie10 select {
    padding: 0 0 0 5px;
    background: white;
}

button,
a.button {
    display: inline-block;
    height: auto;
    width: auto;
    padding: 15px 50px;
    border: 0;
    border-radius: 4px;
    background: getGradient(golden-hour);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
    color: #fff;
    font-family: acumin-pro-condensed, acumin-pro, 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;

    &.secondary {
        background: getGradient(aurora);
    }

    @each $name, $bg in $gradients {
        &.#{$name} {
            background: $bg;
        }
    }

    &:disabled {
        opacity: 0.5;
    }
}

[role='button'] {
    cursor: pointer;
}

.loadingSpinner {
    border: 4px solid #fff;
    border-top-color: rgba(0, 0, 0, 0);
    border-left-color: rgba(0, 0, 0, 0);
    position: absolute;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    opacity: 1;
    border-radius: 50%;
    animation: loadingSpinner .7s infinite linear;
    -webkit-animation: loadingSpinner .7s infinite linear;
}

@keyframes loadingSpinner {
    from { transform: rotate(0deg)   }
    to   { transform: rotate(360deg) }
}

@-webkit-keyframes loadingSpinner {
    from { -webkit-transform: rotate(0deg)   }
    to   { -webkit-transform: rotate(360deg) }
}

form,
.form {
    div.validationSummary {
        margin-bottom: 10px;
        color: #be3438;

        ul {
            @include layout-list;
        }
    }

    .item {
        @extend .clearfix;
        margin-bottom: 5px;

        > label {
            display: inline-block;

            span {
                font-weight: normal;
            }
        }

        input {
            vertical-align: baseline;
        }

        > ul {
            @include layout-list;
        }

        &.horizontal {
            > label {
                display: inline-block;
                margin-right: 5px;
            }
        }
    }

    .item.fl-enabled {
        position: relative;

        > label,
        .label {
            position: absolute;
            top: 20px;
            left: 1px;
            padding: 0 15px;
            color: #acacac;
            opacity: 0;
            font-size: 12px;
            line-height: 24px;
            z-index: 2;
        }

        #{$input-selectors},
        textarea,
        select {
            padding-top: 17px;
            padding-bottom: 17px;
            margin-top: 5px;
        }

        &.fl-active {
            #{$input-selectors},
            textarea,
            select {
                padding-top: 24px;
                padding-bottom: 10px;
            }

            > label,
            .label {
                top: 10px;
                opacity: 1;
            }
        }

        &.fl-animate {
            #{$input-selectors},
            textarea,
            select,
            > label,
            .label {
                transition: all 0.25s;
            }
        }
    }

    .group {
        @include breakpoint(medium-large) {
            display: flex;
            margin: 0 -10px;

            .item {
                margin: 10px;
                //display: flex;
                text-align: left;
                flex-direction: column;
                flex-grow: 1;
                flex-shrink: 1;
                flex-basis: 0;
                #{$input-selectors},
                textarea,
                select {
                    width: 100%;
                }

                .group .item {
                    margin: 0 10px;
                }
            }
        }
    }

    .buttons {
        text-align: center;

        button,
        .button {
            margin: 10px 0 0 10px;
            @include breakpoint(small) {
                width: 100%;
                margin: 0 0 10px 0;
            }
        }
    }

    input[type='radio'],
    input[type='checkbox'] {
        margin-bottom: 0;
        margin-right: 5px;
    }

    .radio-list,
    .checkbox-list {
        @include layout-list();

        input,
        label {
            display: inline-block;
        }

        &.inline {
            li {
                display: inline-block;
                margin-right: 10px;
            }
        }
    }

    // specificity needed because jQuery validate adds .error class to both field and error message
    span.error {
        text-align: left;
        float: left;
        color: map-get($colors, white);
        font-size: 14px;
        margin-top: 4px;
        line-height: 1.25;
        background: #ef3039;
        padding: 12px;
        border-radius: 10px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        &::before {
            position: absolute;
            top: calc(50% - 14px);
            left: 40px;
            border: solid transparent;
            content: '';
            height: 0;
            width: 0;
            pointer-events: none;
            border-color: rgba(45, 114, 247, 0);
            border-bottom-color: #ef3039;
            border-width: 14px;
        }
    }
}

.custom-checkbox {
    min-height: 30px;

    input[type='checkbox'],
    input[type='radio'] {
        @include sr-only();
    }

    label {
        position: relative;
        display: inline-block;
        padding-left: 32px;
    }

    label::before,
    label::after {
        position: absolute;
        content: '';
        display: inline-block;
    }

    /*Outer box of the fake checkbox*/
    label::before {
        height: 22px;
        width: 22px;
        left: 0px;
        top: 1px;
        border: 2px solid #ececec;
        background: #fff;
        border-radius: 2px;
    }

    input[type='radio'] + label::before {
        border-radius: 50%;
        top: 0;
    }

    /*Checkmark of the fake checkbox*/
    input[type='checkbox'] + label::after {
        height: 5px;
        width: 13px;
        border-left: 3px solid #fff;
        border-bottom: 3px solid #fff;
        transform: rotate(-45deg);
        left: 5px;
        top: 8px;
    }

    input[type='radio'] + label::after {
        height: 16px;
        width: 16px;
        background: #2d72f7;
        border-radius: 50%;
        left: 5px;
        top: 5px;
    }

    /*Hide the checkmark by default*/
    input[type='checkbox'] + label::after,
    input[type='radio'] + label::after {
        content: none;
    }

    input[type='checkbox']:checked + label::before {
        background: #2d72f7;
        border-color: #2d72f7;
    }

    input[type='radio']:checked + label::before {
        background: #fff;
        border-color: #fff;
    }

    /*Unhide on the checked state*/
    input[type='checkbox']:checked + label::after,
    input[type='radio']:checked + label::after {
        content: '';
    }

    /*Adding focus styles on the outer-box of the fake checkbox*/
    input[type='checkbox']:focus + label::before,
    input[type='radio']:focus + label::before {
        outline: rgb(59, 153, 252) auto 5px;
    }
}

/* Custom dropdown */
.custom-dropdown {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 50px;
    background: linear-gradient(192.87deg, #FFFFFF 48.72%, #B3B3B3 141.8%);
    border: 1px solid #CFCFCF;
    box-shadow: 4px 1px 11px rgba(0, 0, 0, 0.13);
    max-width: 100%;

    & > select {
        background: transparent;
        box-sizing: border-box;
        font-weight: 600;
        color:  map-get($colors, black);
        font-size: inherit;
        padding: 30px 35px 12px;
        width: 350px;
        max-width: 100%;
        border: 0;
        margin: 0;
        text-indent: 0.01px;
        text-overflow: '';
        -webkit-appearance: button; /* hide default arrow in chrome OSX */
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        pointer-events: none;
    }

    &::after {
        /*  Custom dropdown arrow */
        content: '';
        width: 18px;
        height: 11px;
        line-height: 1;
        right: 30px;
        top: 50%;
        margin-top: -5.5px;
        background-image: inline-svg('<svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 1L9 9L1 0.999999" stroke="#2D72F7" stroke-width="2"/></svg>');
    }

    & select[disabled] {
        color: rgba(0, 0, 0, 0.3);
    }

    & select[disabled]::after {
        color: rgba(0, 0, 0, 0.1);
    }

    &::after {
        color: rgba(0, 0, 0, 0.4);
    }

    &-label {
        font-size: 14px;
        position: absolute;
        z-index: 1;
        left: 35px;
        font-weight: normal;
        top: 12px;
        pointer-events: none;
    }

    &-redirect {
        .custom-dropdown-label {
            top: 50%;
            font-size: 16px;
            font-weight: 600;
            margin-top: -13px;
        }
    }
}

@include custom-toggle();

.contact-preferences-container {
    display: flex;
    align-items: baseline;
    padding: 25px 0 0;

    .contact-preferences-label {
        font-weight: 600;
    }

    .checkbox-list {
        margin-left: 30px;

        &.contacts {
            li {
                margin-right: 0;
            }
        }

        .custom-checkbox label::before {
            height: 18px;
            width: 18px;
        }

        .custom-checkbox label {
            padding-right: 30px;
        }  

        .custom-checkbox input[type='checkbox'] + label::after {
            left: 4px;
            top: 6px;
        }

        .form input[type='checkbox'] {
            margin-right: 0;
        }
    }
}