.news-press {
    &-heading {
        margin-bottom: 30px;
        font-weight: 400;
    }

    &-videos-container {
        margin-bottom: 50px; 

        .tns-nav {
            text-align: center;
        }

        .tns-controls {
            position: absolute;
        }

        [data-controls], [data-nav] {
            @include button-reset();
        }
        
        [data-nav] {
            display: inline-block;
            width: 15px;
            height: 15px;
            border: 1px solid #ccc;
            border-radius: 50%;
            margin: 2px;

            &.tns-nav-active {
                border: 0;
                background: #FF6B0B;    
            }
        }
    }

    &-videos {
        .video {
            background-color: #f1f1f1;
            position: relative;
            height: 0;
        }
    }

    &-container {
        @include breakpoint(medium-large) {
            position: relative;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 50%;
                border-left: 1px solid #ccc;
            }
        }
    }

    div.news {
        @include breakpoint(medium-large) {
            @include grid-columns(4);
            @include push(1);
        }
        h1,h2,h3,h4,h5,h6 {
            font-size: 24px;
            background: linear-gradient(10deg, #33ccff 10%, #333399 100%);
            color: #fff;
            padding: 10px 20px;
            margin-top: 0;
            @include breakpoint(small) {
                font-size: 28px;
            }
        }
    }

    div.press {
        @include breakpoint(medium-large) {
            @include grid-columns(4);
            @include push(2, true);
        }
        h1,h2,h3,h4,h5,h6 {
            font-size: 24px;
            background: linear-gradient(10deg, #333399 10%, #33ccff 100%);
            color: #fff;
            padding: 10px 20px;
            margin-top: 0;
            @include breakpoint(small) {
                font-size: 28px;
            }
        }
    }

    ul {
        @include layout-list();

        li {
            margin-bottom: 20px;

            div.source {
                font-size: 14px;
                text-transform: uppercase;
            }

            a {
                font-size: 16px;
                line-height: 1.5;
            }

            div.date {
                font-size: 14px;
            }
        }
    }

    .load-more {
        text-align: center;

        @include breakpoint(small) {
            margin-top: 30px;
            margin-bottom: 40px;
        }

        .button {
            margin-top: 40px;
            background: getGradient(aqua);
            color: #fff;
        }
    }

    [data-load-more-list] {
        transition: .3s ease-in-out height;
    }
}