// Usage @include breakpoint(small) { /* Styles here */ }
@mixin breakpoint($point) {
    $small: 767px; // Up to 767px
    $medium: 1023px; // Up to 1024px
    @if $point == large {
        @media only screen and (min-width: $medium+1) {
            @content;
        }
    } @else if $point == medium-large {
        @media only screen and (min-width: $small+1) {
            @content;
        }
    } @else if $point == medium {
        @media only screen and (min-width: $small+1) and (max-width: $medium) {
            @content;
        }
    } @else if $point == small-medium {
        @media only screen and (max-width: $medium) {
            @content;
        }
    } @else if $point == small {
        @media only screen and (max-width: $small) {
            @content;
        }
    }
}

@mixin gradient-text($gradientName, $direction: 45deg) {
    display: inline-block;
    background: linear-gradient(
        $direction,
        map-get(getGradientColor($gradientName), left) 0%,
        map-get(getGradientColor($gradientName), right) 100%
    );
    color: getGradientFallback($gradientName); // Set a fallback color that is 50% between the two numbers
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

// This needs two inline elements in order to work. The first is for a fallback color to help address a Chrome bug and the second is to set the background itself.
// HTML structure inside the element applying the gradient to should be: <span><span>Text Here</span></span>
@mixin gradient-text-background($gradientName, $direction: 45deg) {
    > span {
        padding: 5px 0;
        background: getGradientFallback(golden-hour); // Get the fallback color
        > span {
            display: inline;
            padding: 0 20px 5px;
            margin: 0 -20px;
            line-height: 1.25;
            @include breakpoint(small) {
                line-height: 1.5;
            }
            background: linear-gradient(
                $direction,
                map-get(getGradientColor($gradientName), left) 20%,
                map-get(getGradientColor($gradientName), right) 80%
            );
            color: #fff;
            background-attachment: fixed;
            -webkit-box-decoration-break: clone;
            box-decoration-break: clone;
        }
    }
}

@mixin curved-box($element: article, $top: true, $bottom: true) {
    // Top Rounded
    @if ($top == true) {
        #{$element} {
            mask-image: url(../images/curve-mask.png);
            mask-repeat: no-repeat;
            mask-position: 50% 0;
            mask-size: cover;
        }
    }

    // Bottom Rounded
    @if ($bottom == true) {
        mask-image: url(../images/curve-mask.png);
        mask-repeat: no-repeat;
        mask-position: 50% 100%;
        mask-size: cover;
    }
}

@mixin gradient-underline($gradientName, $height: 2px) {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: $height;
        bottom: 0;
        left: 0;
        background: getGradient($gradientName);
    }
}

@mixin image-replace($imgName, $retina: false) {
    display: inline-block;
    @if $retina == false {
        width: image-width($imgName);
        height: image-height($imgName);
    } @else {
        width: ceil(image-width($imgName) / 2);
        height: ceil(image-height($imgName) / 2);
        background-size: ceil(image-width($imgName) / 2) ceil(image-height($imgName) / 2);
    }
    background-image: image-url($imgName);
    background-repeat: no-repeat;
    text-indent: -50000px;
}

@mixin retina-background($imgName) {
    background-image: image-url('bg/#{$imgName}');

    @if image-exists('bg2x/#{$imgName}') {
        @media (-webkit-min-device-pixel-ratio: 2),
            (-o-min-device-pixel-ratio: 3/2),
            (min--moz-device-pixel-ratio: 2),
            (min-device-pixel-ratio: 2),
            (min-resolution: 144dppx) {
            background-image: image-url('bg2x/#{$imgName}');
            background-size: (image-width('bg2x/#{$imgName}') / 2) (image-height('bg2x/#{$imgName}') / 2);
        }
    }
}

@mixin layout-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

@mixin button-reset {
    background: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    text-transform: none;
    color: inherit;
    font-size: inherit;
    font-weight: normal;
    white-space: initial;
    border-radius: 0;
    text-align: left;
}

@mixin sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    clip-path: inset(50%);
    border: 0;
}

@mixin bp($bp, $rule: min-width) {
    @media screen and (#{$rule}: map-get($breakpoints, $bp)) {
        @content;
    }
}

@mixin text-sizes() {
    @each $name, $size in $text-sizes {
        @include text-size($className: '.text-#{$name}', $size: $size);
    }

    @each $name, $resolution in $breakpoints {
        @include bp($name) {
            @each $textName, $size in $text-sizes {
                @include text-size($className: '.#{$name}\\:text-#{$textName}', $size: $size);
            }
        }
    }
}

@mixin text-size($className, $size) {
    #{$className} {
        font-size: $size;
    }
}

@mixin aspect-ratios() {
    @each $name, $percentage in $aspect-ratios {
        .aspect-ratio-#{$name} {
            padding-bottom: $percentage;
        }
    }

    @each $bpName, $resolution in $breakpoints {
        @include bp($bpName) {
            @each $name, $percentage in $aspect-ratios {
                .#{$bpName}\:aspect-ratio-#{$name} {
                    padding-bottom: $percentage;
                }
            }
        }
    }
}

@mixin background-colors() {
    @each $name, $style in $colors {
        .bg-#{$name} {
            background-color: $style;
            color: color-contrast($style);
        }
    }
}

@mixin background-gradients() {
    @each $name, $gradient in $gradients {
        $color: getGradientFallback($name);

        .bg-gradient-#{$name} {
            background-image: $gradient;
            color: color-contrast($color);
        }
    }
}

@mixin visibility($type: 'block') {
    @each $name, $resolution in $breakpoints {
        @include bp($name) {
            .#{$name}\:#{$type} {
                @if($type == "hidden") {
                    display: none;
                } @else {
                    display: #{$type};
                }
            }
        }
    }
}

@mixin custom-toggle(
    $className: '.custom-toggle',
    $inputPadding: 3px,
    $width: 5em,
    $backgroundColor: '#efefef',
    $toggleColor: '#575757'
) {
    #{$className} {
        &__label {
            cursor: pointer;
        }

        &__radio {
            display: inline-block;
            width: 50%;
            height: 100%;
            opacity: 0;
            position: relative;
            z-index: 1;
            cursor: pointer;

            &:checked {
                width: 0;
                position: absolute;
            }

            & + &:checked ~ .custom-toggle__switcher {
                right: 0;
                left: calc(50% + #{$inputPadding});
            }

            &:not(:checked) {
                width: 100%;
                z-index: 3;
            }
        }

        &__wrapper {
            font-size: var(--baseSize);
            display: inline-block;
            vertical-align: middle;
            width: $width;
            height: ($width / 2);
            border-radius: ($width / 2);
            position: relative;
            margin: 0 5px;
        }

        &__switcher {
            display: block;
            position: absolute;
            top: $inputPadding;
            left: $inputPadding;
            right: 100%;
            width: calc(50% - #{($inputPadding * 2)});
            height: calc(100% - #{($inputPadding * 2)});
            border-radius: 50%;
            transition: all 0.15s ease-in-out;
            z-index: 2;
            background-color: #575757;
        }

        &__background {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
            border-radius: ($width / 2);
            transition: all 0.2s ease-out;
            background-color: #fff;
        }

        /* support Windows High Contrast Mode. Credit: Adrian Roselli https://twitter.com/aardrian/status/1021372139990134785 */
        @media screen and (-ms-high-contrast: active) {
            &__switcher {
                background-color: windowText;
            }
        }
    }
}
