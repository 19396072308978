table {
	width: 100%;
	border-collapse: collapse;
	th, td {
		padding: 10px 0;
		border-bottom: 1px solid #DADADA;
	}
	th {
		color: #e8825b;
		text-align: left;
	}
	td {
		
	}
	thead {
		th {
			//border-top: 1px solid #ccc;
			border-bottom: 2px solid #e8825b;
		}
	}
	tbody {
		tr:nth-child(even) {
			//background: #f3f3f3;
		}
	}
}