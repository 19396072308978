article.image {
    @include breakpoint(large) {
        margin: 100px 0;
    }
}

.image {
    text-align: center;

    &-content {
        margin-bottom: 50px;
    }

    img {
        border-radius: 10px;
        overflow: hidden;
    }
}
