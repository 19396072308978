.info-card {
    $this: &;

    text-align: center;

    &-media {
        line-height: 0;
        overflow: hidden;
        margin-bottom: 10px;
    }
    
    &-title {
        color: #2D72F7;
        margin-top: 0;
    }

    &-meta {
        font-size: map-get($text-sizes, sm);
        margin-bottom: 10px;
        margin: 0 -5px;

        & > span {
            margin: 0 5px;
        }
    }
    
    &-description {
        padding: 0 25px;
        margin-top: 0;

        & > *:last-child {
            margin-bottom: 0;
        }
        &.fade {
            position: relative;
            height: 130px;
            overflow: hidden;
        }
        &.fade:after {
          content: "";
          text-align: right;
          position: absolute;
          bottom: 0;
          right: 0;
          width: 70%;
          height: 1.5em;
          background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
        }
    }

    &-content {
        & > *:last-child {
            margin-bottom: 0;
        }
    }

    &-tags {
        @include layout-list();

        margin: 0 -5px;
        text-transform: uppercase;

        li {
            display: inline-block;
            margin: 0 5px;
        }

        a {
            text-decoration: underline;
            color: #2D72F7;
            font-size: 14px;
        }
    }

    &.is-opposite {
        flex-direction: row-reverse;
    }

    @include breakpoint(large) {
        &.is-horizontal {
            text-align: left;
            display: flex;
            padding: 0;

            #{$this}-media {
                margin-bottom: 0;
            }

            #{$this}-description {
                padding: 0;
            }

            #{$this}-header {
                flex: 0 0 50%;
            }

            #{$this}-content {
                padding: 10px;
            }

            #{$this}-header + #{$this}-content {
                padding: 10px 20px;
            }

            &.is-padded {
                #{$this}-header + #{$this}-content {
                    padding: 10px 0 10px 40px;
                }
            }
        }

        &.is-padded {
            padding: 10px;
        }
    }

    &.has-shadow {
        box-shadow: 0 10px 15px rgba(0, 0, 0, .25);
    }

    &.is-rounded {
        #{$this}-media {
            border-radius: 10px;
        }
    }

    &--v2 {
        &.is-horizontal {
            #{$this}-header + #{$this}-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;

                @include bp(md) {
                    
                }
            }

            #{$this}-title {
                margin-bottom: 20px;

                @include bp(md) {
                    //font-size: 42px;
                }
            }

            #{$this}-content {
                background: linear-gradient(201.18deg, #FFFFFF 48.72%, #B3B3B3 141.8%);
            }

            @include bp(md) {
                #{$this}-media {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
        
                    img {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            &.is-small {
                @include bp(md) {
                    #{$this}-header + #{$this}-content {
                        padding: 35px;
                    }
                }
            }
        }

        &:not(.is-horizontal) {
            position: relative;

            &:after {
                content: '';
                position: absolute;
                top: 20px;
                left: 40px;
                bottom: 0;
                right: 40px;
                background: #000000;
                opacity: 0.3;
                filter: blur(30px);
                z-index: 0;
            }
        }

        #{$this}-header + #{$this}-content {
            text-align: left;
            
            @include bp(md) {
                padding: 50px 55px;
            }
        }

        #{$this}-description {
            padding: 0;
        }

        #{$this}-content {
            background: linear-gradient(226.39deg, #FFFFFF 48.72%, #B3B3B3 141.8%);
            padding: 35px 30px;

            & > a {
                &:hover, &:focus, &:active {
                    text-decoration: none;
                }
            }
        }

        #{$this}-media {
            margin-bottom: 0;
            z-index: 1;
            background: #fff;

            img {
                object-position: top;
                object-fit: cover;
            }
        }
    
        #{$this}-media, #{$this}-content {
            position: relative;
            z-index: 1;
        }

        #{$this}-header {
            position: relative;
        }

        #{$this}-title {
            color:  map-get($colors, black);
            letter-spacing: -0.03em;
            line-height: 1.15;
            margin-bottom: 25px;

            @include bp(md) {
                //font-size: 36px;
            }
        }

        #{$this}-tags {
            margin-bottom: 10px;

            a {
                text-decoration: none;
            }
        }

        #{$this}-meta {
            font-weight: normal;
        }
    }
}