article.sidebar-nav {
    margin-top: 0;

    nav {
        background: #FFFFFF;
        box-shadow: 0px 15px 34px rgba(0, 0, 0, 0.08);
        border: 1px solid rgba(0, 0, 0, .02);
        border-radius: 16px;
        padding: 20px 40px;
    }

    ul {
        @include layout-list();

        & > li {
            position: relative;

            a {
                display: block;
                position: relative;
                padding: 20px 0;
            }

            & > a:hover {
                font-weight: bold;
                color: #000;
            }

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                padding-bottom: 0
            }

            &:not(.has-sub-nav) {
                & > a {
                    @include gradient-underline(golden-hour);

                    &:before {
                        opacity: 0;
                    }

                    &:hover, 
                    &.selected {
                        & > a {
                            font-weight: bold;
                            color: #000;
                        }
        
                        &::before {
                            bottom: 14px;
                            opacity: 1;
                        }
                    }
                }
            }

            &.has-sub-nav, 
            &.has-sub-nav.selected {
                & > a {
                    @include gradient-underline(golden-hour);

                    &::before {
                        opacity: 0;
                        bottom: 11px;
                    }

                    &:hover, {
                        &::before {
                            bottom: 11px;
                            opacity: 1;
                        }
                    }
                }
            }
        }

        ul {
            margin-left: 22px;
        }

        &.is-divided {
            & > li {
                &.has-sub-nav {
                    & > a {
                        &:hover {
                            &::before {
                                bottom: -2px;
                            }
                        }
                    }
                }

                &.has-sub-nav.selected {
                    & > a::before {
                        opacity: 1;
                    }
                }

                & > a {
                    &:hover {
                        &::before {
                            bottom: -2px;
                        }
                    }
                }
            }
        }

        &.has-description {
            &:not(.is-divided) {
                & > li {
                    & > a {
                        &:hover {
                            &::before {
                                bottom: 14px;
                            }
                        }
                    }
                }
            }

            &.is-divided {
                & > li:hover {
                    & > a {
                        position: static;
                        font-weight: bold;
                        color: #000;

                        &::before {
                            bottom: -2px;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    .sidebar-nav-list {
        transition: .3s ease-in-out all;

        &.is-divided {
            & > li {
                border-bottom: 1px solid #ccc;

                &:last-child {
                    border-bottom-color: transparent;
                }

                & > a {
                    font-weight: 600;
                }

                &.selected, &:not(.has-sub-nav):hover {
                    &::before {
                        bottom: -2px;
                    }
                }
            }
        }

        .has-sub-nav {
            & > a {
                font-weight: 600;
            }

            & > span:not([aria-expanded="false"]) + a,
            & > span:not([aria-expanded="false"]) + a:hover {
                padding-bottom: 10px;

                &::before {
                    bottom: 4px;
                    opacity: 1;
                }
            }

            &:hover > .sidebar-accordion-toggle {
                &::after {
                    border-left-color:#000;
                }
            }
        }
    }

    .sidebar-accordion-toggle {
        position: absolute;
        width: 15px;
        height: 20px;
        top: 22px;
        left: -20px;
        cursor: pointer;
        outline: 0;
    }

    [data-accordion-trigger] {
        transition: .3s ease-in-out all;

        &::after {
            position: absolute;
            left: 0;
            top: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            pointer-events: none;
            border-color: rgba(45, 114, 247, 0);
            border-left-color: #2D72F7;
            border-width: 10px;
            margin-top: -6px;
            transition: .3s ease-in-out transform;
            transform-origin: 4px 5px;
            border-top-width: 6px;
            border-bottom-width: 6px;
        }

        &[aria-expanded="true"] {
            &::after {
                transform: rotateZ(90deg);
            }
        }
    }

    .sidebar-nav-link-description {
        font-size: map-get($text-sizes, sm);
        margin-bottom: 25px;
        margin-top: -10px;
    }
}
