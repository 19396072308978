.services-availability {
    @include breakpoint(large) {
        margin: 100px 0;
    }

    .services {
        position: relative;
        border-radius: 40px;

        &-content {
            padding: 60px 120px;
            text-align: center;
            color: #fff;
            position: relative;
    
            @include breakpoint(medium) {
                padding: 60px 80px;
            }
    
            @include breakpoint(small) {
                padding: 40px 30px;
            }
        }
    
        &-mask {
            background: no-repeat bottom right url(../images/services-mask.svg);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    
        &-heading {
            margin-bottom: 45px;
        }
    
        form {
            margin: 0 auto;
    
            .item {
                position: relative;
            }
    
            .item + .item {
                flex: 0;
    
                @include breakpoint(small) {
                    margin-top: 20px;
                }
            }
    
            label {
                position: absolute;
                top: -22px;
                font-size: map-get($text-sizes, sm);
            }
    
            input[type="text"] {
                border-radius: 5px;
            }
    
            button {
                background: getGradient(aurora);
                color: #fff;
            }
    
            @include breakpoint(large) {
                width: 750px;
            }
        }
    }
}