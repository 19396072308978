.faq-listing {
    $this: &;
    margin-top: 60px;

    @include bp(md) {
        margin-top: 80px;
    }

    &-list {
        @include layout-list();

        &.has-columns {
            @include bp(md) {
                @include flex-grid($gutter: 100px, $isSelf: true, $includeBreakpoints: false);
            }

            #{$this}-list-item {
                @include bp(md) {
                    &:after {
                        right: 50px;
                    }
                }
            }
        }
    }

    &-heading {
        margin: 0 0 60px;

        @include bp(md) {
            margin-bottom: 70px;
            margin-top: -30px;
        }
    }

    &-list-item {
        display: flex;
        align-items: center;
        position: relative;
    }

    &-card {
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 35px 0;

        &:before, &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            border-top: 1px solid #CFCFCF;
        }

        &:after {
            top: auto;
            bottom: -1px;
        }

        @include bp(md) {
            height: 100%;
            width: 100%;
        }

        a {
            text-decoration: none;
            flex: 1;
        }

        a + ul {
            margin-top: 10px;
        }
    }

    &-card-title {
        letter-spacing: -.05px;
        color:  map-get($colors, black);
        margin: 0;
        transition: .2s ease-in-out color;
        font-family: acumin-pro-condensed, acumin-pro, 'Helvetica Neue', Helvetica, Arial, sans-serif;

        &:hover, &:focus {
            color: map-get($colors, blue);
        }
    }
}
