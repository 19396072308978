.comparison {
  max-width: 940px;
  margin: 0 auto;
  font: 16px/1.4 "Helvetica Neue",Helvetica,Arial,sans-serif;
  text-align: center;
}

.comparison table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  border-bottom: 1px solid #CCC;
  border-left: 1px solid #CCC;
  border-top: 1px solid #CCC;
}

.comparison td, .comparison th {
  border-right: 1px solid #CCC;
  empty-cells: show;
  padding: 10px;
}

.compare-heading {
  font-size: 16px;
  border-bottom: 0 !important;
  padding: 10px !important;
}

.comparison .compare-row {
  background: #F5F5F5;
}

.comparison .tickblue {
  color: #0078C1;
}

.comparison .tickgreen {
  color: #009E2C;
  font-size: 20px;
  font-weight: bold;
}

.comparison th {
  font-weight: normal;
  padding: 0;
  border-bottom: 1px solid #CCC;
  text-align: center;
}

.comparison tr td:first-child {
  text-align: left;
}

.comparison .qbse, .comparison .qbo, .comparison .tl {
  color: #FFF;
  padding: 10px;
  font-size: 13px;
  border-right: 1px solid #CCC;
  border-bottom: 0;
}

.comparison .tl2 {
  border-right: 0;
}

.comparison .qbse {
  background: #636363;
  border-top-left-radius: 3px;
  border-left: 0px;
  text-align: center;
}

.comparison .platinum {
  background: #F36F21;
  border-top-left-radius: 3px;
  border-left: 0px;
  color: #fff;
}

.comparison .qbo {
  background: #009E2C;
  border-top-right-radius: 3px;
  border-right: 0px;
}

.comparison .price-info {
  padding: 5px 15px 15px 15px;
}

.comparison .price-was {
  color: #999;
  text-decoration: line-through;
}

.comparison .price-now, .comparison .price-now span {
  color: #ff5406;
}

.comparison .price-now span {
  font-size: 32px;
}

.comparison .price-small {
  font-size: 18px !important;
  position: relative;
  top: -11px;
  left: 2px;
}

.comparison .price-buy {
  background: #ff5406;
  padding: 10px 20px;
  font-size: 12px;
  display: inline-block;
  color: #FFF;
  text-decoration: none;
  border-radius: 3px;
  text-transform: uppercase;
  margin: 5px 0 10px 0;
}

.comparison .price-try {
  font-size: 12px;
}

.comparison .price-try a {
  color: #202020;
}

@media (max-width: 767px) {
  .comparison td:first-child, .comparison th:first-child {
    //display: none;
  }

  .comparison tbody tr:nth-child(odd) {
    display: table-row;
    background: #F7F7F7;
  }

  .comparison .row {
    background: #FFF;
  }

  .comparison td, .comparison th {
    border: 1px solid #CCC;
  }

  .price-info {
    border-top: 0 !important;
  }
}

@media (max-width: 639px) {
  .comparison .price-buy {
    padding: 5px 10px;
  }

  .comparison td, .comparison th {
    padding: 10px 5px;
    font-size: 11px;
  }

  .comparison .hide-mobile {
    display: none;
  }

  .comparison .price-now span {
    font-size: 16px;
  }

  .comparison .price-small {
    font-size: 16px !important;
    top: 0;
    left: 0;
  }

  .comparison .qbse, .comparison .qbo {
    font-size: 12px;
    padding: 10px 5px;
  }

  .comparison .price-buy {
    margin-top: 10px;
  }

  .compare-heading {
    font-size: 13px;
  }
}
