.image-collage-grid {
    @include flex-grid($isSelf: true, $gutter: 0px, $includeBreakpoints: false);
}

.image-collage {
    overflow: hidden;

    &-wrapper {
        margin: 0 -10px;
    }

    img {
        border-radius: 10px;
        padding: 5px;

        @include breakpoint(small) {
            border-radius: 5px;
            padding: 3px;
        }
    }

    .aspect-ratio-3\/4 {
        padding-bottom: 133.333%;
    }
}