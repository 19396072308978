.carousel {
    &-content {
        margin-bottom: 20px;
    }

    .tns-outer {
        position: relative;
    }

    .tns-controls {
        [data-controls] {
            position: absolute;
            width: calc(25% - 30px);
            top: 0;
            bottom: 0;
            z-index: 1;
            background: transparent;
            box-shadow: none;
            outline: 0;
        }

        [data-controls="next"] {
            right: 0;
        }

        [data-controls][disabled] {
            display: none;
        }
    }

    .tns-nav {
        text-align: center;
        margin-top: 20px;
    }

    [data-controls], [data-nav] {
        @include button-reset();
    }
    
    [data-nav] {
        display: inline-block;
        width: 15px;
        height: 15px;
        border: 1px solid #ccc;
        border-radius: 50%;
        margin: 2px;

        &.tns-nav-active {
            border-color: #FF6B0B;
            background: #FF6B0B;
        }
    }

    .tns-slider {
        padding: 0;

        .tns-item {
            transition: .3s ease-in-out opacity;
            opacity: .35;

            &.is-active {
                opacity: 1;
            }

            img {
                width: 100% !important;
                height: auto !important;
                border-radius: 10px;
            }
        }
    }
}