.hero {
    $this: &;

    position: relative;

    .mask-wrapper {
        &.top-curve {
            @include curved-box(".bg");
        }
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .bg {
        position: relative;
        background-repeat: no-repeat;
        background-position: 0 100%;
        background-size: cover;
        height: 465px;

        @include breakpoint(small) {
            height: 240px;
        }
    }

    &.center-bg .bg {
        background-position: 0 50%;
    }

    .video-bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .title {
        position: relative;
        display: flex;
        align-items: center;
        color: #fff;
        min-height: 465px;

        @include breakpoint(small) {
            min-height: 240px;
        }
    }

    &:not(.hero--columns) h1:not(.clear-title) {
        color: #fff;
        text-align: center;

        > span {
            padding: 5px 0;
            background: #FF8200; // Fallback color

            > span {
                display: inline;
                padding: 5px 20px;
                line-height: 1.35;
                color: #fff;
                -webkit-box-decoration-break: clone;
                box-decoration-break: clone;

                @include breakpoint(medium-large) {
                    background: linear-gradient(to right top, #F15523 20%, #FFBE01 80%);
                    background-attachment: fixed;
                }

                @include breakpoint(small) {
                    line-height: 1.55;
                }
            }
        }
    }

    &.is-small {
        .title {
            min-height: 365px;

            @include breakpoint(small) {
                min-height: 165px;
            }
        }

    }

    &.top {
        .title {
            align-items: flex-start;
            margin-top: 20px;
        }
    }

    &.middle {
        .title {
            align-items: center;
        }
    }

    &.bottom {
        .title {
            align-items: flex-end;

            @include breakpoint(small) {
                top: 10px;
            }
        }
    }

    &.left {
        .title {
            justify-content: flex-start;
        }
    }

    &.center {
        .title {
            justify-content: center;
        }
    }

    &.right {
        .title {
            justify-content: flex-end;
        }
    }

    &--columns {
        .bg {
            height: 100%;
        }

        .title {
            padding: 40px 0;
            box-sizing: content-box;

            @include bp(md) {
                padding: 40px 0 60px 0;
            }

            .flex-grid {
                @include bp(md) {
                    align-items: center;
                    width: 100%;
                }
            }
        }

        .hero-heading, .hero-content {
            & > *:first-child {
                margin-top: 0;
            }

            & > *:last-child {
                margin-bottom: 0;
            }
        }

        &.right {
            @include bp(md) {
                .flex-grid {
                    text-align: right;
                    flex-direction: row-reverse;
                }

                .faq-autocomplete-form {
                    margin-left: auto;
                }
            }
        }

        &.center {
            @include bp(md) {
                .flex-grid {
                    text-align: center;
                    justify-content: center;
                }

                .faq-autocomplete-form {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }

        &.bottom {
            @include breakpoint(medium-large) {
                padding-bottom: 57px;
            }
        }
    }

    &-content {
        margin-top: 15px;
    }

    .faq-autocomplete-form {
        margin-top: 40px;
        max-width: 515px;
    }
}
