.at-only:not(:focus):not(:active) {
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

[data-modal-open][hidden] {
    display: none;
}

body.modal-open {
    overflow: hidden;
}

.js [data-modal],
.a11y-modal {
    -webkit-overflow-scrolling: touch;
    background: #fff;
    left: 20px;
    right: 20px;
    top: 20px;
    box-shadow: 0 0 1800px 18000px rgba(0, 0, 0, .4);
    margin: auto;
    max-height: 100%;
    max-width: 660px;
    opacity: 1;
    overflow: auto;
    padding: 40px;
    position: fixed;
    transform: translateY(0%);
    visibility: visible;
    z-index: 1000;
    border-radius: 20px;
}

@include breakpoint(medium-large) {
    .js [data-modal],
    .a11y-modal {
        bottom: auto;
        top: 50%;
        transform: translateY(-50%);
        transition: opacity .2s ease-in-out, transform .2s ease-in-out;
        bottom: auto;
        left: 0;
        right: 0;
        width: 100%;
    }
}

.js [data-modal][hidden] .a11y-modal[hidden] {
    display: block;
    opacity: 0;
    pointer-events: none;
    transform: translateY(15vh);
    visibility: hidden;
}

.a11y-modal__close-btn {
    border: none;
    @include button-reset();
}

.is-icon-btn {
    background: none;
    border: none;
    height: 2em;
    padding: 0;
    position: absolute;
    right: .75em;
    top: .75em;
    width: 2em;
}

[data-modal-x] {
    border-right: 2px solid;
    bottom: 0;
    display: block;
    height: 2em;
    left: 0;
    margin: auto;
    pointer-events: none;
    position: relative;
    right: 0;
    top: 0;
    transform: rotate(45deg);
    width: 2px;
}

[data-modal-x]:after {
    border-right: 2px solid;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: rotate(-90deg);
    width: 100%;
}