article.breadcrumbs-wrapper {
    margin: -50px 0;
    @include breakpoint(small) {
      margin: -25px 0;
    }

    a {
        color: #FF6B0B;
        transition: .2s linear opacity;

        &:hover, &:focus {
            opacity: .7;
        }
    }
}
