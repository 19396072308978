// Layout Variables
$sidebar: 275px;

.layout {
    @extend .clearfix;
}

// Layout Styles
.layout-columns {
    margin-top: 50px;

    @include breakpoint(medium-large) {
        > section {
            @include grid-columns(9, $column_gap: 55px);
        }

        > aside {
            @include grid-columns(3, $column_gap: 55px);
        }
    }
}

.site-wrapper {
    max-width: $contentmaxwidth;
    margin: 0 auto;
    background-color: #fff;
    &.experience-editor {
        .site-hader {
            position: relative;
            margin-top: -130px;
        }
    }
}

.wrapper {
    @extend .clearfix;
    max-width: $contentwidth;
    margin: 0 auto;
    padding: 0 20px;

    .wrapper, 
    .wrapper-lg, 
    .wrapper-md,
    .wrapper-sm {
        padding: 0;
    }
}

// Generic Grid
@include breakpoint(medium-large) {
    .grid {
        $spacing: 10px;
        display: table;
        width: 100%;
        //@include experimental-value(width, calc(100% + #{$spacing * 2})); // Better alignment for everything except IE8 with this
        border-spacing: $spacing;
        margin: -$spacing; // Offset the border-spacing around the outside
        .col {
            //*behavior: url(/static/css/htc/boxsizing.htc);
            display: table-cell;
            width: 50%;
            vertical-align: top;
        }
        &.three .col {
            width: 33.33%;
        }
        &.four .col {
            width: 25%;
        }
        &.border .col {
            border-left: 1px solid #cccccc;
            padding: 0 30px;
            &:first-child {
                border-left: 0;
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }
        }
        &.bg {
            .col {
                background: #f3f3f3;
                padding: 20px;
            }
        }
    }
}

.wrapper {
    &-lg,
    &-md,
    &-sm {
        padding: 0 20px;

        @include breakpoint(large) {
            margin: 0 auto;
        }
        
        .wrapper {
            padding: 0;
        }
    }

    &-lg {
        @include breakpoint(large) {
            max-width: map-get($breakpoints, 'lg');
        }
    }

    &-md {
        @include breakpoint(large) {
            max-width: map-get($breakpoints, 'md');
        }
    }

    &-sm {
        @include breakpoint(large) {
            max-width: map-get($breakpoints, 'sm');
        }
    }
}
