.charity-listing {
    &-header {
        margin-bottom: 50px;
        text-align: center;
    }

    img {
        margin-bottom: 45px;
    }

    &-grid {
        margin-bottom: -45px; 

        li {
            display: flex;
            align-items: center;    
            @include flex-grid-item();
            @include flex-grid-column($percentage: map-get($flex-columns, '1\\/2'));
        }

        @include bp(sm) {
            li {
                @include flex-grid-column($percentage: map-get($flex-columns, '1\\/3'));
            }
        }

        @include bp(md) {
            li {
                @include flex-grid-column($percentage: map-get($flex-columns, '1\\/6'));
            }
        }
    }
}