// Desktop Header
// --------------------------------------------------
body.is-scrolling .site-header {
    border-bottom: 1px solid #bababa;
}

.site-header.desktop {
    @include breakpoint(small) {
        display: none;
    }

    position: fixed;
    top: 0;
    z-index: 100;
    background: #fff;
    height: 135px;
    font-size: 14px;
    padding: 15px 0;
    max-width: $contentmaxwidth;
    width: 100%;

    .utility-nav {
        @include layout-list;
        margin-bottom: 10px;
        text-align: right;
        font-size: 10px;
        font-weight: bold;

        & > li {
            display: inline-block;
            padding-left: 35px;

            & > a {
                padding: 5px 0;
                color: #6C6C6C;

                &.selected {
                    @include gradient-underline(golden-hour, 1px);
                }
            }
        }
    }

    .logo {
        float: left;
        width: 200px;
        height: 56px;
        margin-top: 4px;
        background: url(../images/sunnova-logo.png) no-repeat;
        background-size: 100%;
        text-indent: -5000px;
    }

    .main-nav-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .main-nav {
        text-align: right;
        margin-top: 6px;

        ul {
            @include layout-list;
            float: right;

            & > li {
                display: inline-block;
                margin: 0 10px;

                &:last-child {
                    margin-right: 0;
                }

                &:first-child {
                    margin-left: 0;
                }

                & > a {
                    display: block;
                    padding: 15px 10px;
                    color:  map-get($colors, black);
                    text-decoration: none;
                }

                &.get-quote a {
                    background: getGradient(golden-hour);
                    color: #fff;
                    padding: 10px 30px;
                }

                &.is-active:not(.get-quote), &:hover:not(.get-quote) {
                    @include gradient-underline(golden-hour);

                    & > a {
                        //font-weight: bold;
                        color: #000;
                    }
    
                    &::before {
                        bottom: 10px;
                    }
                }

                &.has-sub-nav {
                    position: relative;
                    text-align: left;
                    display: inline-flex;
                    justify-content: center;

                    & > a {
                        padding-right: 20px;
                    }

                    &::after {
                        position: absolute;
                        right: 5px;
                        top: calc(50% - 2px);
                        border: solid transparent;
                        content: '';
                        height: 0;
                        width: 0;
                        pointer-events: none;
                        border-color: rgba(45, 114, 247, 0);
                        border-top-color:  map-get($colors, black);
                        border-width: 6px;
                        transition: .3s ease-in-out all;
                        border-left-width: 4px;
                        border-right-width: 4px;
                    }

                    &:hover {
                        & > ul {
                            display: block;
                        }
                    }

                    & > ul {
                        display: none;
                        position: absolute;
                        background: #FFFFFF;
                        box-shadow: 0px 15px 34px rgba(0, 0, 0, 0.08);
                        border: 1px solid rgba(0, 0, 0, 0.02);
                        border-radius: 16px;
                        padding: 10px 20px;
                        min-width: 180px;
                        top: 100%;
                        left: 0;

                        li {
                            margin: 0;
                            display: block;
                        }

                        a {
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
    }
}

// Mobile Header
// --------------------------------------------------

.site-header.mobile {
    @include breakpoint(medium-large) {
        display: none;
    }
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 10px 0;
    background: #fff;
    z-index: 100;
    overflow: auto;

    .logo {
        float: left;
        width: 144px;
        height: 40px;
        background: url(../images/sunnova-logo.png) no-repeat;
        background-size: 100%;
        text-indent: -5000px;
    }

    .mobile-menu {
        float: right;
        margin-top: 5px;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: bold;
    }

    $hamburger-height: 16px;
    $hamburger-stripe-height: 3px;
    $hamburger-width: 24px;
    
    span.hamburger {
        display: inline-block;
        height: $hamburger-height + $hamburger-stripe-height;
        position: relative;
        transform: rotate(0deg);
        transition: .5s ease-in-out;
        width: $hamburger-width;
        vertical-align: middle;
        margin-right: 10px;

        span {
            background: getGradient(golden-hour);
            height: $hamburger-stripe-height;
            left: 0;
            opacity: 1;
            position: absolute;
            transform: rotate(0deg);
            transition: .25s ease-in-out;
            width: 100%;

            &:nth-child(1) {
                top: 0;
            }

            &:nth-child(2) {
                top: $hamburger-height / 2;
            }

            &:nth-child(3) {
                top: $hamburger-height / 2;
            }

            &:nth-child(4) {
                top: $hamburger-height;
            }
        }

        &.open span {
            &:nth-child(1) {
                top: $hamburger-height / 2;
                left: 50%;
                width: 0;
            }

            &:nth-child(2) {
                transform: rotate(45deg);
            }

            &:nth-child(3) {
                transform: rotate(-45deg);
            }

            &:nth-child(4) {
                top: $hamburger-height / 2;
                left: 50%;
                width: 0;
            }
        }
    }

    nav {
        display: none;
        clear: both;

        ul {
            @include layout-list;
            font-size: 20px;

            li {
                position: relative;

                a {
                    display: block;
                    padding: 15px 0;
                    color:  map-get($colors, black);
                    font-weight: bold;
                }

                &.get-quote a {
                    margin-top: 10px;
                    background: getGradient(golden-hour);
                    color: #fff;
                    font-size: 20px;
                    padding: 10px 0 12px;
                }

                & > ul {
                    background: #f3f3f3;
                    padding: 5px 20px;
                    margin: 0 -20px;
                    transition: .3s ease-in-out height;
                }

                &.has-sub-nav > a {
                    margin-right: 25px;
                }
            }
        }

        ul.utility-nav {
            @include layout-list;
            margin-top: 20px;
            font-size: 14px;

            li {
                padding: 5px 0;

                a {
                    display: inline-block;
                    padding: 5px 0;
                    color:  map-get($colors, black);
                    font-weight: bold;

                    &.selected {
                        @include gradient-underline(golden-hour, 1px);
                    }
                }
            }
        }
    }

    [data-accordion-trigger] {
        position: absolute;
        width: 15px;
        height: 20px;
        top: 22px;
        right: -5px;
        cursor: pointer;
        outline: 0;
        transition: .3s ease-in-out all;

        &::after {
            position: absolute;
            right: 0;
            top: 0;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            pointer-events: none;
            border-color: rgba(45, 114, 247, 0);
            border-top-color:  map-get($colors, black);
            border-width: 10px;
            margin-top: 6px;
            transition: .3s ease-in-out transform;
            transform-origin: 5px 3px;
            border-left-width: 6px;
            border-right-width: 6px;
        }

        &[aria-expanded="true"] {
            &::after {
                transform: rotateZ(-180deg);
            }
        }
    }

    &.open {
        bottom: 0;
    }
}
