article.partner-form {
  padding: 100px 0;
  background: getGradient(golden-hour);
  color: #fff;
  text-align: center;
  position: relative;

  .partner-form-success, .error-message {
    display: none;
  }

  .partner-form-container, .partner-form-success {
    @extend .clearfix;
    background: rgba(0, 0, 0, .15);
    border-radius: 20px;
    padding: 60px;
    position: relative;

    p {
      font-size: 24px;
    }

    @include breakpoint(small) {
      padding: 20px;
    }

    @include breakpoint(medium) {
      padding: 40px;
    }
  }

  h2 {
    text-align: center;
  }

  .partner-form-about {
    margin: 30px 0;
  }

  p.partner-form-required {
    font-size: map-get($text-sizes, sm);
  }

  form {
    @include breakpoint(medium-large) {
      @include grid-columns(8);
      @include push(2);
    }

    p {
      &.intro {
        margin-bottom: 50px;
      }
    }

    .item.city {
      flex-grow: 1.5;
    }

    button {
      background: #fff;
      color: getGradientFallback(golden-hour);
    }

    .buttons {
      margin-top: 30px;
    }

    p.legal {
      margin-top: -20px;
      font-size: 14px;
      text-align: center;

      a {
        color: #fff;
        text-decoration: underline;
      }
    }

    select {
      border-radius: 16px;
      padding: 17px 15px;
      height: 58.5px;
    }

    input {
      border: 1px solid #eaeaea;
      border-radius: 16px;
      background-color: #fff;
      transition: all 0.25s;

      &:focus, &:active {
        box-shadow: 0 0 0px 1px #555;
        border-color: #eaeaea;
        outline: 0;
      }
    }
  }
  // Active Markets Multiselect
  .combobox {
    display: inline-block;
    position: relative;
    margin-bottom: 2em;
    margin-top: 1em;
    width: 100%;
    &.active-markets {
      margin-bottom: 5px;
      ul {
        z-index: 1;
      }
    }
  }

  .markets-label {
    top: 10px !important;
    opacity: 1 !important;
  }

  .combo-input-display {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 58.5px;
    padding: 35px 15px 10px;
    border: 1px solid #eaeaea;
    border-radius: 16px;
    background-color: #fff;
    transition: all 0.25s;

    &:focus, &:active {
      box-shadow: 0 0 0px 1px #555;
      border-color: #eaeaea;
      outline: 0;
    }
  }

  .combobox-input-tag {
    display: inline-block;
    border-radius: 8px;
    padding: 6px 15px;
    margin: 2px;
    font-size: 14px;
    background: #2d72f7;
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.3px;
  }

  .combobox button {
    background: transparent;
    padding: 0;
    margin: 0;
    border: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .combobox input {
    color: #757575;
    font-size: 16px;
    width: 100%;
    outline: none;
    padding: 17px 15px;
    margin: 0;
    border: 1px solid #fff;
    border-radius: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    overflow-y: scroll;
    max-height: 18em;
  }

  .combobox .toggle {
    position: absolute;
    right: 15px;
    top: 50%;
    height: 18px;
    transform: translateY(-50%);
    pointer-events: none;

    svg {
      height: 18px;
      color: #555;
    }
  }

  .toggle-list {
    position: absolute;
    right: 15px;
    top: 45%;
    height: 18px;
    pointer-events: none;
    z-index: 1;

    svg {
      height: 18px;
      color: #555;
    }
  }

  .toggle-arrow-fix {
    top: 25px !important;
  }

  .combobox ul {
    display: none;
    list-style: none;
    text-align: left;
    background-color: #fff;
    color: #202020;
    font-size: 16px;
    width: 100%;
    margin: 0;
    padding: 0;
    border: 1px solid #999;
    border-radius: 5px;
    max-height: 300px;
    overflow-y: auto;
    position: absolute;
    top: calc(100% + 5px);


    &:focus {
      box-shadow: 0 0 0px 2px #555;
      border-color: #555;
      outline: 0;
    }
  }

  .combobox li {
    color: #757575;
    font-size: 16px;
    padding: 15px 0 15px 15px;
    position: relative;
  }

  .combobox [aria-selected="true"] {
    background-color: #eaeaea;
    color: #333;
    display: flex;
    align-items: center;

    &:after {
      display: inline-block;
      position: absolute;
      right: 10px;
      width: 20px;
      height: 20px;
      content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='%23a5e226' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'></path></svg>");
    }
  }

  .combobox .activedescendant {
    background: #2d72f7;
    color: #fff;
  }
  .text-box {
    padding: 14px 15px 15px 15px;
    border: 1px solid #eaeaea;
    border-radius: 16px;
    background-color: #fff;
    margin-top: 1em;
    &.fl-active {
      padding: 24px 15px 5px 15px;
      textarea {
        padding-top: 0px;
      }
    }

    textarea {
      border: none;
      padding: 0px;
      border-radius: 0px;
      max-height: 80px;
    }
  }
  // Business Focus Multiselect
  .combobox-options {
    margin-bottom: 0;
    display: inline-block;
    position: relative;
    margin-top: 1em;
    width: 100%;

    ul {
      z-index: 9999;
    }
  }

  .combobox-options button {
    background: transparent;
    padding: 0;
    margin: 0;
    border: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .combobox-options input {
    color: #757575;
    font-size: 16px;
    width: 100%;
    outline: none;
    padding: 17px 15px;
    margin: 0;
    border: 1px solid #fff;
    border-radius: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    overflow-y: scroll;
    max-height: 18em;
  }

  .combobox-options .toggle {
    position: absolute;
    right: 15px;
    top: 50%;
    height: 18px;
    transform: translateY(-50%);
    pointer-events: none;

    svg {
      height: 18px;
      color: #555;
    }
  }

  .combobox-options ul {
    display: none;
    list-style: none;
    text-align: left;
    background-color: #fff;
    color: #202020;
    font-size: 16px;
    width: 100%;
    margin: 0;
    padding: 0;
    border: 1px solid #999;
    border-radius: 5px;
    max-height: 300px;
    overflow-y: auto;
    position: absolute;
    top: calc(100% + 5px);


    &:focus {
      box-shadow: 0 0 0px 2px #555;
      border-color: #555;
      outline: 0;
    }
  }

  .combobox-options li {
    color: #757575;
    font-size: 16px;
    padding: 15px 0 15px 15px;
    position: relative;
  }

  .combobox-options [aria-selected="true"] {
    background-color: #eaeaea;
    color: #333;
    display: flex;
    align-items: center;

    &:after {
      display: inline-block;
      position: absolute;
      right: 10px;
      width: 20px;
      height: 20px;
      content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='%23a5e226' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'></path></svg>");
    }
  }

  .combobox-options .activedescendant {
    background: #2d72f7;
    color: #fff;
  }

  .selections + span.error {
    display: none;
  }
  .not-required + span.error {
    display: none !important;
  }

  span#businessfocus-error + .toggle {
    top: 29.25px !important;
  }
}
